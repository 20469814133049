<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="1300px"
      >
        <v-card>
          <v-simple-table class="pa-10">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    CAMPAIGN
                  </th>
                  <th class="text-center">
                    TIME
                  </th>
                  <th class="text-center">
                    DATABASE
                  </th>
                  <th class="text-center">
                    SEGMENTATION
                  </th>
                  <th class="text-center">
                    AMOUNT
                  </th>
                  <th class="text-center">
                    PAYOUT
                  </th>
                  <th class="text-center">
                    TAG(S)
                  </th>
                  <th class="text-center">
                    COMMENTS
                  </th>
                  <th class="text-center">
                    EDIT
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="py-5"
                  v-for="item in top_campaign"
                  :key="item.name"
                >
                  <td class="text-center top_campaign px-10 py-2">{{ item.name }}<br>{{ item.partner }}</td>
                  <td class="text-center">{{ item.time }}</td>
                  <td class="text-center" rowspan="4">{{ item.database }}</td>
                  <td class="text-center">{{ item.segmentation }}</td>
                  <td class="text-center">{{ item.amount }}</td>
                  <td class="text-center">{{ item.payout }}</td>
                  <td class="text-center">{{ item.tags }}</td>
                  <td class="text-center">{{ item.comments }}</td>
                  <td class="text-center">
                    <v-btn icon>
                      <v-icon>mdi-circle-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr
                  class="py-5"
                  v-for="item in rate"
                  :key="item.name"
                >
                  <td class="text-center bounce_rate px-10 py-2">{{ item.name}}<br>{{item.partner}}</td>
                  <td class="text-center">{{ item.time }}</td>
                  <td class="text-center">{{ item.segmentation }}</td>
                  <td class="text-center">{{ item.amount }}</td>
                  <td class="text-center">{{ item.payout }}</td>
                  <td class="text-center">{{ item.tags }}</td>
                  <td class="text-center">{{ item.comments }}</td>
                  <td class="text-center">
                    <v-btn icon>
                      <v-icon>mdi-circle-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr
                  class="py-5"
                  v-for="item in okKit"
                  :key="item.name"
                >
                  <td class="text-center kit_ok px-10 py-2">{{ item.name}}<br>{{item.partner}}</td>
                  <td class="text-center">{{ item.time }}</td>
                  <td class="text-center">{{ item.segmentation }}</td>
                  <td class="text-center">{{ item.amount }}</td>
                  <td class="text-center">{{ item.payout }}</td>
                  <td class="text-center">{{ item.tags }}</td>
                  <td class="text-center">{{ item.comments }}</td>
                  <td class="text-center">
                    <v-btn icon>
                      <v-icon>mdi-circle-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr
                  class="py-5"
                  v-for="item in scheduled"
                  :key="item.name"
                >
                  <td class="text-center scheduled px-10 py-2">{{ item.name}}<br>{{item.partner}}</td>
                  <td class="text-center">{{ item.time }}</td>
                  <td class="text-center">{{ item.database }}</td>
                  <td class="text-center">{{ item.segmentation }}</td>
                  <td class="text-center">{{ item.amount }}</td>
                  <td class="text-center">{{ item.payout }}</td>
                  <td class="text-center">{{ item.tags }}</td>
                  <td class="text-center">{{ item.comments }}</td>
                  <td class="text-center">
                    <v-btn icon>
                      <v-icon>mdi-circle-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="tvf_color"
              text
              @click="$emit('close')"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      top_campaign: [
        {
          name: "Allianz Auto",
          partner: "(Adkomo)",
          time: "8:00",
          database: "Conseils du Jour",
          segmentation: "segmentation",
          amount: "90K",
          payout: "0,40",
          tags: "Assurance",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        }
      ],
      rate: [
        {
          name: "BMW",
          partner: "(Datawork)",
          time: "8:00",
          database: "Conseils du Jour",
          segmentation: "segmentation",
          amount: "30K",
          payout: "0,50",
          tags: "Cars",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        },
      ],
      okKit: [
        {
          name: "Younited",
          partner: "(Adventure Conseil)",
          time: "8:00",
          database: "Les promos de l'anée",
          segmentation: "segmentation",
          amount: "58K",
          payout: "0,45",
          tags: "Credit",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        },
        {
          name: "Younited",
          partner: "(Adventure Conseil)",
          time: "14:00",
          database: "Les promos de l'anée",
          segmentation: "segmentation",
          amount: "20K",
          payout: "0,45",
          tags: "Credit",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        },
      ],
      scheduled: [
        {
          name: "Fnac",
          partner: "(Natexo)",
          time: "8:00",
          database: "Les Remises de Billy",
          segmentation: "segmentation",
          amount: "30K",
          payout: "0,30",
          tags: "Shopping Black Friday",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        },
        {
          name: "Fnac",
          partner: "(Natexo)",
          time: "8:00",
          database: "Les Remises de Billy",
          segmentation: "segmentation",
          amount: "30K",
          payout: "0,30",
          tags: "Shopping Black Friday",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        },
        {
          name: "Zalando",
          partner: "(AdvCentral)",
          time: "14:00",
          database: "Une aubaine pour vous",
          segmentation: "segmentation",
          amount: "100K",
          payout: "0,35",
          tags: "Shopping Fashion",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        },
        {
          name: "Zalando",
          partner: "(AdvCentral)",
          time: "14:00",
          database: "Affaire pour vous",
          segmentation: "segmentation",
          amount: "80K",
          payout: "0,35",
          tags: "Shopping Fashion",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        },
        {
          name: "Zalando",
          partner: "(AdvCentral)",
          time: "14:00",
          database: "La Suite Logique",
          segmentation: "segmentation",
          amount: "160K",
          payout: "0,35",
          tags: "Shopping Fashion",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        },
      ],
    };
  },
};
</script>

