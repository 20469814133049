<template>
  <div>
    <v-row>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <v-autocomplete v-model="country" class="caption" :items='countriesByUser' background-color="#fff"
          placeholder="Select country" item-value="id" item-text="name" solo dense flat aria-autocomplete="off"
          @input="onCountryChange(country)">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <div class="stats">
              <v-text-field v-model="formattedDate" class="caption" v-bind="attrs" v-on="on" background-color="#fff"
                placeholder="Select date range" solo dense flat readonly aria-autocomplete="off"></v-text-field>
            </div>
          </template>
          <!-- Ranges buttons -->
          <v-card>
            <v-card-text>
              <v-btn v-for="(value, name) in ranges" :key="name" class="mr-3 mb-2" depressed small
                @click.stop="setRange(value)">
                {{ name }}
              </v-btn> <br />
              <v-btn v-for="(value, name) in ranges2" :key="name" class="mr-3 mb-2" depressed small
                @click.stop="setRange(value)">
                {{ name }}
              </v-btn> <br />
              <div class="d-flex">
                <v-btn small depressed @click.stop="showCustomRange = !showCustomRange"
                  class="mt-2 mb-1 tvf_color--text">Custom Range
                </v-btn>
                <date-picker prefix-class="xmx" v-model="dates" class="pt-2 pl-2" type="date" range
                  background-color="#fff" placeholder="Select date range" solo dense flat v-show="showCustomRange"
                  @change="showCustomRange = false" aria-autocomplete="off">
                </date-picker>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <v-autocomplete v-model="where" class="caption flex-grow-1" :items='filterByItems' background-color="#fff"
          placeholder="Filter by..." item-value="name" item-text="name" solo dense flat aria-autocomplete="off"
          return-object></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="1" xl="1">
        <v-autocomplete v-model="andWhere" class="caption flex-grow-1" :items='andByItems' background-color="#fff"
          placeholder="and by..." item-value="name" item-text="name" solo dense flat aria-autocomplete="off"
          return-object></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <v-autocomplete v-model="groupBy1" class="caption flex-grow-1" :items='groupBy1Items' background-color="#fff"
          placeholder="Group by..." item-value="name" item-text="name" solo dense flat aria-autocomplete="off"
          return-object></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2" md="2" lg="1" xl="1">
        <v-autocomplete v-model="groupBy2" class="caption flex-grow-1" :items='groupBy2Items' background-color="#fff"
          placeholder="Group by..." item-value="name" item-text="name" solo dense flat aria-autocomplete="off"
          return-object :disabled="!groupBy1"></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <v-btn class="buttons px-6" icon depressed tile @click="filterHeadersAndFetchStats">
          <v-icon dark color="#A3A3A3">
            mdi-magnify
          </v-icon>
        </v-btn>
        <v-btn class="buttons px-6 ml-2" icon depressed tile @click="resetFilters">
          <v-icon dark color="#A3A3A3">
            mdi-refresh
          </v-icon>
        </v-btn>
        <v-btn class="buttons px-6 ml-2" icon depressed tile @click="openModal" v-if="$vuetify.breakpoint.lgAndDown">
          <v-icon dark color="#A3A3A3">
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn v-else class="buttons grey--text ml-10" color="white" elevation="0" @click="openModal">
          Save Filter
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="2" md="2">
        <v-autocomplete v-model="filter" class="caption" :items='stats_filters' background-color="#fff"
          placeholder="My custom filters" item-value="id" item-text="name" solo dense flat aria-autocomplete="off"
          return-object @change="getFilterInfo(filter)">
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
      </v-col>
      <v-col cols="12" sm="2" md="2" v-if="where">
        <v-autocomplete v-if="where.id === 1" v-model="filterBy" class="caption" :items='getFilterItems'
          background-color="#fff" placeholder="Select filter" item-value="id" item-text="first_name" solo dense flat
          aria-autocomplete="off">
        </v-autocomplete>
        <v-autocomplete v-else-if="where.id === 8" v-model="filterBy" class="caption" :items='getFilterItems'
          background-color="#fff" placeholder="Select filter" solo dense flat aria-autocomplete="off">
        </v-autocomplete>
        <v-autocomplete v-else-if="where.id === 10" v-model="filterBy" class="caption" :items='getFilterItems'
          background-color="#fff" placeholder="Select filter" item-value="id" item-text="from_name" solo dense flat
          aria-autocomplete="off">
        </v-autocomplete>
        <v-autocomplete v-else v-model="filterBy" class="caption" :items='getFilterItems' background-color="#fff"
          placeholder="Select filter" item-value="id" item-text="name" solo dense flat aria-autocomplete="off">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2" v-else>

      </v-col>
      <v-col cols="12" sm="2" md="2" v-if="andWhere">
        <v-autocomplete v-if="andWhere.id === 1" v-model="andBy" class="caption" :items='getAndByItems'
          background-color="#fff" placeholder="Select filte" item-value="id" item-text="first_name" solo dense flat
          aria-autocomplete="off">
        </v-autocomplete>
        <v-autocomplete v-else-if="andWhere.id === 8" v-model="andBy" class="caption" :items='filterItemsValues'
          background-color="#fff" placeholder="Select filter" solo dense flat aria-autocomplete="off">
        </v-autocomplete>
        <v-autocomplete v-else-if="andWhere.id === 10" v-model="andBy" class="caption" :items='getAndByItems'
          background-color="#fff" placeholder="Select filter" item-value="id" item-text="from_name" solo dense flat
          aria-autocomplete="off">
        </v-autocomplete>
        <v-autocomplete v-else v-model="andBy" class="caption" :items='getAndByItems' background-color="#fff"
          placeholder="Select filter" item-value="id" item-text="name" solo dense flat aria-autocomplete="off">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2" v-else>

      </v-col>
      <v-col cols="12" sm="2" md="2">

      </v-col>
      <v-col cols="12" sm="1" md="1">

      </v-col>
      <v-col cols="12" sm="1" md="1">
        <v-btn class="buttons px-6 ml-4" icon depressed tile @click="exportToCSV()" v-if="$vuetify.breakpoint.lgAndDown">
          <v-icon dark color="#A3A3A3">
            mdi-download
          </v-icon>
        </v-btn>
        <v-btn v-else class="buttons grey--text" color="white" elevation="0" @click="exportToCSV()">
          EXPORT
          <v-icon dark color="#A3A3A3" class="ml-1">
            mdi-download
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="white--text mb-5 d-flex justify-space-around align-center" color="black" elevation="1" height="120px">
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon class="mb-1" large color="white">
          mdi-send
        </v-icon>
        <p class="body-2">{{ sumField('amount_sent') }}</p>
        <p class="caption">SENT</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-record-circle-outline
        </v-icon>
        <p class="body-2">{{ sumField('bounce') }}</p>
        <p class="caption">BOUNCES</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-email-multiple-outline
        </v-icon>
        <p class="body-2">{{ sumField('open') }}</p>
        <p class="caption">OPENS</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-email-outline
        </v-icon>
        <p class="body-2">{{ sumField('unique_open') }}</p>
        <p class="caption">U. OPENS</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-cursor-default-click-outline
        </v-icon>
        <p class="body-2">{{ sumField('click') }}</p>
        <p class="caption">CLICKS</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-cursor-default-outline
        </v-icon>
        <p class="body-2">{{ sumField('unique_click') }}</p>
        <p class="caption">U. CLICKS</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-finance
        </v-icon>
        <p class="body-2">{{ averageField('ctor', 'amount_sent') }}</p>
        <p class="caption">CTOR</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-emoticon-sad-outline
        </v-icon>
        <p class="body-2">{{ sumField('complaint') }}</p>
        <p class="caption">COMPL.</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-account-minus-outline
        </v-icon>
        <p class="body-2">{{ sumField('unsub') }}</p>
        <p class="caption">UNSUB.</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-account-multiple-outline
        </v-icon>
        <p class="body-2">0,0%</p>
        <p class="caption">LEADS</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-account-plus-outline
        </v-icon>
        <p class="body-2">0,0%</p>
        <p class="caption">VALID<br /> LEADS</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-thumb-up-outline
        </v-icon>
        <p class="body-2">{{ sumField('ecpm') }}</p>
        <p class="caption">ECPM NET</p>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-poll
        </v-icon>
        <p class="body-2">{{ sumField('performanceGenerated') }}</p>
        <p class="caption">PERFOR.</p>
      </div>
      <div class="icons_stats"
        style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <v-icon large color="white">
          mdi-currency-usd
        </v-icon>
        <p class="body-2">0,0%</p>
        <p class="caption">MARGIN</p>
      </div>
    </v-card>

    <v-card id="content" elevation="1">
      <v-card-title>
        <v-spacer></v-spacer>
        <div>
          <v-select v-model="selectedHeaders" class="caption" :items="tableHeaders1" label="Select Columns"
            background-color="#F8F9FB" multiple solo dense flat return-object>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2" small>
                <span class="caption">{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text caption">(+{{ selectedHeaders.length - 2 }} others)</span>
            </template>
          </v-select>
        </div>
      </v-card-title>
      <LoadingSpinner v-if="loading" />
      <v-data-table class="list px-10 pb-10" v-if="!loading" :headers="showHeaders" :items="stats"
        :items-per-page="itemsPerPage" :search="search" :page.sync="page" @page-count="pageCount = $event"
        :show-expand="permission" :expanded.sync="expanded" single-expand @item-expanded="onExpandButtonClick">
        <template v-slot:expanded-item="{ headers, item }" v-if="groupBy2">
          <td :colspan="headers.length">
            <v-data-table class="pt-8" :headers="tableHeaders2" :items="statsGroup" v-if="!loading2">
              <template v-slot:[`item.send_date`]="{ item }">
                {{ moment(item.send_date).format('D-MMMM-YYYY') }}
              </template>
              <template v-slot:[`item.ctor`]="{ item }">
                {{ (item.ctor && item.ctor !== 0) ? parseFloat(item.ctor).toFixed(2) + '%' : 0 }}
              </template>
              <template v-slot:[`item.segmentation`]="{ item }">
                {{ parseSegmentation(item.segmentation).join(', ') }}
              </template>
              <template v-slot:[`item.paymodels_templates`]="{ item }">
                {{ item.paymodels_templates.length > 0 ? item.paymodels_templates.map(v => v.payout).join('€, ') + '€' : 0
                }}
              </template>
            </v-data-table>
          </td>
        </template>
        <template v-slot:[`item.send_date`]="{ item }">
          {{ moment(item.send_date).format('D-MMMM-YYYY') }}
        </template>
        <template v-slot:[`item.ctor`]="{ item }">
          {{ (item.ctor && item.ctor !== 0) ? parseFloat(item.ctor).toFixed(2) + '%' : 0 }}
        </template>
        <template v-slot:[`item.segmentation`]="{ item }">
          {{ parseSegmentation(item.segmentation).join(', ') }}
        </template>
        <template v-slot:[`item.paymodels_templates`]="{ item }">
          {{ item.paymodels_templates.length > 0 ? item.paymodels_templates.map(v => v.payout).join('€, ') + '€' : 0 }}
        </template>
      </v-data-table>
    </v-card>
    <v-dialog 
      v-model="customModal" 
      :value="true" persistent 
      @click:outside="customModal = false" 
      max-width="450px"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon class="mr-2 pb-6" @click="customModal = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <label class="caption font-weight-bold pl-10">Custom Name</label><br />
        <div class="px-4 pb-8 d-flex">
          <v-text-field class="px-6 caption" v-model="customFilterName" background-color="#F8F9FB" dense solo
            flat></v-text-field>
          <v-btn v-if="editFilter" class="addButton px-6 mb-6 mr-6 white--text caption" color="tvf_color"
            :disabled="isEmpty" @click="saveFilter">
            Save
          </v-btn>
          <v-btn v-else class="addButton px-6 mb-6 mr-6 white--text caption" color="tvf_color" :disabled="isEmpty"
            @click="updateFilter">
            Update
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <div class="pt-4 pl-4">
      <v-pagination v-if="stats.length > 0" v-model="page" :length="pageCount" color="#f5f5f5"
        class="primary--text"></v-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import { format, startOfDay, endOfDay, startOfMonth, endOfMonth, subDays, subMonths } from 'date-fns';
import { mapState, mapActions } from "vuex";
import moment from 'moment';
import ApiService from "@/services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";

export default {
  name: "Stats",
  props: ["search"],
  components: {
    DatePicker,
    LoadingSpinner
  },
  data() {
    return {
      moment: moment,
      columnMap: {
        'country': 'country_id',
        'dates': 'send_date',
        'traffic': 'user_id',
        'database': 'database_id',
        'tags': 'tag_id',
        'campaign': 'template_id',
        'agency': 'agency_id',
        'owner': 'owner_id',
        'paymodel': 'paymodel_id',
        'target': 'segmentation',
        'sender': 'from_name',
        'router': 'platform_id'
        // agregue más mapeos aquí si es necesario
      },
      inverseColumnMap: {},
      showCustomRange: false,
      customModal: false,
      editFilter: false,
      customFilterName: '',
      filter: '',
      country: '',
      where: '',
      andWhere: '',
      filterBy: '',
      andBy: '',
      groupBy1: '',
      groupBy2: '',
      selectedGroup1: '',
      selectedGroup2: '',
      clauses: [],
      countryClauses: {
        type: 'where', column: '', operator: '=', value: '',
      },
      whereClauses: {
        type: 'where', column: '', operator: '=', value: '',
      },
      andWhereClauses: {
        type: 'where', column: '', operator: '=', value: '',
      },
      groupByClauses: {
        type: 'groupBy', column: '',
      },
      andByClauses: {
        type: 'groupBy', column: '',
      },
      dateRangeClauses: {
        type: 'dateRange', column: '', date: []
      },
      menu: false,
      dates: [startOfMonth(new Date()), endOfMonth(new Date())],
      ranges: {
        'Today': [new Date(), new Date()],
        'Yesterday': [subDays(new Date(), 1), subDays(new Date(), 1)],
        'Last 7 Days': [subDays(new Date(), 6), new Date()]
      },
      ranges2: {
        'Last 30 Days': [subDays(new Date(), 29), new Date()],
        'This Month': [startOfMonth(new Date()), endOfMonth(new Date())],
        'Last Month': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))]
      },
      datesString: "",
      date: null,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      permission: false,
      expanded: [],
      whereElements: [
        {
          id: 1,
          name: 'Traffic'
        },
        {
          id: 2,
          name: 'Router'
        },
        {
          id: 3,
          name: 'Database'
        },
        {
          id: 4,
          name: 'Tags'
        },
        {
          id: 5,
          name: 'Campaign'
        },
        {
          id: 6,
          name: 'Agency'
        },
        {
          id: 7,
          name: 'Owner'
        },
        {
          id: 8,
          name: 'Target'
        },
        {
          id: 9,
          name: 'Paymodel'
        },
        {
          id: 10,
          name: 'Sender'
        },
      ],
      groupElements: [
        {
          id: 1,
          name: 'Traffic'
        },
        {
          id: 2,
          name: 'Router'
        },
        {
          id: 3,
          name: 'Database'
        },
        {
          id: 4,
          name: 'Tags'
        },
        {
          id: 5,
          name: 'Campaign'
        },
        {
          id: 6,
          name: 'Country'
        },
        {
          id: 7,
          name: 'Agency'
        },
        {
          id: 8,
          name: 'Owner'
        },
        {
          id: 9,
          name: 'Target'
        },
        {
          id: 10,
          name: 'Paymodel'
        },
        {
          id: 11,
          name: 'Sendout'
        },
        {
          id: 12,
          name: 'Sender'
        },
      ],
      defaultHeaders: [
        {
          text: "SEND_DATE",
          align: "start",
          value: "send_date",
          sortable: true,
          width: '10%'
        },
        {
          text: "DATABASE",
          align: "start",
          value: "database_name",
          sortable: true,
          width: '10%'
        },
        {
          text: "CAMPAIGN",
          align: "start",
          value: "template_name",
          sortable: true,
        },
        {
          text: "TARGET",
          align: "start",
          value: "segmentation",
          sortable: true,
        },
        {
          text: "SENT",
          align: "start",
          value: "amount_sent",
          sortable: true,
        },
        {
          text: "BOUNCES",
          align: "start",
          value: "bounce",
          sortable: true,
        },
        {
          text: "OPENS",
          align: "start",
          value: "open",
          sortable: true,
        },
        {
          text: "U. OPENS",
          align: "start",
          value: "unique_open",
          sortable: true,
        },
        {
          text: "CLICKS",
          align: "start",
          value: "click",
          sortable: true,
        },
        {
          text: "U. CLICKS",
          align: "start",
          value: "unique_click",
          sortable: true,
        },
        {
          text: "AGENCY",
          align: "start",
          value: "agency_name",
          sortable: true,
        },
        {
          text: "TAGS",
          align: "start",
          value: "tag_name",
          sortable: true,
        },
        {
          text: "CTOR",
          align: "start",
          value: "ctor",
          sortable: true,
        },
        {
          text: "COMPL.",
          align: "start",
          value: "complaint",
          sortable: true,
        },
        {
          text: "UNSUB.",
          align: "center",
          value: "unsub",
          sortable: true,
        },
        {
          text: "PAYOUT",
          align: "start",
          value: "paymodels_templates",
          sortable: true,
        },
        {
          text: "TRAFFIC",
          align: "start",
          value: "user_username",
          sortable: true,
        },
      ],
      headers: [],
      originalHeaders: [],  // Almacena los headers originales
      headersMap: [
        {
          text: "SEND_DATE",
          align: "start",
          value: "send_date",
          sortable: true,
          width: '7%'
        },
        {
          text: "DATABASE",
          align: "start",
          value: "database_name",
          sortable: true,
          width: '15%'
        },
        {
          text: "CAMPAIGN",
          align: "start",
          value: "template_name",
          sortable: true,
        },
        {
          text: "TARGET",
          align: "start",
          value: "segmentation",
          sortable: true,
        },
        {
          text: "SENT",
          align: "start",
          value: "amount_sent",
          sortable: true,
        },
        {
          text: "BOUNCES",
          align: "start",
          value: "bounce",
          sortable: true,
        },
        {
          text: "OPENS",
          align: "start",
          value: "open",
          sortable: true,
        },
        {
          text: "U. OPENS",
          align: "start",
          value: "unique_open",
          sortable: true,
        },
        {
          text: "CLICKS",
          align: "start",
          value: "click",
          sortable: true,
        },
        {
          text: "U. CLICKS",
          align: "start",
          value: "unique_click",
          sortable: true,
        },
        {
          text: "CTOR",
          align: "start",
          value: "ctor",
          sortable: true,
        },
        {
          text: "COMPL.",
          align: "start",
          value: "complaint",
          sortable: true,
        },
        {
          text: "UNSUB.",
          align: "center",
          value: "unsub",
          sortable: true,
        },
        {
          text: "ECPM NET.",
          align: "center",
          value: "ecpm",
          sortable: true,
        },
        {
          text: "PERF. GEN",
          align: "center",
          value: "performanceGenerated",
          sortable: true,
        },
        {
          text: "PERF. INV",
          align: "center",
          value: "performanceInv",
          sortable: true,
        },
        {
          text: "LEADS",
          align: "center",
          value: "totalLeads",
          sortable: true,
        },
        {
          text: "LEADS INV.",
          align: "center",
          value: "leadInv",
          sortable: true,
        },
        {
          text: "PAYOUT",
          align: "start",
          value: 'paymodels_templates',
          sortable: true,
        },
        {
          text: "PAYMODEL",
          align: "start",
          value: 'paymodel_name',
          sortable: true,
        },
        {
          text: "FORECAST",
          align: "center",
          value: "forecast",
          sortable: true,
        },
        {
          text: "SENDER",
          align: "start",
          value: "sender_from_name",
          sortable: true,
        },
        {
          text: "AGENCY",
          align: "start",
          value: "agency_name",
          sortable: true,
        },
        {
          text: "TRAFFIC",
          align: "start",
          value: "user_username",
          sortable: true,
        },
        {
          text: "TAGS",
          align: "start",
          value: "tag_name",
          sortable: true,
        },
        {
          text: "OWNER",
          align: "start",
          value: "owner_name",
          sortable: true,
        },
        {
          text: "ROUTER",
          align: "start",
          value: "platform_name",
          sortable: true,
        },
        {
          text: "COUNTRY",
          align: "start",
          value: "country_name",
          sortable: true,
        },
      ],
      selectedHeaders: [],
      tableHeaders1: [],
      tableHeaders2: [],
      idFilter: ''
    };
  },

  computed: {
    isEmpty() {
      return (
        this.customFilterName == ''
      );
    },

    loading() {
      return this.$store.state.loading;
    },
    loading2() {
      return this.$store.state.loading2;
    },

    formattedDate() {
      return this.dates ? `${format(this.dates[0], 'yyyy/MM/dd')} - ${format(this.dates[1], 'yyyy/MM/dd')}` : 'Select date range';
    },

    ...mapState([
      "stats_filters",
      "databases",
      "agencies",
      "stats",
      "countriesByUser",
      "databasesByCountry",
      "tagsByCountry",
      "platformsByCountry",
      "agenciesByCountry",
      "sendersByCountry",
      "templatesByCountry",
      "usersByCountry",
      "ownersByCountry",
      "targetsByCountry",
      "statsGroup"
    ]),

    filterByItems() {
      // Si no se ha seleccionado nada en "andBy", muestra todos los elementos
      if (!this.andWhere) {
        return this.whereElements;
      }
      // Si se ha seleccionado algo en "andBy", excluye ese elemento
      return this.whereElements.filter(element => element.id !== this.andWhere.id);
    },
    andByItems() {
      // Si no se ha seleccionado nada en "filterBy", muestra todos los elementos
      if (!this.where) {
        return this.whereElements;
      }
      // Si se ha seleccionado algo en "filterBy", excluye ese elemento
      return this.whereElements.filter(element => element.id !== this.where.id);
    },
    groupBy1Items() {
      // Si no se ha seleccionado nada en "groupBy2", muestra todos los elementos
      if (!this.groupBy2) {
        return this.groupElements;
      }
      // Si se ha seleccionado algo en "groupBy2", excluye ese elemento
      return this.groupElements.filter(element => element.name !== this.groupBy2.name);
    },
    groupBy2Items() {
      // Si no se ha seleccionado nada en "groupBy1", muestra todos los elementos
      if (!this.groupBy1) {
        return this.groupElements;
      }
      // Si se ha seleccionado algo en "groupBy1", excluye ese elemento
      return this.groupElements.filter(element => element.name !== this.groupBy1.name);
    },

    getFilterItems() {
      // Devuelve la lista relevante del estado en función del valor seleccionado en filterBy
      if (this.where.name === 'Traffic') {
        return this.usersByCountry; // Suponiendo que tienes una lista de usuarios llamada usersList en el estado
      } else if (this.where.name === 'Database') {
        return this.databasesByCountry; // Suponiendo que tienes una lista de bases de datos llamada databasesList en el estado
      } else if (this.where.name === 'Router') {
        return this.platformsByCountry;
      } else if (this.where.name === 'Tags') {
        return this.tagsByCountry;
      } else if (this.where.name === 'Campaign') {
        return this.templatesByCountry;
      } else if (this.where.name === 'Agency') {
        return this.agenciesByCountry;
      } else if (this.where.name === 'Owner') {
        return this.ownersByCountry;
      } else if (this.where.name === 'Paymodel') {
        return this.$store.state.paymodels;
      } else if (this.where.name === 'Sender') {
        return this.sendersByCountry;
      } else if (this.where.name === 'Target') {
        return Object.values(this.targetsByCountry);
      } else if (this.where.name === 'Sendout') {
        return this.stats;
      } else {
        return []; // Devuelve un array vacío si no hay una selección válida en filterBy
      }
    },
    getAndByItems() {
      // Devuelve la lista relevante del estado en función del valor seleccionado en filterBy
      if (this.andWhere.name === 'Traffic') {
        return this.usersByCountry; // Suponiendo que tienes una lista de usuarios llamada usersList en el estado
      } else if (this.andWhere.name === 'Database') {
        return this.databasesByCountry; // Suponiendo que tienes una lista de bases de datos llamada databasesList en el estado
      } else if (this.andWhere.name === 'Router') {
        return this.platformsByCountry;
      } else if (this.andWhere.name === 'Tags') {
        return this.tagsByCountry;
      } else if (this.andWhere.name === 'Campaign') {
        return this.templatesByCountry;
      } else if (this.andWhere.name === 'Agency') {
        return this.agenciesByCountry;
      } else if (this.andWhere.name === 'Owner') {
        return this.ownersByCountry;
      } else if (this.andWhere.name === 'Paymodel') {
        return this.$store.state.paymodels;
      } else if (this.andWhere.name === 'Sender') {
        return this.sendersByCountry;
      } else if (this.andWhere.name === 'Target') {
        return this.targetsByCountry;
      } else if (this.andWhere.name === 'Sendout') {
        return this.stats;
      } else {
        return []; // Devuelve un array vacío si no hay una selección válida en filterBy
      }
    },

    showHeaders() {
      return this.tableHeaders1.filter(s => this.selectedHeaders.includes(s));
    }
  },

  watch: {
    dates: {
      handler() {
        this.datesString = `${this.dates.start} - ${this.dates.end}`;
      },
      immediate: true,
    },
    country(newCountry, oldCountry) {
      if (newCountry !== oldCountry) { // Si el país ha cambiado
        this.$store.dispatch('getDBByCountry', newCountry);
      }
    },
  },

  created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = [...this.headers];
    this.originalHeaders = [...this.headers];  // Asigna los headers originales en created

    // Asigna los headers predeterminados a las tablas en created
    this.tableHeaders1 = [...this.headers];
    this.tableHeaders2 = [...this.headers];
  },

  mounted() {
    this.$store.dispatch("getStatsFilters");
    this.$store.dispatch("getPaymodels");
    this.$store.dispatch("getStats");
    this.$store.dispatch("getCountriesDB");
    this.$store.dispatch("getAgenciesUser");
    this.$store.dispatch("getSendersByCountry");
    this.$store.dispatch("getTargetsByCountry");
    this.$store.dispatch("getTemplatesByCountry");
    this.$store.dispatch("getPlatformsByCountry");
    this.$store.dispatch("getTagsByCountry");
    this.$store.dispatch("getUsersByCountry");
    this.$store.dispatch("getOwnersByCountry");

    for (let key in this.columnMap) {
      this.inverseColumnMap[this.columnMap[key]] = key;
    }
  },

  methods: {
    ...mapActions([
      'getDatabases',
      'getAgencies',
      'getStats'
    ]),

    // Método para agregar cláusulas
    addClause(clauseObj, filterName, value = null, dateValue = null) {
      // Si filterName está en columnMap, usa el mapeo. Si no, asume que filterName es el nombre de la columna
      clauseObj.column = this.columnMap[filterName] ? this.columnMap[filterName] : filterName;

      if (value !== null) {
        clauseObj.value = value;
      }
      // Agregamos la excepción para el caso de 'dates'
      if (dateValue !== null) {
        clauseObj.date = dateValue;
      }
      this.clauses.push(JSON.stringify(clauseObj));
    },

    // Método para actualizar los encabezados
    updateHeaders(selectedGroup, groupByClause, headersDataProperty) {
      this.$store.dispatch('setLoadingAction', true);

      if (selectedGroup) {
        this.selectedGroup = selectedGroup;
        // Tienes que clonar el objeto para que no estés empujando la misma referencia todo el tiempo
        let clone = { ...groupByClause };
        this.addClause(clone, this.selectedGroup.name.toLowerCase());
        this.selectedHeaders = this.getFilteredHeaders(this.selectedGroup)

        this[headersDataProperty] = this.getFilteredHeaders(this.selectedGroup).filter(s => this.selectedHeaders.includes(s));
      } else {
        this.selectedGroup = null;
        this.selectedHeaders = this.headers;
        this[headersDataProperty] = [...this.originalHeaders];
      }
      this.$store.dispatch('setLoadingAction', false);
    },

    // En tu función principal puedes usar estos métodos para agregar las cláusulas y actualizar los encabezados
    filterHeadersAndFetchStats() {
      this.expanded = [];
      if (this.groupBy2) {
        this.permission = true
      }

      // Limpia this.clauses
      this.clauses = [];

      // Agrega las cláusulas
      if (this.country) {
        this.addClause(this.countryClauses, 'country', this.country);
      }
      if (this.dates) {
        const convertedDates = this.dates.map((date, index) => {
          const convertedDate = moment(date).format('YYYY-MM-DD');
          if (index === 1) {
            return moment(convertedDate).add(1, 'days').format('YYYY-MM-DD');
          }
          return convertedDate;
        });
        this.addClause(this.dateRangeClauses, 'dates', null, convertedDates);
      }
      if (this.where) {

        if (this.where.id === 8) {
          let formattedValue = null;
          for (const key in this.targetsByCountry) {
            if (this.targetsByCountry.hasOwnProperty(key) && this.targetsByCountry[key] === this.filterBy) {
              formattedValue = {
                [key]: this.filterBy
              };
              break;
            }
          }
          this.addClause(this.whereClauses, this.where.name.toLowerCase(), JSON.stringify(formattedValue));
        } else {
          this.addClause(this.whereClauses, this.where.name.toLowerCase(), this.filterBy);
        }
      }
      if (this.andWhere) {
        this.addClause(this.andWhereClauses, this.andWhere.name.toLowerCase(), this.andBy);
      }

      // Actualiza los encabezados y agrega las cláusulas de agrupación
      this.updateHeaders(this.groupBy1, this.groupByClauses, 'tableHeaders1');

      // llamar a la acción de Vuex
      this.$store.dispatch('getStats', this.clauses);
    },

    parseSegmentation(segmentationString) {
      if (segmentationString) {
        try {
          let parsedSegmentation = JSON.parse(segmentationString);
          return Object.values(parsedSegmentation);
        } catch (error) {
          console.error('Error parsing segmentation string:', error);
        }
      }
      return [];
    },

    resetFilters() {
      this.country = '',
        this.dates = [startOfMonth(new Date()), endOfMonth(new Date())],
        this.where = '',
        this.andWhere = '',
        this.groupBy1 = '',
        this.groupBy2 = '',
        this.filter = '',
        this.filterBy = '',
        this.andBy = '',
        this.permission = false
      this.selectedHeaders = [...this.originalHeaders];
      this.headers = [...this.originalHeaders];

      // Limpia las cláusulas
      this.clauses = [];

      // Vuelve a ejecutar la función para rellenar las cláusulas con los valores por defecto
      this.filterHeadersAndFetchStats();
    },

    getFilterInfo(item) {
      this.country = '',
        this.dates = [startOfMonth(new Date()), endOfMonth(new Date())],
        this.where = '',
        this.andWhere = '',
        this.groupBy1 = '',
        this.groupBy2 = '',
        this.filterBy = '',
        this.andBy = ''

      const filter = JSON.parse(item.filter)

      for (const f of filter) {
        const fil = JSON.parse(f)

        if (fil === null) continue;
        const originalFilterName = this.inverseColumnMap[fil.column];

        if (fil.type === "dateRange") {
          // Convertir las cadenas de fecha en objetos Date
          let startDate = new Date(fil.date[0]);
          let endDate = new Date(fil.date[1]);

          this.dates = [startOfDay(startDate), endOfDay(endDate)];

        } else if (fil.type === "where") {
          // Verifica el nombre de la columna para asignar el filtro correspondiente
          if (originalFilterName === 'country') {
            this.country = fil.value;
            this.onCountryChange(this.country);
          } else {
            // Este código asume que 'where' siempre es asignado antes que 'andWhere'. Si este no es el caso, puede que necesites agregar alguna lógica adicional.
            if (this.where === '') {
              this.where = {
                name: originalFilterName[0].toUpperCase() + originalFilterName.substring(1),
                id: fil.value
              }; // asigna la columna y el valor al filtro 'where'
              this.filterBy = fil.value;
            } else if (this.andWhere === '') {
              this.andWhere = {
                name: originalFilterName[0].toUpperCase() + originalFilterName.substring(1),
                id: fil.value
              }; // asigna la columna y el valor al filtro 'andWhere'
              this.andBy = fil.value
            }
          }
        } else if (fil.type === "groupBy") {
          if (this.groupBy1 === '') {
            this.groupBy1 = {
              name: originalFilterName[0].toUpperCase() + originalFilterName.substring(1),
              id: fil.value
            };
          }
          else if (this.groupBy2 === '') {
            this.groupBy2 = {
              name: originalFilterName[0].toUpperCase() + originalFilterName.substring(1),
              id: fil.value
            };
          }
        }
        // Añade más casos aquí si tienes más tipos de filtro.
      }
    },

    // Botón de expandir
    async onExpandButtonClick({ item }) {
      // limpia las cláusulas
      this.clauses = [];

      // La cláusula groupBy1 se convierte en cláusula where
      if (this.groupBy1) {
        // Encuentra el nombre real de la columna utilizando el mapeo
        const realColumnName = this.columnMap[this.groupBy1.name.toLowerCase()];
        // Encuentra el valor del campo usando el nombre real de la columna
        const filterValue = item.id;

        this.addClause(this.whereClauses, this.groupBy1.name.toLowerCase(), filterValue);
      }
      const selectedHeaders = [...this.selectedHeaders];
      // Actualiza los encabezados y agrega las cláusulas de agrupación
      this.updateHeaders(this.groupBy2, this.groupByClauses, 'tableHeaders2');
      // Restaura los headers seleccionados después de actualizar los encabezados
      this.selectedHeaders = selectedHeaders;
      // llamar a la acción de Vuex
      await this.$store.dispatch("getStatsByGroup", this.clauses);
      // Devuelve los datos obtenidos
      return this.$store.state.statsGroup;
    },

    openModal() {
      if (!this.filter) {
        this.editFilter = false
        this.customModal = true
      } else {
        this.editFilter = true
        this.customFilterName = this.filter.name
        this.idFilter = this.filter.id
        this.customModal = true
      }
    },

    updateFilter() {
      // Limpia las cláusulas
      this.clauses = [];

      if (this.country) {
        this.addClause(this.countryClauses, 'country', this.country);
      }
      if (this.dates) {
        this.addClause(this.dateRangeClauses, 'dates', null, this.dates);
      }
      if (this.where) {
        this.addClause(this.whereClauses, this.where.name.toLowerCase(), this.filterBy);
      }
      if (this.andWhere) {
        this.addClause(this.andWhereClauses, this.andWhere.name.toLowerCase(), this.andBy);
      }

      this.updateHeaders(this.groupBy1, this.groupByClauses, 'tableHeaders1');

      ApiService.put("filters/" + this.filter.id, {
        user_id: this.$store.state.auth.user.id,
        name: this.filter.name,
        filter: this.clauses,
      })
        .then((response) => {
          this.customModal = false
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "New custom filter has been updated!",
            timeout: 3000,
          });
          this.$store.dispatch("getStatsFilters");
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data.errors)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error,
                  timeout: 3000,
                });
              });
          }
        });
    },

    saveFilter() {
      // Limpia las cláusulas
      this.clauses = [];

      if (this.country) {
        this.addClause(this.countryClauses, 'country', this.country);
      }
      if (this.dates) {
        this.addClause(this.dateRangeClauses, 'dates', null, this.dates);
      }
      if (this.where) {
        this.addClause(this.whereClauses, this.where.name.toLowerCase(), this.filterBy);
      }
      if (this.andWhere) {
        this.addClause(this.andWhereClauses, this.andWhere.name.toLowerCase(), this.andBy);
      }

      this.updateHeaders(this.groupBy1, this.groupByClauses, 'tableHeaders1');

      ApiService.post("filters", {
        user_id: this.$store.state.auth.user.id,
        name: this.customFilterName,
        filter: this.clauses,
      })
        .then((response) => {
          this.customModal = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "New custom filter has been created!",
            timeout: 3000,
          });
          this.$store.dispatch("getStatsFilters");
          this.$store.dispatch("getStats", this.clauses);
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data.errors)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error,
                  timeout: 3000,
                });
              });
          }
        });
    },

    onCountryChange(countryId) {
      this.$store.dispatch('getDBByCountry', countryId);
      this.$store.dispatch('getTagsByCountry', countryId);
      this.$store.dispatch('getPlatformsByCountry', countryId);
      this.$store.dispatch('getAgenciesUser', countryId);
      this.$store.dispatch('getSendersByCountry', countryId);
      this.$store.dispatch('getTemplatesByCountry', countryId);
      this.$store.dispatch("getPlatformsByCountry", countryId);
      this.$store.dispatch("getUsersByCountry", countryId);
      this.$store.dispatch("getOwnersByCountry", countryId);
      this.$store.dispatch("getTargetsByCountry", countryId);
    },

    sumField(fieldName) {

      return this.stats.reduce((total, item) => {
        // Necesitamos acceder a las propiedades anidadas en el objeto
        const keys = fieldName.split('.');
        let value = item;

        keys.forEach(key => {
          value = value[key];
        });
        // Si el valor es numérico, lo añadimos al total
        if (!isNaN(value)) {
          total += parseInt(value);
        }

        return total;
      }, 0);
    },

    averageField(fieldName, volumeName) {
      if (!this.stats.length) return 0;

      let filteredStats = this.stats.filter(row => row[fieldName] !== 0);

      let weightedSum = filteredStats.reduce((accumulatedValue, currentRow) => {
        let percentage = currentRow[fieldName] || 0;
        let volume = currentRow[volumeName] || 1; // Suponemos que el volumen es 1 si no está especificado.
        return accumulatedValue + percentage * volume;
      }, 0);

      let totalVolume = filteredStats.reduce((accumulatedValue, currentRow) => accumulatedValue + (parseInt(currentRow[volumeName]) || 1), 0);

      return totalVolume ? (weightedSum / totalVolume).toFixed(2) + '%' : 'N/A';
    },


    getFilteredHeaders(filter) {
      if (filter) {
        const lowerCaseFilter = filter.name.toLowerCase();
        const selectedHeader = this.headers.find(header => header.text.toLowerCase() === lowerCaseFilter);

        let filteredHeaders;
        if (['Database'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'template_name', 'agency_name', 'payout', 'paymodel_name', 'segmentation', 'sender_from_name', 'tag_name', 'user_username', 'owner_name', 'country_name', 'platform_name'].includes(header.value));
        } else if (['Agency'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'template_name', 'payout', 'paymodel_name', 'segmentation', 'tag_name', 'database_name', 'sender_from_name', 'user_username', 'owner_name', 'country_name', 'platform_name'].includes(header.value));
        } else if (['Traffic'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'template_name', 'agency_name', 'payout', 'paymodel_name', 'segmentation', 'tag_name', 'sender_from_name', 'database_name', 'forecast', 'owner_name', 'country_name', 'platform_name'].includes(header.value));
        } else if (['Tags'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'template_name', 'agency_name', 'payout', 'paymodel_name', 'segmentation', 'database_name', 'sender_from_name', 'user_username', 'forecast', 'platform_name', 'owner_name', 'country_name'].includes(header.value));
        } else if (['Router'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'template_name', 'agency_name', 'payout', 'paymodel_name', 'segmentation', 'tag_name', 'database_name', 'sender_from_name', 'user_username', 'forecast', 'owner_name', 'country_name'].includes(header.value));
        } else if (['Country'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'template_name', 'agency_name', 'payout', 'paymodel_name', 'segmentation', 'tag_name', 'database_name', 'sender_from_name', 'user_username', 'forecast', 'owner_name', 'platform_name'].includes(header.value));
        } else if (['Target'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'template_name', 'agency_name', 'payout', 'paymodel_name', 'segmentation', 'tag_name', 'database_name', 'sender_from_name', 'user_username', 'platform_name', 'owner_name', 'country_name'].includes(header.value));
        } else if (['Send Date'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['template_name', 'agency_name', 'payout', 'paymodel_name', 'segmentation', 'tag_name', 'database_name', 'sender_from_name', 'user_username', 'country', 'platform_name'].includes(header.value));
        } else if (['Campaign'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'payout', 'paymodel_name', 'segmentation', 'tag_name', 'database_name', 'user_name', 'forecast', 'owner_name', 'country_name', 'sender_from_name', 'agency_name', 'user_username', 'platform_name'].includes(header.value));
        } else if (['Paymodel'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'template_name', 'agency_name', 'segmentation', 'tag_name', 'database_name', 'user_name', 'owner_name', 'country_name', 'platform_name', 'sender_from_name', 'agency_name', 'user_username', 'platform_name'].includes(header.value));
        } else if (['Owner'].includes(filter.name)) {
          filteredHeaders = this.headers.filter(header => !['send_date', 'template_name', 'agency_name', 'payout', 'paymodel_name', 'segmentation', 'tag_name', 'database_name', 'sender_from_name', 'user_username', 'country_name', 'platform_name'].includes(header.value));
        } else {
          filteredHeaders = this.headers;
        }

        if (selectedHeader) {
          // Remove the selectedHeader if it is in the filteredHeaders array.
          filteredHeaders = filteredHeaders.filter(header => header.value !== selectedHeader.value);
          // Add the selectedHeader at the beginning of the filteredHeaders array.
          filteredHeaders.unshift(selectedHeader);
        }
        return filteredHeaders;

      } else {
        return this.headers;
      }
    },

    exportToCSV() {
  let csvContent = "data:text/csv;charset=utf-8,";

  // Solo se añaden los títulos de las columnas seleccionadas
  const titulos = this.selectedHeaders.map(header => header.text).join(";");
  csvContent += titulos + "\n";

  // Crear una serie de promesas basadas en cada fila de stats
  const statsPromises = this.stats.map(row => {
    return this.onExpandButtonClick({ item: row }).then(statsGroup => {
      // Crear una cadena CSV para la fila principal
      let rowCSV = this.selectedHeaders.map(header => {
        return this.formatFieldValue(header, row[header.value]);
      }).join(";");

      // Añade las filas de statsGroup a rowCSV
      statsGroup.forEach((subRow, index) => {
        if (index === 0) { // Solo para la primera fila de statsGroup
          // Añade un salto de línea y los títulos de las columnas de statsGroup
          rowCSV += "\n;" + Object.keys(subRow).map(key => key.toUpperCase()).join(";");
        }

        // Añade los datos de statsGroup
        rowCSV += "\n;" + Object.keys(subRow).map(key => {
          return this.formatFieldValue({ value: key, text: key }, subRow[key]);
        }).join(";");
      });

      return rowCSV;
    });
  });

  // Espera hasta que todas las promesas se resuelvan
  Promise.all(statsPromises).then(rows => {
    csvContent += rows.join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "TVF_STATS_" + this.formattedDate +".csv");
    document.body.appendChild(link);

    link.click();
  });
},

    formatFieldValue(header, value) {
      if (header.value === 'send_date') {
        return moment(value).format('DD-MMMM-YYYY');
      } else if (header.value === 'ctor') {
        return parseFloat(value).toFixed(2) + '%';
      } else if (header.value === 'paymodels_templates') {
        return value.map(v => v.payout).join(', ')
      } else if (header.value === 'segmentation') {
        return this.parseSegmentation(value).join(', ')
      } else {
        return value;
      }
    },

    setRange(range) {
      this.dates = [startOfDay(range[0]), endOfDay(range[1])];
      this.showCustomRange = false;
    },
    cancelarSeleccion() {
      this.menu = false;
    },
    aceptarSeleccion() {
      // Realiza las operaciones necesarias con this.date (rango de fechas seleccionado)
      this.dates = `${this.date.start} - ${this.date.end}`;
      this.menu = false;
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px;
}

.v-menu__content {
  top: 145px !important;
}

.buttons {
  background-color: white;
  border-radius: 5px;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-2,
.col-1 {
  width: 100%;
  padding: 0 12px 0 12px;
}
</style>