<template>
  <div>
    <v-card
      id="content"
      elevation="1"
    >
      <LoadingSpinner v-if="loading" />
      <v-data-table
        class="list px-10 pt-10"
        v-if="!loading"
        :headers="headers"
        :items="filteredSendouts"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        @page-count="pageCount = $event"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :custom-sort="customSort"
        :search="search"
        show-expand
        single-expand
      >
        <!-- Para la columna "NAME" -->
        <template v-slot:[`header.template.name`]="{ header }">
          {{ header.text }}
          <v-menu
            offset-y
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="header.value === 'template.name'"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  small
                  :color="name ? 'black' : ''"
                >
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="name"
                class="pa-2 caption"
                type="text"
                :autofocus="true"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <v-btn
                @click="name = ''"
                small
                text
                color="primary"
                class="mb-2 ml-2 tvf_color--text"
              >Clean</v-btn>
            </div>
          </v-menu>
        </template>
        <!-- Para la columna "SEND DATE" -->
        <template v-slot:[`header.send_date`]="{ header }">
          {{ header.text }}
          <v-menu
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                v-if="header.value === 'send_date'"
              >
                <v-icon
                  small
                  :color="send_date ? 'black' : ''"
                >mdi-magnify</v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <date-picker
                    prefix-class="xmx"
                    class="sendouts pa-3"
                    v-model="dates"
                    type="date"
                    range
                    v-bind="attrs"
                    v-on="on"
                    placeholder="Select date range"
                  ></date-picker>                  
                </template>
                <!-- Replace v-date-picker with your date-picker component -->
              </v-menu>
              <v-btn
                @click="resetDates"
                small
                text
                color="primary"
                class="mb-2 ml-2 tvf_color--text"
              >Clean</v-btn>
            </div>
          </v-menu>
        </template>


        <!-- Para la columna "DATABASE" -->
        <template v-slot:[`header.database.name`]="{ header }">
          {{ header.text }}
          <v-menu
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                v-if="header.value === 'database.name'"
              >
                <v-icon
                  small
                  :color="database ? 'black' : ''"
                >mdi-magnify</v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="database"
                class="pa-2 caption"
                type="text"
                :autofocus="true"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <v-btn
                @click="database = ''"
                small
                text
                color="primary"
                class="mb-2 ml-2 tvf_color--text"
              >Clean</v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
          <v-icon
            v-if="item.type === 'split'"
            :class="[
                        'v-data-table__expand-icon',
                        { 'v-data-table__expand-icon--active': isExpanded },
                        ]"
            @click.stop="expand(!isExpanded)"
          >$expand</v-icon>
        </template>
        <template v-slot:[`item.amount_sent`]="{ item }">
          {{ item.amount_sent | formatNumber }}
        </template>
        <template v-slot:[`item.open`]="{ item }">
          {{ item.open | formatNumber }}
        </template>
        <template v-slot:[`item.click`]="{ item }">
          {{ item.click | formatNumber }}
        </template>
        <template v-slot:[`item.complaint`]="{ item }">
          {{ item.complaint | formatNumber }}
        </template>
        <template v-slot:[`item.unsub`]="{ item }">
          {{ item.unsub | formatNumber }}
        </template>
        <template v-slot:[`item.agency`]="{ item }">
          {{ item.agency }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            v-if="item.status !== null"
            class="status black--text font-weight-bold px-2"
            :color="item.status.name==='Pending validation'?'#FBBA3C':item.status.name==='Draft'?'#A3A3A3':item.status.name==='Canceled'?'rgba(255, 0, 91, 0.6)':'#3CFBB6'"
            depressed
            small
          >
            {{ item.status.name }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            small
            @click="addLeadsBtn(item)"
          >
            <v-icon
              small
              color="black"
              v-model="item.actions"
            >
              mdi-currency-eur
            </v-icon>
          </v-btn>
          <v-menu
            top
            :offset-x="offset"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon
                  small
                  color="black"
                  v-model="item.actions"
                >
                  mdi-menu
                </v-icon>
              </v-btn>
            </template>
            <v-card width="200px">
              <v-list>
                <v-list-item @click="showInfo(item)">
                  <v-list-item-title class="caption font-weight-bold"><v-icon
                      class="mr-2"
                      color="black"
                      v-model="item.actions"
                      small
                    >
                      mdi-eye
                    </v-icon>More info</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="item.status !== null && item.status.name === 'In Progress'"
                  @click="pauseResume(item)"
                >
                  <v-list-item-title class="caption font-weight-bold"><v-icon
                      class="mr-2"
                      color="black"
                      v-model="item.actions"
                      small
                    >
                      mdi-pause
                    </v-icon>Pause</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="item.status !== null && item.status.name === 'Paused'"
                  @click="pauseResume(item)"
                >
                  <v-list-item-title class="caption font-weight-bold"><v-icon
                      class="mr-2"
                      color="black"
                      v-model="item.actions"
                      small
                    >
                      mdi-replay
                    </v-icon>Resume</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="item.status !== null && item.status.name === 'Pending validation'"
                  @click="validateSendout(item)"
                >
                  <v-list-item-title class="caption font-weight-bold"><v-icon
                      class="mr-2"
                      color="black"
                      v-model="item.actions"
                      small
                    >
                      mdi-check
                    </v-icon>Validate</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openConfirmation(item)">
                  <v-list-item-title class="caption font-weight-bold"><v-icon
                      class="mr-2"
                      color="black"
                      v-model="item.actions"
                      small
                    >
                      mdi-close
                    </v-icon>Cancel</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
      </v-data-table>
      <v-dialog
        v-model="confirmation"
        :value="true"
        persistent
        @click:outside="confirmation = false"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-col>
              <v-row class="pa-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>{{ modalName }}</h4>
                  <h5>{{ modalDb }}</h5>
                  <h5>Date: {{ modalDate }}</h5><br />
                  <p class="body-2 mt-4">Are you sure you want to delete this sendout?</p>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-btn
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="deleteSendout()"
                  >
                    Delete
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
    </v-card>
    <div class="pt-4 pl-4">
      <v-pagination
        v-if="sendouts.length > 0"
        v-model="page"
        :length="pageCount"
        color="#f5f5f5"
        class="primary--text"
      ></v-pagination>
    </div>
    <more-info
      v-if="infoModal"
      @close="infoModal = false"
      :sendout="sendout"
    ></more-info>
    <add-leads
      v-if="addLeads"
      @close="addLeads = false"
      :paymodels="paymodels"
      :idSendout="idSendout"
      @updateList="getSendouts"
    ></add-leads>

  </div>
</template>

<script>
import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";
import moreInfo from "../components/sendout/moreInfo";
import addLeads from "../components/sendout/addLeads";
import DatePicker from "vue2-datepicker";
import { mapState } from "vuex";

export default {
  props: ["search"],
  name: "Sendouts",
  components: {
    LoadingSpinner,
    moreInfo,
    addLeads,
    DatePicker
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      addLeads: false,
      offset: true,
      infoModal: false,
      confirmation: false,
      modalName: "",
      modalDate: "",
      modalDb: "",
      name: "",
      database: "",
      send_date: "",
      itemId: "",
      idSendout: "",
      segment: [],
      idSendouts: [],
      sendout: [],
      columnFilters: {},
      sortBy: [], // Agrega esto
      sortDesc: [],
      menu: false,
      dates: ['', ''],
      datesString: "",
      date: null,
      singleExpand: false,
      headers: [
        {
          text: "SEND DATE",
          align: "start",
          value: "send_date",
          filterable: true,
          width: "20%",
        },
        {
          text: "DATABASE",
          align: "start",
          value: "database.name",
          filterable: true,
          width: "15%",
        },
        {
          text: "CAMPAIGN NAME",
          align: "start",
          value: "template.name",
          filterable: true,
          width: "20%",
        },
        {
          text: "AGENCY",
          align: "start",
          value: "agency[0].name",
          filterable: true,
          width: "15%",
        },
        {
          text: "CREATED AT",
          align: "start",
          value: "created_at_format",
          filterable: true,
          width: "10%",
        },
        /*     {
          text: "TYPE",
          align: "start",
          value: "type",
          filterable: true,
        }, */
        {
          text: "USER",
          align: "start",
          value: "user.first_name",
          filterable: true,
          width: "7%",
        },
        {
          text: "AMOUNT",
          align: "start",
          value: "amount_sent",
          filterable: true,
          width: "7%",
        },
        {
          text: "DELIV.",
          align: "start",
          value: "",
          filterable: true,
          width: "7%",
        },
        {
          text: "OPENS",
          align: "start",
          value: "open",
          filterable: true,
          width: "7%",
        },
        {
          text: "CLICK",
          align: "start",
          value: "click",
          filterable: true,
          width: "7%",
        },
        {
          text: "COMP.",
          align: "start",
          value: "complaint",
          filterable: true,
          width: "7%",
        },
        {
          text: "UNS.",
          align: "start",
          value: "unsub",
          filterable: true,
          width: "7%",
        },
        {
          text: "GEN. €",
          align: "start",
          value: "generated",
          filterable: true,
          width: "10%",
        },
        {
          text: "ECPM",
          align: "start",
          value: "ecpm",
          filterable: true,
          width: "5%",
        },
        {
          text: "ECPC",
          align: "start",
          value: "ecpc",
          filterable: true,
          width: "5%",
        },
        {
          text: "STATUS",
          align: "center",
          value: "status",
          width: "5%",
          sortable: true,
        },
        {
          text: "",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },

  mounted() {
    this.$store.dispatch("getSendouts");
  },

  computed: {
    ...mapState([
      "sendouts", // esto mapeará this.sendouts a this.$store.state.sendouts
    ]),

    filteredSendouts() {
      return this.sendouts.filter(
        (sendout) =>
          this.filteredCampaignName(sendout) &&
          this.filterSendDate(sendout) &&
          this.filteredDatabase(sendout)
      );
    },
  },

  watch: {
    dates: {
      handler() {
        this.datesString = `${this.dates.start} - ${this.dates.end}`;
      },
      immediate: true,
    },
  },

  methods: {
    customSort(items, sortByArray, sortDescArray) {
      if (sortByArray[0] === "status") {
        items.sort((a, b) => {
          const nameA = a.status ? a.status.name : "";
          const nameB = b.status ? b.status.name : "";
          return (nameA < nameB ? -1 : 1) * (sortDescArray[0] ? -1 : 1);
        });
      }
      return items;
    },

    resetDates() {
      this.dates = ['', '']
    },

/*     aceptarSeleccion() {
      this.dates.start = this.date[0];
      this.dates.end = this.date[1];
      this.menu = false;
    }, */

    addLeadsBtn(info) {
      this.paymodels = info.paymodels_templates;
      this.idSendout = info.id;
      this.addLeads = true;
    },

    filteredCampaignName(sendout) {
      console.log(sendout)
      return sendout.template.name
        .toLowerCase()
        .includes(this.name.toLowerCase());
    },

    filterSendDate(sendout) {
      let date = sendout.send_date.substring(0, 10);
      // Si no se ha seleccionado un rango de fechas, no filtrar por fecha.
      if (!this.dates.start || !this.dates.end) {
        return true;
      }

      /*    // Si se ha seleccionado una fecha de inicio pero no una de fin,
      // incluir el item si su fecha es igual a la fecha de inicio.
      if (this.dates.start && !this.dates.end) {
        return date = this.dates.start;
      }
 */
      // Si la fecha está dentro del rango de fechas seleccionado, incluir el item.
      return date >= this.dates.start && date <= this.dates.end;
    },

    filteredDatabase(sendout) {
      return sendout.database.name
        .toLowerCase()
        .includes(this.database.toLowerCase());
    },

    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    async pauseResume(sendout) {
      await ApiService.post("sendouts/pauseResume", {
        idSendout: sendout.id,
      })
        .then((response) => {
          this.$store.dispatch("getSendouts");
          if (item.status !== null && item.status.name === "Paused") {
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "Sendout paused",
              timeout: 3000,
            });
          } else {
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "Sendout resumed",
              timeout: 3000,
            });
          }
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    async validateSendout(sendout) {
      this.idSendouts = [];
      this.idSendouts.push(sendout.id);

      await ApiService.post("campaign/validateSendouts", {
        idSendouts: this.idSendouts,
      })
        .then((response) => {
          this.$store.dispatch("getSendouts");
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Sendout validated",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    openConfirmation(sendout) {
      this.modalName = sendout.template.name;
      this.modalDb = sendout.database.name;
      this.modalDate = sendout.send_date;
      this.itemId = sendout.id;
      this.confirmation = true;
    },

    deleteSendout() {
      ApiService.delete("sendouts/" + this.itemId)
        .then((response) => {
          this.$store.dispatch("getSendouts");
          this.confirmation = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Domain has been deleted of this creativity",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    /*     async getSendouts() {
      this.loading = true;

      await ApiService.get("sendouts/list")
        .then((response) => {
          this.loading = false;
          this.sendouts = response.data.data;
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    }, */

    showInfo(info) {
      this.sendout = info;
      this.infoModal = true;
    },
  },
};
</script>

<style scoped>
.list .mdi-chevron-down::before {
  content: "\F419" !important;
  color: #000;
}

</style>