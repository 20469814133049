<template>
    <div>
      <v-row justify="center">
        <v-dialog
          :value="true"
          persistent
          @click:outside="$emit('close')"
          max-width="500px"
        >
          <v-card>
            <v-form 
              v-model="isFormValid"
            >
              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row class="px-8 py-4">
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <label class="caption font-weight-bold">Comment</label>
                        <v-textarea
                            class="body-2 font-weight-regular"
                            v-model="comment"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                            rows="3"
                        ></v-textarea>
                      </v-col> 
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-btn 
                          class="caption mt-3 white--text"
                          color="tvf_color" 
                          block
                          large 
                          :disabled="!isFormValid"
                          @click="refuseCampaign"
                        >
                        Refuse
                        </v-btn>  
                      </v-col>                  
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </template>
  
  <script>
  import ApiService from "@/services/api.service";
  
  export default {
    props: ['id', 'template', 'crea'],
    data() {
      return {
        comment: '',
        errors: [],
        isFormValid: false
      };
    },

    mounted(){

    },
  
    methods: { 

      refuseCampaign(){
        ApiService.patch("campaign/putRefusedCampaign/" + this.id, {
          comment_refuse: this.comment
        }).then(
        (response) => {
          this.isFormValid = true
          this.$emit('close');
          this.$store.dispatch('getHTML')          
          this.$store.dispatch('setSnackbar', {
            color: 'success',
            text: 'Campaign has been refused',
            timeout: 3000
          })
          .catch((error) => {
            this.isFormValid = false
            if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
            }
          });      
        })
      }
    }
  };
  </script>
  
  <style  scoped>
  .col-sm-4,
  .col-md-4,
  .col-12,
  .col-sm-8,
  .col-md-8 {
    padding: 0px;
  }
  
  .spacing{
    width: 45%;
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  
  </style>