<template>
    <div>
      <v-row justify="center">
          <v-dialog
              :value="true"
              persistent
              @click:outside="$emit('close')"
              max-width="800px"   
            >
              <v-card>
                <div class="py-10 px-8">
                  <div v-html="html" class="pa-16 subtitle-1"></div>
                </div>                
              </v-card>
            </v-dialog>
          </v-row>
      </div>
  </template>
  
  <script>
  
  export default {
      props: ['html'],
      name: "showPreview",
  
      data() {
          return {
              loading: false,    
          }
      },
  }
  </script>
  
  <style scoped>
  .text-subtitle {
    font-size: 1.25rem !important;
  }
  </style>
  
  