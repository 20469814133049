<template>
  <v-card>
    <div class="card">
      <div class="d-flex justify-end pr-6">
        <v-btn
          class="addButton ml-8 mt-10 mb-8 white--text"
          color="tvf_color"
          @click="openCreateModal"
        >
          New campaign
        </v-btn>
      </div>      
      <div class="nextMonth d-flex px-10 justify-center">
        <v-btn
          class="ml-4 mt-2 ma-1"
          fab
          icon
          outlined
          x-small
          @click="$refs.calendar.prev()"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar" class="px-10 pt-2">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn
          class="mt-2 mr-4 ma-1"
          fab
          icon
          outlined
          x-small
          @click="$refs.calendar.next()"
        >
          <v-icon>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </div>
      <v-app id="inspire">
        <v-layout wrap>
          <v-flex class="pa-4">
            <v-sheet height="500">
              <v-calendar
                ref="calendar"
                v-model="start"
                :type="type"
                :start="start"
                :end="end"
                :min-weeks="minWeeks"
                :max-days="maxDays"
                :now="now"
                :dark="dark"
                :weekdays="weekdays"
                :first-interval="intervals.first"
                :interval-minutes="intervals.minutes"
                :interval-count="intervals.count"
                :interval-height="intervals.height"
                :interval-style="intervalStyle"
                :show-interval-label="showIntervalLabel"
              >
                <template v-slot:day="day">
                  <v-sheet
                    v-if="day.day % 2"
                    :color="colorRefused"
                    class="caption px-1 ma-1 rounded"
                  >
                    <span class="caption font-weight-bold mr-16">Refused</span> {{ styleIntervalOptions.length }}
                  </v-sheet>
                  <v-sheet
                    v-if="day.day % 3"
                    :color="colorInProgress"
                    class="caption px-1 ma-1 rounded"
                  >
                    <span class="font-weight-bold mr-10">In Progress</span> {{ maxDaysOptions.length }}
                  </v-sheet> 
                  <v-sheet
                    v-if="day.day % 4"
                    :color="colorKitOk"
                    class="caption px-1 ma-1 rounded"
                  >
                    <span class="font-weight-bold mr-16">Kit OK</span> {{ maxDaysOptions.length }}
                  </v-sheet> 
                  <v-sheet
                    v-if="day.day % 3"
                    :color="colorPending"
                    class="caption px-1 ma-1 rounded"
                  >
                    <span class="caption font-weight-bold mr-14">Pending</span> {{ typeOptions.length }}
                  </v-sheet>
                  <v-sheet
                    v-if="day.day % 2"
                    :color="colorValidated"
                    class="caption px-1 ma-1 rounded"
                    @click="viewDetails"
                  >
                    <span class="caption font-weight-bold mr-12">Validated</span> <span>{{ weekdays.length }}</span>
                  </v-sheet>                                
                  <v-sheet
                    v-if="day.day % 2"
                    :color="colorScheduled"
                    class="caption px-1 ma-1 rounded"
                  >
                    <span class="font-weight-bold mr-12">Scheduled</span> {{ intervalsOptions.length }}
                  </v-sheet>                              
                  <v-sheet
                    v-if="day.day % 2"
                    :color="colorSending"
                    class="caption px-1 ma-1 rounded"
                  >
                    <span class="font-weight-bold mr-14">Sending</span> {{ maxDaysOptions.length }}
                  </v-sheet>
                  <v-sheet
                    v-if="day.day % 3"
                    :color="colorFinished"
                    class="caption white--text px-1 ma-1 rounded"
                  >
                    <span class="font-weight-bold mr-14">Finished</span> {{ maxDaysOptions.length }}
                  </v-sheet>
                </template>
              </v-calendar>
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-app>
      <dialog-details
        v-if="dialogDetails"
        @close="dialogDetails = false"
      />
      <event-detail
        v-if="showDetails"
        @close="showDetails = false"
      />
      <date-selector
        v-if="newCampaign"
        @close="newCampaign = false"
      />
    </div>
  </v-card>
</template>

<script>
import LoadingSpinner from "../components/core/LoadingSpinner";
import DateSelector from "../components/calendar/Datapicker";
import DialogDetails from "../components/calendar/Details";
import EventDetail from "../components/calendar/EventDetail";

export default {
  name: "Calendar",
  components: {
    LoadingSpinner,
    DateSelector,
    DialogDetails,
    EventDetail,
  },
  data: () => ({
    dark: false,
    startMenu: false,
    start: "2021-12-01",
    endMenu: false,
    end: "2022-12-31",
    nowMenu: false,
    minWeeks: 1,
    now: null,
    type: "month",
    typeOptions: [
      { text: "Day", value: "day" },
      { text: "4 Day", value: "4day" },
      { text: "Week", value: "week" },
      { text: "Month", value: "month" },
      { text: "Custom Daily", value: "custom-daily" },
      { text: "Custom Weekly", value: "custom-weekly" },
    ],
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    intervals: {
      first: 0,
      minutes: 60,
      count: 24,
      height: 40,
    },
    intervalsOptions: [
      {
        text: "Default",
        value: {
          first: 0,
          minutes: 60,
          count: 24,
          height: 40,
        },
      },
      {
        text: "Workday",
        value: { first: 16, minutes: 30, count: 20, height: 40 },
      },
    ],
    maxDays: 7,
    maxDaysOptions: [
      { text: "7 days", value: 7 },
      { text: "5 days", value: 5 },
      { text: "4 days", value: 4 },
      { text: "3 days", value: 3 },
    ],
    styleInterval: "default",
    styleIntervalOptions: [
      { text: "Default", value: "default" },
      { text: "Workday", value: "workday" },
      { text: "Past", value: "past" },
    ],
    colorValidated: "validated",
    colorRefused: "refused",
    colorPending: "pending",
    colorScheduled: "scheduled",
    colorKitOk: 'kit_ok',
    colorInProgress: 'in_progress',
    colorSending: 'sending',
    colorFinished: 'finished',
    showDetails: false,
    dialogDetails: false,
    newCampaign: false
  }),

  mounted () {
    
  },

  computed: {
    intervalStyle () {
      const stylings = {
        default (interval) {
          return undefined
        },
        workday (interval) {
          const inactive = interval.weekday === 0 ||
            interval.weekday === 6 ||
            interval.hour < 9 ||
            interval.hour >= 17
          const startOfHour = interval.minute === 0
          const dark = this.dark
          const mid = dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

          return {
            backgroundColor: inactive ? (dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
            borderTop: startOfHour ? undefined : '1px dashed ' + mid
          }
        },
        past (interval) {
          return {
            backgroundColor: interval.past ? (this.dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined
          }
        }
      }
      
      return stylings[ this.styleInterval ].bind(this)
    },
    hasIntervals () {
      return this.type in {
        'week': 1, 'day': 1, '4day': 1, 'custom-daily': 1
      }
    },
    hasEnd () {
      return this.type in {
        'custom-weekly': 1, 'custom-daily': 1
      }
    }
  },
  methods: {
    showIntervalLabel (interval) {
      return interval.minute === 0
    },

    viewDetails() {
      this.dialogDetails = true;
    },

    openCreateModal() {
      this.newCampaign = true;
    },

    openDetail() {
      this.showDetails = true;
    },
  }
};

</script>

<style scoped>
.v-main__wrap,
.card {
  min-height: 1420px;
}

.v-event > div,
.v-event-timed > div {
  color: black !important;
}

div.v-toolbar__title {
  color: black !important;
}

div.v-event-timed {
  width: 20% !important;
  height: 75px !important;
}

.row {
  margin: 0;
}

.caption {
  cursor: pointer;
}

button .mt-2 .mr-4 .ma-1 .v-btn--outlined .v-btn:before{
  background-color: white !important;
}

div.v-sheet.theme--light .v-calendar-weekly {
  display: block;
}

</style>