<template>
    <div>
      <v-row justify="center">
        <v-dialog
          :value="true"
          persistent
          @click:outside="$emit('close')"
          max-width="800px"
        >
          <v-card>
            <v-form>
                <div>
                    <v-row class="px-14 pt-14">
                        <v-col cols="6"> 
                            <label class="caption font-weight-bold">Domain</label>
                            <v-autocomplete
                                v-model="newRecord.database"
                                class="caption"
                                :items='databaseList'
                                background-color="#F8F9FB"
                                return-object
                                item-value="id"
                                item-text="name"
                                solo
                                dense
                                flat
                                @change="loadData(newRecord.database)"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-expansion-panels
                            class="mb-10"
                            multiple                            
                            v-if="response"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <p class="body-2 font-weight-bold mt-4">Status general:</p>
                                    <p v-if="status !== 'OK'" class="body-2 red--text mt-4"> 
                                        <v-icon
                                            small
                                            color="red"
                                            >mdi-alert
                                        </v-icon> {{ status }}
                                    </p>
                                    <p v-else class="body-2 green--text mt-4"> 
                                        <v-icon
                                            small
                                            color="green"
                                            >mdi-check
                                        </v-icon> {{ status }}
                                    </p>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="scrollable-content mb-10">
                                    <div
                                        class="item"
                                        v-if="status === 'html_no_equals'"
                                    >
                                        <p class="caption">Percent: <span class="caption">{{ status.percent.toFixed(2) }} %</span></p>
                                        <p class="caption">Status: <span class="red--text ">{{ status.status }}</span></p>
                                    </div>
                                    <div class="pt-2" v-if="response.pixels">
                                        <p class="body-1 font-weight-bold">PIXELS</p>
                                        <div v-for="pixel, index in response.pixels" :key="index" class="pa-4 mb-6" style="border: 1px solid #fff; border-radius: 5px;background-color: #F8F9FB;">                                            
                                            <p class="caption font-weight-bold">URL: <span class="caption">{{ pixel.url }}</span></p>
                                            <p class="caption font-weight-bold">STATUS: <span class="red--text">{{ pixel.status }}</span></p>
                                            <p v-if="pixel.status === 'Blacklist'" class="caption font-weight-bold">BLACKLIST: </p>
                                            <div v-for="bl, index in pixel.blacklist" :key="index">
                                                <p v-if="pixel.status === 'Blacklist'" class="caption red--text">• {{ bl }}</p>
                                            </div>
                                        </div>                                    
                                    </div><br/>
                                    <div class="item" v-if="response.hrefs">
                                        <p class="body-1 font-weight-bold">HREFS</p>
                                        <div v-for="href, index in response.hrefs" :key="index" class="pa-4 mb-6" style="border: 1px solid #fff; border-radius: 5px;background-color: #F8F9FB;">
                                            <p class="caption font-weight-bold">URL: <span class="caption">{{ href.url }}</span></p>
                                            <p class="caption font-weight-bold">STATUS: <span class="red--text">{{ href.status }}</span></p>
                                            <p v-if="href.status === 'blacklist'" class="caption font-weight-bold">BLACKLIST:</p>
                                            <div v-for="bl, index in href.blacklist" :key="index">
                                                <p v-if="href.status === 'blacklist'" class="caption red--text">• {{ bl }}</p>
                                            </div>
                                        </div> 
                                    </div><br/>
                                    <div class="item" v-if="response.srcs" >
                                        <p class="body-1 font-weight-bold">SRCS:</p>
                                        <div v-for="src, index in response.srcs" :key="index" class="pa-4 mb-6" style="border: 1px solid #ccc;border-radius: 5px;">                                            
                                            <p class="caption font-weight-bold">{{ src }}</p>
                                        </div> 
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>
                    <v-row class="px-14 pb-14">
                        <v-col cols="6">                           
                            <label class="caption font-weight-bold">Sender/From name</label>
                            <v-text-field
                                v-model="newRecord.sender"
                                class="input caption"
                                background-color="#F8F9FB"
                                dense
                                solo
                                flat
                            ></v-text-field>
                            <label class="caption font-weight-bold">Reply name</label>
                            <v-text-field
                                v-model="newRecord.reply_name"
                                class="input caption"
                                background-color="#F8F9FB"
                                dense
                                solo
                                flat
                            ></v-text-field>
                            <label class="caption font-weight-bold">Subject</label>
                            <v-text-field
                                v-model="newRecord.subject"
                                class="input caption"
                                background-color="#F8F9FB"
                                dense
                                solo
                                flat
                            ></v-text-field>
                            <label class="caption font-weight-bold">Variables</label>
                            <v-text-field
                                v-model="newRecord.variables"
                                class="caption"
                                background-color="#F8F9FB"
                                dense
                                solo
                                flat
                            ></v-text-field>
                            <v-btn 
                                v-if="!status || status === 'html_not_equal'"
                                class="caption mt-14 white--text"
                                color="tvf_color" 
                                block
                                large 
                                :loading="loading"
                                :disabled="loading"
                                @click="processHtml"
                            >
                                Process
                            </v-btn> 
                            <v-btn 
                                v-if="status === 'OK' && status !== '' "
                                class="caption mt-14 white--text"
                                color="tvf_color" 
                                block
                                large 
                                :loading="loading"
                                :disabled="loading"
                                @click="addNewDomain"
                            >
                                Add Domain
                            </v-btn>
                            <v-btn 
                                v-if="status !== 'OK' && status !== 'html_not_equal' && status !== ''"
                                class="caption mt-14 white--text"
                                color="red" 
                                block
                                large 
                                :loading="loading"
                                :disabled="loading"
                                @click="addNewDomain"
                            >
                                <v-icon
                                    small
                                    >mdi-alert
                                </v-icon>
                                Add Domain
                            </v-btn> 
                        </v-col>
                        <v-col cols="6">
                            <label class="caption font-weight-bold">From email</label>
                            <v-text-field
                                v-model="newRecord.from_email"
                                class="input caption"
                                background-color="#F8F9FB"
                                dense
                                solo
                                flat
                            ></v-text-field>
                            <label class="caption font-weight-bold">Reply email</label>
                            <v-text-field
                                v-model="newRecord.reply_email"
                                class="input caption"
                                background-color="#F8F9FB"
                                dense
                                solo
                                flat
                            ></v-text-field>
                            <label class="caption font-weight-bold">Pre-header</label>
                            <v-text-field
                                v-model="newRecord.preheader"
                                class="caption"
                                background-color="#F8F9FB"
                                dense
                                solo
                                flat
                            ></v-text-field>
                            <div class="d-flex">
                                <label class="caption font-weight-bold mr-2">Html</label>
                                <v-checkbox v-model="withHtml"></v-checkbox>
                            </div>
                            <v-textarea
                                v-if="withHtml"
                                v-model="newRecord.html"
                                class="caption"
                                background-color="#F8F9FB"
                                dense
                                solo
                                flat
                                rows="5"
                            ></v-textarea>                            
                        </v-col>
                    </v-row>
                </div>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </template>
  
  <script>
  import ApiService from "@/services/api.service";
  
  export default {
    props: ['template', 'item'],
    data() {
      return {
        newRecord: {
            sender: '',
            from_email: '',
            reply_name: '',
            reply_email: '',
            variables: '',
            subject: '',
            preheader: '',
            html: '',
            database: ''
        },    
        status: '',
        response: '',
        databaseList: [],    
        database: [],
        isFormValid: false,
        loading: false,
        withHtml: false,
        result: false,
        html_final: '',
        database: '',
      };
    },

    computed: {

        likesAllDb() {
            return this.databasesLocal.length === this.databaseList.length;
        },
        likesSomeDb() {
            return this.databasesLocal.length > 0 && !this.likesAllDb;
        },

        icon() {
            if (this.likesAllDb) return "mdi-close-box";
            if (this.likesSomeDb) return "mdi-minus-box";
            return "mdi-checkbox-blank-outline";
        },
    },

    mounted() {
        this.getDatabases()
    },    
  
    methods: { 

        toggle() {
            this.$nextTick(() => {
                if (this.likesAllDb) {
                    this.databasesLocal = [];
                } else {
                    this.databasesLocal = this.databaseList.slice();
                }
            });
        },

        loadData(item){
            this.status = ''
            this.response = ''
            this.newRecord.sender = item.from_name
            this.newRecord.from_email = item.from_email
            this.newRecord.reply_email = item.reply_email
            this.newRecord.reply_name = item.reply_name
        },

        processHtml(){
            this.loading = true
            ApiService.post("campaign/addDomainCampaign", {
              create: false,
              idTemplate: this.template.id,     
              database_id: this.newRecord.database.id,  
              from_name: this.newRecord.sender,
              from_email: this.newRecord.from_email,
              reply_name: this.newRecord.reply_name,
              reply_email: this.newRecord.reply_email,
              html_original: this.newRecord.html,
              subject: this.newRecord.subject,
              variables: this.newRecord.variables,
              preheader: this.newRecord.preheader
            })
            .then((response) => {
                this.loading = false
                this.status = response.data.result.status
                let data = response.data.result
                let keys = Object.keys(data)
                let firstKey = keys[0];

                this.response = data[firstKey]
                this.result = true
            })
            .catch((error) => {
                this.loading = false
                if (typeof error.response.data === "object") {
                    Object.values(error.response.data)
                    .flat()
                    .forEach((error) => {
                        this.$store.dispatch("setSnackbar", {
                        color: "tvf_secondary",
                        text: error.message,
                        timeout: 3000,
                        });
                    });
                }
            });
        },

        addNewDomain(){
            // Guarda los valores actuales de this.newRecord en un objeto temporal
            let currentRecord = this.newRecord;
            // Luego utiliza currentRecord para crear el nuevo this.newRecord
            this.newRecord = {
                create: true,
                idTemplate: this.template.id,     
                database_id: currentRecord.database.id,  
                from_name: currentRecord.sender,
                from_email: currentRecord.from_email,
                reply_name: currentRecord.reply_name,
                reply_email: currentRecord.reply_email,
                html_original: currentRecord.html,
                subject: currentRecord.subject,
                variables: currentRecord.variables,
                preheader: currentRecord.preheader
            }; 

            this.loading = true
            ApiService.post("campaign/addDomainCampaign", this.newRecord)
            .then((response) => {
                this.loading = false

                // Agrega los campos amount y timeSent a response.data.crea
                response.data.crea.amount = null;
                response.data.crea.status = null;
                response.data.crea.schedule_date = null;
                response.data.crea.time_sent = 0;
                response.data.crea.sendouts = [];

                this.newRecord = {
                    name: currentRecord.database.name,
                    creas: [response.data.crea]
                };
                this.$emit('add-item', this.newRecord );    
                this.$emit('close')               
            })
            .catch((error) => {
                this.loading = true
                if (typeof error.response.data === "object") {
                    Object.values(error.response.data)
                    .flat()
                    .forEach((error) => {
                        this.$store.dispatch("setSnackbar", {
                        color: "tvf_secondary",
                        text: error.message,
                        timeout: 3000,
                        });
                    });
                }
            });
        },

        getDatabases(){

            ApiService.get("databases/getDBForUserCountry/" + this.$store.state.auth.user.id + '/' + this.item.id)
            .then((response) => {

            const databases = response.data.databases;
            this.databaseList = databases.sort(function (a, b) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
                }
                // a must be equal to b
                return 0;
            });
            })
            .catch((error) => {
                if (typeof error.response.data === "object") {
                    Object.values(error.response.data)
                    .flat()
                    .forEach((error) => {
                        this.$store.dispatch("setSnackbar", {
                        color: "tvf_secondary",
                        text: error.message,
                        timeout: 3000,
                        });
                    });
                }
            });
        }
    },
  };
  </script>
  
  <style  scoped>
  .col-sm-4,
  .col-md-4,
  .col-sm-8,
  .col-md-8 {
    padding: 0px;
  }

  .v-label {
    font-size: 12px !important;
  }
   
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  .scrollable-content {
    max-height: 350px; /* Establece la altura máxima deseada */
    overflow-y: auto; /* Añade un scroll vertical cuando el contenido exceda la altura */
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
  
  </style>