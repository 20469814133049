<template>
    <v-dialog
      :value="true"
      persistent
      @click:outside="closeModal"
      max-width="1370px"
    >
      <v-card class="pa-8 d-flex">
        <v-col cols="3">
          <div class="steps my-6">
            <v-row class="d-flex">
              <img
                src="@/assets/icons/send_icon.png"
                class="send"
              />
              <div class="mt-12">
                <span class="addnew mt-1 mx-4 other--text">ADD NEW</span><br />
                <span class="campaign mt-1 mx-4 other--text">CAMPAIGN</span>
              </div>
            </v-row>
            <v-row>
              <Stepper
                class="caption mx-15 mt-8"
                :value="step"
                :items="items"
                orientation="vertical"
              >
              </Stepper>
            </v-row>
            <v-row>
              <span class="stepCount caption other--text font-weight-bold">STEP {{ step + 1 }} OF 5</span>
            </v-row>
          </div>
        </v-col>
        <v-col cols="9">
          <v-form v-if="step === 3" ref="form">
            <v-row class="pt-6">              
              <v-col               
                cols="4"
                v-if="localSegmentsList.length"
              >    
                <LoadingSpinner v-if="isLoading" />    
                <div class="segments_section">    
                  <div 
                    v-for="(input, index) in segments[dbSelected].segment"
                    :key="`fieldInput-${index}`"
                  >
                    <v-row>                    
                      <v-col
                        cols="12"
                        sm="8"
                        md="8"
                      >
                        <label class="caption font-weight-bold">Segment</label>
                        <v-autocomplete
                          class="caption"
                          v-model="input.id"
                          background-color="#F8F9FB"
                          :items="localSegmentsList"
                          item-value="Id"
                          item-text="Name"
                          return-object
                          dense
                          solo
                          flat
                          :disabled="loading"
                          @change="getAmount(input.id, index)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <label class="caption font-weight-bold">Amount</label>
                        <v-text-field
                          class="caption"
                          v-model="input.amount"
                          :loading="loading"
                          readonly
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </div>  
                <v-btn
                  class="caption px-16 mt-3 white--text"
                  color="tvf_color"
                  elevation="0"
                  block                  
                  :disabled="loading"
                  @click="addSegment()"
                >
                  Add segment
                </v-btn>
                <v-btn
                  v-if="segments[dbSelected].segment.length > 1"
                  class="caption px-16 mt-3 white--text"
                  color="tvf_color"
                  elevation="0"
                  block
                  @click="removeSegment()"
                >
                  Delete segment
                </v-btn>
              </v-col>
              <v-col
                cols="4"
                v-else
              >
                <div>
                  <v-row>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="4">
                <v-row>
                  <v-col
                    class="schedule"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <label class="caption font-weight-bold">Send date</label><br />
                    <date-picker
                      prefix-class="xmx"
                      class="caption datetime"
                      :rules="[datesRequired]"
                      readonly
                      v-model="sendouts[dbSelected].send_date"
                      value-type="timestamp"
                      format="YYYY-MM-DD HH:mm:ss"
                      :disabled-date="notBeforeToday"                   
                      type="datetime"
                    >
                    </date-picker>
                  </v-col>
                  <v-col
                    class="schedule1"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="caption font-weight-bold">Throttle</label>
                    <v-text-field
                      class="caption"
                      type="number"
                      :rules="throttleRules"                    
                      :value="sendout.throttle"
                      v-model="sendouts[dbSelected].throttle"
                      background-color="#F8F9FB"
                      dense
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="caption font-weight-bold">Limit</label>
                    <v-text-field
                      class="caption"
                      type="number"
                      v-model="sendouts[dbSelected].limit"
                      :value="sendout.limit"
                      background-color="#F8F9FB"
                      dense
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="headerStyle">
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <label class="caption font-weight-bold">Header</label>
                    <v-select
                      class="caption"
                      :rules="headerRules"
                      v-model="sendouts[dbSelected].header"
                      background-color="#F8F9FB"
                      :items="localHeaders"
                      item-value="id"
                      item-text="name"
                      dense
                      solo
                      flat
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <label class="caption font-weight-bold">Footer</label>
                    <v-select
                      class="caption"
                      :rules="footerRules"
                      v-model="sendouts[dbSelected].footer"
                      background-color="#F8F9FB"
                      :items="localFooters"
                      item-value="id"
                      item-text="name"
                      dense
                      solo
                      flat
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <div class="databases4">
                  <div
                    class="ml-10"
                    v-for="crea, index in creasLocal"
                    :key="index"
                  >
                    <v-btn
                      v-if="crea !== null"
                      :class="crea.database_id === dbSelected ? 'db_btn caption mt-3 white--text':'db_btn caption mt-3 black--text'"
                      :color="crea.database_id === dbSelected ? 'black': '#F8F9FB'"
                      elevation="0"
                      block
                      @click="assignIdSendouts(crea.database_id)"
                    >
                      {{ crea.database.name }}
                      <v-icon
                        v-if="formsValid[crea.database_id]"
                        right
                        dark
                        color="green"
                      >
                        mdi-check-circle
                      </v-icon>
                      <v-icon
                        v-else
                        right
                        dark
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="mt-2">
                  <v-row>
                    <v-col cols="10" class="ml-10">
                      <v-btn
                        class="steps_btn caption white--text"
                        color="black"
                        :loading="loading"
                        :disabled="loading"
                        x-large
                        block
                        @click="saveAndSchedule"
                      >
                        Save & Schedule
                        <v-icon
                          right
                          dark
                        >
                          mdi-arrow-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div v-if="step === 4">
            <v-row class="mb-4">
              <v-col cols="4">
                <div class="section1 mt-6">
                  <v-row class="pl-6">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label class="caption font-weight-bold">Name</label><br />
                      <span class="caption">{{ templateLocal.name }}</span><br />
                      
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <label class="caption font-weight-bold">Country</label><br />
                      <span class="caption">{{ templateLocal.country.name }}</span><br />
                      <label class="caption font-weight-bold">Agency</label><br />
                      <span class="caption">{{ templateLocal.agency.name }}</span>
                    </v-col>
                  </v-row>
                </div>
                <div class="section2_2">
                  <v-row class="mt-2 pl-6">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <label class="caption font-weight-bold">Send Date</label><br />
                      <span class="caption">{{ sendouts_response[dbSelected].send_date }}</span><br /><br />
                      <label class="caption font-weight-bold">Header</label><br />
                      <span class="caption">{{ sendouts_response[dbSelected].headerName }}</span><br /><br />
                      <label class="caption font-weight-bold">Segment</label><br />
                      <div
                        v-for="input,index in this.sendouts[this.dbSelected].segmentation"
                        :key="index"
                      >
                        <span
                          v-if="input != null"
                          class="caption"
                        >{{ input }}</span>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <label class="caption font-weight-bold">Throttle</label><br />
                      <span class="caption">{{ sendouts_response[dbSelected].throttle }}</span><br /><br />
                      <label class="caption font-weight-bold">Footer</label><br />
                      <span class="caption">{{ sendouts_response[dbSelected].footerName }}</span><br /><br />
                      <label class="caption font-weight-bold">Amount</label><br />
                      <span class="caption">{{ sendouts_response[dbSelected].amount_sent }}</span><br />
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col
                    cols="6"
                    class="d-flex pt-8"
                  >
                    <v-btn
                      class="functions1 caption white--text mr-4"
                      color="black"
                      x-large
                      block
                      @click='previewFinalHtml(dbSelected)'
                    >
                      <v-icon
                        left
                        dark
                      >
                        mdi-eye
                      </v-icon>
                      PREVIEW
                    </v-btn>
                    <v-btn
                      class="functions2 caption white--text pt-1 ml-2"
                      color="black"
                      x-large
                      block
                      @click="previewText"
                    >
                      <v-icon
                        left
                        dark
                      >
                        mdi-file-document-outline
                      </v-icon>
                      text <br />version
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <div class="section1 mt-6">
                  <v-row class="pl-6">
                    <v-col
                      cols="12"
                      sm="5"
                      md="5"
                    >
                      <label class="caption font-weight-bold">From Name</label><br />
                      <span class="caption">{{ creasOrder[dbSelected].from_name }}</span><br />
                      <label class="caption font-weight-bold">Reply Name</label><br />
                      <span class="caption">{{ creasOrder[dbSelected].reply_name }}</span><br />
                      <label class="caption font-weight-bold">Sender Domain</label><br />
                      <span class="caption">{{ sendouts_response[dbSelected].sender_domain }}</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="7"
                      md="7"
                    >
                      <label class="caption font-weight-bold">From Email</label><br />
                      <span class="caption">{{ creasOrder[dbSelected].from_email }}</span><br />
                      <label class="caption font-weight-bold">Reply Email</label><br />
                      <span class="caption">{{ creasOrder[dbSelected].reply_email }}</span><br />
                      <label class="caption font-weight-bold">Tag</label><br />
                      <div
                        v-for="input,index in tags"
                        :key="index"
                      >
                        <span class="caption">{{ input.name }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="section2">
                  <v-row class="mt-6 pl-6">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label class="caption font-weight-bold">Variable</label><br />
                      <span class="caption">{{ creasOrder[dbSelected].variables }}</span><br />
                      <label class="caption font-weight-bold">Subject</label><br />
                      <span class="caption">{{ creasOrder[dbSelected].subject }}</span><br />
                      <label class="caption font-weight-bold">Pre-header</label><br />
                      <span class="caption">{{ creasOrder[dbSelected].preheader }}</span>
                    </v-col>
                  </v-row>
                </div>
                <div class="section3 mt-3">
                  <v-row class="mt-1 pl-6">
                    <v-col
                      cols="12"
                      sm="5"
                      md="5"
                    >
                      <label class="caption font-weight-bold">Payout</label><br />
                      <div
                        v-for="input,index in pays"
                        :key="index"
                      >
                        <span class="caption">{{ input.paymodel_template.payout }}</span>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="5"
                      md="5"
                    >
                      <label class="caption font-weight-bold">Paymodel</label><br />
                      <div
                        v-for="input,index in pays"
                        :key="index"
                      >
                        <span class="caption">{{ input.name }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col
                    cols="6"
                    class="d-flex pt-8"
                  >
                    <v-btn
                      class="functions2 caption white--text mr-1"
                      color="#8A0F48"
                      x-large
                      block
                      @click="validateBAT(dbSelected)"
                    >
                      VALIDATE BAT
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex pt-8"
                  >
                    <v-btn
                      class="functions2 caption white--text mr-1"
                      color="black"
                      x-large
                      block
                      @click="backPage"
                    >
                      <v-icon
                        left
                        dark
                      >
                        mdi-arrow-left
                      </v-icon>
                      Back
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <div class="databases5 ml-4 mb-14">
                  <div
                    v-for="crea, index in creas"
                    :key="index"
                  >
                    <v-btn
                      v-if="crea.status === null"
                      :class="crea.database_id === dbSelected ? 'db_btn caption mt-3 white--text':'db_btn caption px-16 mt-3 black--text'"
                      :color="crea.database_id === dbSelected ? 'black': '#F8F9FB'"
                      x-large
                      elevation="0"
                      @click="assignId(crea.database_id)"
                    >
                      {{ crea.database.name }}
                      <v-icon
                        right
                        dark
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <div>
                  <v-btn
                    class="draft_btn caption ml-4 px-16 mt-3 white--text"
                    color="#8A0F48"
                    large
                    @click="validateSendout"
                  >
                    VALIDATE & SCHEDULE
                  </v-btn>
                  <v-btn
                    class="draft_btn caption ml-4 px-16 mt-3 white--text"
                    color="black"
                    large
                    @click="closeModal(dbSelected)"
                    :disabled="batValidated"
                  >
                    SCHEDULE ALL
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-card>
      <show-preview
        v-if="preview"
        @close="preview = false"
        :html="html"
      ></show-preview>
      <send-bat
        v-if="bat"
        :validation="validation"
        :html="html"
        :text_version="text_version"
        :dbCountry="dbCountry_id"
        :sendout="sendouts_response"
        :crea="crea"
        :campaign="campaign"
        :db="db"
        :campaignName="campaignName"
        :dbName="dbName"
        @close="bat = false"
      ></send-bat>   
    </v-dialog>
  </template>
  
  <script>
  import Vue from "vue";
  import { Stepper } from "@progress/kendo-vue-layout";
  import ApiService from "../../services/api.service";
  import ShowPreview from "@/components/campaign/htmlPreview";
  import SendBat from "@/components/campaign/SendBat";
  import RefuseCrea from "@/components/campaign/RefuseCrea";
  import LoadingSpinner from "@/components/core/LoadingSpinner";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";

  export default {
    props: {
      batValidated: {
        type: Boolean,
        default: true
      },
      template: {
        type: Object
      },
      tags: {
        type: Array
      },
      pays: {
        type: Array
      },
      creas: {
        type: Array
      },
      creasOrder: {
        type: Array
      },
      templateID: {
        type: Number
      },
      databases: {
        type: Array
      },
      dbCountry: {
        type: Number
      },
      createModal: {
        type: Boolean,
      },
      stepCampaign: {
        type: Number
      },
      sendoutsLocal: {
        type: Array
      },
      selectedDb: {
        type: Number
      },
      segmentsInputs: {
        type: Array
      },
      segmentsList: {
        type: Array
      },
      headers: {
        type: Array
      },
      footers: {
        type: Array
      }
    },
    components: {
      Stepper,
      ShowPreview,
      SendBat,
      RefuseCrea,
      DatePicker,
      LoadingSpinner
    },
    data() {
      //DATOS
      return {
        dbSelected: this.selectedDb,
        loading: false,
        isLoading: false,        
        kit: false,
        all: false,
        split: false,
        preview: false,
        bat: false,
        refuse: false,
        validation: false,
        refuseCrea: false,
        loadingDraft: false,
        batSended: this.batValidated,        
        num_split: "",
        comment: "",
        amount: "",
        step: this.stepCampaign,
        creasLocal: this.creas,            
        templateLocal: this.template,
        databasesLocal: this.databases,
        idTemplate: this.templateID,
        localSegmentsList: this.segmentsList,
        localHeaders: this.headers,
        localFooters: this.footers,
        crea: {
          idCrea: null,
          database_id: "",
          template_id: "",
          html_original: "",
          subject: "",
          variables: "",
          preheader: "",
          status: false,
        },
        paymodels: [],
        tagsList: [],
        tagsIds: [],
        status: "",
        databaseList: [],
        databaseFinal: [],
        custom_field: "",
        geo_filters: [],
        campaign: [],
        creasFinal: [],
        filelist: [],
        agencies: [],
        header: "",
        footer: "",
        creas_response: [],
        time: "",
        dbCountry_id:'',
        sendouts: this.sendoutsLocal,
        sendouts_response: [],
        idSendouts: [],
        segmentsFinal: [],
        segments: this.segmentsInputs,
        segment: {
          database_id: "",
          id: "",
          amount: "",
        },
        sendout: {
          identifier: null,
          user_id: this.$store.state.auth.user.id,
          database_id: "",
          crea_id: "",
          segmentation: {},
          send_date: '',
          limit: null,
          throttle: 24,
          header: "",
          footer: "",
          comment: "",
          type: "sendout",
          status: "Pending validation",
        },
        formsValid: {},
        items: [
          {
            icon: "k-i-pencil",
            label: "Basic \n Information",
          },
          {
            icon: "k-i-code",
            label: "Content",
          },
          {
            icon: "k-i-eye",
            label: "Preview",
          },
          {
            icon: "k-i-calendar",
            label: "Schedule",
          },
          {
            icon: "k-i-check",
            label: "Confirm Schedule",
          },
        ],
        datesRequired: [(v) => !!v || 'Send date is required'],
        nameRules: [(v) => !!v || "Campaign name is required"],
        agencyRules: [(v) => !!v || "Agency is required"],
        countryRules: [(v) => !!v || "Country is required"],
        throttleRules: [(v) => !!v || "Throttle name is required"],
        headerRules: [(v) => !!v || "Header is required"],
        footerRules: [(v) => !!v || "Footer is required"],
        fromNameRules: [(v) => !!v || "From name are required"],
        fromEmailRules: [(v) => !!v || "From Email are required"],
      };
    },
  
    mounted() {
      this.$store.commit("getCountries");
      this.$store.commit("getPrefills");
    },
  
    watch: {
      creas: {
        handler(newValue) {
          this.creasLocal = newValue;
        },
        immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
      },
      tags: {
        handler(newValue) {
          this.tagsLocal = newValue;
        },
        immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
      },
      template: {
        handler(newValue) {
          this.templateLocal = newValue;
        },
        immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
      },
      templateID: {
        handler(newValue) {
          this.idTemplate = newValue;
        },
        immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
      },
      databases: {
        handler(newValue) {
          this.databasesLocal = newValue;
        },
        immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
      },
      stepCampaign: {
        handler(newValue) {
          this.step = newValue;
        },
        immediate: true,
      },
      sendoutsLocal: {
        handler(newValue) {
          this.sendouts = newValue;
        },
        immediate: true,
      },
      selectedDb: {
        handler(newValue){
          this.dbSelected = newValue;
        },
        immediate: true,
      },
      segmentsInputs: {
        handler(newValue){
          this.segments = newValue;
        },
        immediate: true,
      },
      segmentsList: {
        handler(newValue){
          this.localSegmentsList = newValue;
        }
      },
      segments: {
        handler() {
          this.checkFormValidity();
        },
        deep: true,
      },
      sendouts: {
        handler() {
          this.checkFormValidity();
        },
        deep: true,
      },
      headers: {
        handler(newValue){
          this.localHeaders = newValue;
        }
      },
      footers: {
        handler(newValue){
          this.localFooters = newValue;
        }
      }, 
    },

    created() {
      for(let crea of this.creasLocal){
        if(crea !== null) {
          this.formsValid[crea.database_id] = false;
        }
      }
    },
  
    computed: {

      /* allFormsValid() {
        console.log('Checking allFormsValid');
        console.log('formsValid:', this.formsValid);
        const allValid = Object.values(this.formsValid).every(item => item === true);
        console.log('allValid:', allValid);
        return allValid;
      }, */

    },
  
    methods: {

      checkFormValidity() {
        for (let dbID of Object.keys(this.segments)) {
          const isValid = this.segments[dbID].segment.every(segment => segment.id && segment.amount) 
            && this.sendouts[dbID].send_date
            && this.sendouts[dbID].header
            && this.sendouts[dbID].footer;
          Vue.set(this.formsValid, dbID, isValid);
        }
      },
      
      ////////////dESHABILITAR FECHAS ANTERIORES A HOY EN EL DATEPICKER
  
      notBeforeToday(date) {
        return date < new Date(new Date().setHours(0, 0, 0, 0));
      },
  
      stringToTimestamp(dateString) {
        return new Date(dateString).getTime();
      },
  
      /////////////////////////////////////////////////////////////////////////////////ASIGNAR DATABASE ID
  
      assignId(id) {
        this.dbSelected = id;
      },
  
      //TRAER LOS SEGMENTOS Y HEADER/FOOTER SEGÚN DB
  
      assignIdSendouts(id) {
        this.dbSelected = id;
        if (!(id in this.formsValid)) {
          this.formsValid[id] = false;
        }
        this.localSegmentsList = [];        
        
        if (!this.segments.hasOwnProperty(this.dbSelected)) {
          const newSegment = { ...this.segment, database_id: this.dbSelected };
          Vue.set(this.segments, this.dbSelected, { segment: [newSegment] });
        }
        
        this.isLoading = true
        ApiService.get("campaign/getListSegment/" + this.dbSelected)
          .then((response) => {
            this.isLoading = false
            this.localSegmentsList = response.data.segments;
          })
          .catch((error) => {
            this.isLoading = false
            if (typeof error.response.data === "object") {
              Object.values(error.response.data)
                .flat()
                .forEach((error) => {
                  this.$store.dispatch("setSnackbar", {
                    color: "tvf_secondary",
                    text: error.message,
                    timeout: 3000,
                  });
                });
            }
          });

        this.localHeaders = [];
        this.localFooters = [];          
  
        ApiService.get("hf/listByDb/" + this.dbSelected)
          .then((response) => {
            let list = response.data.data;
            list.forEach((hf) => {
              if (hf.type === "header") {
                this.localHeaders.push(hf);
              } else {
                this.localFooters.push(hf);
              }
            });
          })
          .catch((error) => {
            if (typeof error.response.data === "object") {
              Object.values(error.response.data)
                .flat()
                .forEach((error) => {
                  this.$store.dispatch("setSnackbar", {
                    color: "tvf_secondary",
                    text: error.message,
                    timeout: 3000,
                  });
                });
            }
          });
      },
  
      /////////////////////////////////////////////////////////////////////////////////AÑADIR ID DATABASES AL ARRAY CREAS
  
      previewFinalHtml(id) {
        this.html = this.sendouts_response[id].final_html;
        this.preview = true;
      },
  
      previewText() {
        this.html = this.template.text_version;
        this.preview = true;
      },
  
      onChange() {
        this.filelist = [...this.$refs.file.files];
      },
  
      remove(i) {
        this.filelist.splice(i, 1);
      },
  
      validateBAT(id) {
        this.db = id;
        this.bat = true;
        this.validation = true;
        this.batValidated = !this.batSended;
        this.sendouts_response.forEach((sendout) => {
          if (sendout.database_id === id) {
            this.html = sendout.final_html;
            this.dbCountry_id = sendout.template.country_id
            this.text_version = sendout.template.text_version
            this.campaignName = sendout.template.name
            this.dbName = sendout.database.name
            this.crea = sendout.crea.subject
          }
        });              
      },
      getAmount(item, index) {
        this.loading = true;

        ApiService.get(
          "campaign/getSegmentSize/" + this.dbSelected + "/" + item.Id
        )
        .then((response) => {
          this.loading = false;
          this.segments[this.dbSelected].segment[index].amount = response.data.subscribers;
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.loading = false;
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
            }
        }); 
      },

      closeModal() {
        this.segments.forEach((segs) => {
          segs.segment.forEach((seg) => {
            seg.database_id = '',
            seg.id = '',
            seg.amount = ''
          })        
        })
        this.$emit("close");
        this.$store.dispatch('getHTML')
      },

      /////////////////////////////////////////////////////////////////////////////////GUARDAR BORRADOR CON SENDOUT
  
      async saveAndSchedule() {

        this.sendoutsFinal = [];      
        let isValid = true; // Nueva variable para verificar si todos los send_date son válidos

        this.sendouts.forEach((send, indexSendout) => {
          const selectedSegments = {}
          this.segments.forEach((segSelected, indexSegment) => {
            if(indexSendout === indexSegment) {    
              segSelected.segment.forEach(segment => {
                Vue.set(selectedSegments, segment.id.Id, segment.id.Name)
              }) 
            }  
          }) 
          this.sendouts[indexSendout].segmentation = selectedSegments       
        });

        this.sendouts.forEach((sendout, index) => {
          const timeStampMilisegundos = this.stringToTimestamp(sendout.send_date);

          if (sendout != null) {
            if (!sendout.send_date) {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: "Send date is required!",
                timeout: 3000,
              });
              isValid = false; // Si el send_date está vacío, establecer isValid en false
              return;
            }
            const sendoutForApi = Object.assign({}, sendout);
            sendoutForApi.send_date = Math.floor(timeStampMilisegundos / 1000);
            Vue.set(this.sendoutsFinal, index, sendoutForApi);
          }
        });

        var sendouts_filtered = this.sendoutsFinal.filter(function (el) {
          return el != null;
        });

        // Si isValid es falso, detener la ejecución de la función
        if (!isValid) {
          return;
        }

        this.loading = true;

        await ApiService.post("campaign/createSendouts", {
          sendouts: sendouts_filtered,
        })
        .then((response) => {
          this.loading = false;
          this.sendouts_response = [];
          this.idSendouts = [];
          var sendouts = response.data.sendouts;
          sendouts.forEach((sendout, index) => {
            Vue.set(
              this.sendouts_response,
              sendout.database_id,
              Object.assign({}, sendout)
            );
          });
          this.sendouts_response.forEach((sendout, index) => {
            Vue.set(
              this.segmentsFinal,
              sendout.database_id,
              Object.assign({}, JSON.parse(sendout.segmentation))
            );
          });
          this.nextPage();
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
            .flat()
            .forEach((error) => {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: error.message,
                timeout: 3000,
              });
            });
          }
        });
      },
  
      async validateSendout() {
        this.idSendouts = [];
        this.sendouts_response.forEach((sendout, index) => {
          this.idSendouts.push(sendout.id);
        });
  
        await ApiService.post("campaign/validateSendouts", {
          idSendouts: this.idSendouts,
        })
          .then((response) => {
            this.$emit("close");
            this.$store.dispatch('getHTML')
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "Sendout validated & scheduled",
              timeout: 3000,
            });
          })
          .catch((error) => {
            if (typeof error.response.data === "object") {
              Object.values(error.response.data)
                .flat()
                .forEach((error) => {
                  this.$store.dispatch("setSnackbar", {
                    color: "tvf_secondary",
                    text: error.message,
                    timeout: 3000,
                  });
                });
            }
          });
      },
  
      addSegment() {
        if (!this.segments[this.dbSelected].segment) {
          this.$set(this.segments[this.dbSelected], "segment", [this.segment]);
        }
        this.segments[this.dbSelected].segment.push({});
      },
  
      removeSegment() {
        this.segments[this.dbSelected].segment.pop();
      },
  
      handleOrientationChange(e) {
        this.orientation = e.target.step;
      },
  
      nextPage() {
        this.step = this.step + 1;
      },
  
      backPage() {
        this.step = this.step - 1;
        this.creasFinal = [];
      },
  
    },
  };
  </script>
  
  <style scoped>
  @font-face {
    font-family: "Circular Std";
    src: url("~@/assets/fonts/CircularStd-Light.otf"),
      url("~@/assets/fonts/CircularStd-Light Italic.otf");
  }
  
  @font-face {
    font-family: "Circular Medium";
    src: url("~@/assets/fonts/CircularStd-Medium.otf");
  }
  
  @font-face {
    font-family: "Circular Black";
    src: url("~@/assets/fonts/CircularStd-Black.otf"),
      url("~@/assets/fonts/CircularStd-BlackItalic.otf");
  }
  
  .section {
    padding: 0px 12px 25px 12px !important;
  }
  
  .section1 {
    min-height: 35% !important;
    max-height: 35% !important;
    overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
    overflow-x: hidden;
    background-color: #f8f9fb;
    border-radius: 5px;
  }
  
  .section1 > label {
    font-family: "Circular Black";
  }
  
  .section2 {
    min-height: 32% !important;
    background-color: #f8f9fb;
    border-radius: 5px;
  }
  
  .section2_2 {
    margin-top: 20px;
    min-height: 50% !important;
    max-height: 50% !important;
    overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
    overflow-x: hidden;
    background-color: #f8f9fb;
    border-radius: 5px;
  }
  
  .section3 {
    min-height: 15% !important;
    max-height: 15% !important;
    overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
    overflow-x: hidden;
    padding-top: 0.1px;
    background-color: #f8f9fb;
    border-radius: 5px;
  }

  .segments_section{
    min-height: 370px !important;
    max-height: 370px !important;
    overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
    overflow-x: hidden;
  }
  
  .mx-input-wrapper .mx-input {
    background-color: #f8f9fb !important;
  }
  
  .send {
    margin-top: 18%;
    margin-left: 20%;
  }
  
  [v-cloak] {
    display: none;
  }
  
  .schedule1 {
    padding: 12px 12px 0px 12px !important;
  }
  
  .v-application .addButton {
    caret-color: #f85182 !important;
    background-color: #f85182;
    font-size: 13px;
    height: 70px !important;
    width: 95%;
  }
  
  .steps_btn {
    width: 100%;
  }
  
  .functions1,
  .functions2,
  .functions3 {
    height: 40px !important;
  }
  
  .databases4 {
    min-height: 400px !important;
    max-height: 400px !important;
    overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
    overflow-x: hidden;
  }
  
  .databases5 {
    min-height: 60%;
    max-height: 60%;
    overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
    overflow-x: hidden;
  }
  
  .draft_btn {
    width: 95%;
    max-height: 50px !important;
  }
  
  .db_btn {
    max-height: 30px !important;
    width: 90%;
    text-transform: capitalize;
  }
  
  .db_btn2 {
    max-height: 30px !important;
    width: 100%;
    text-transform: capitalize;
  }
  
  .commond_btn {
    max-height: 30px !important;
    width: 100%;
    text-transform: capitalize;
  }
  
  .col-5 {
    max-width: 50%;
    flex: 50%;
  }
  
  .functions1,
  .functions3 {
    text-transform: capitalize;
  }
  
  .addMore {
    background-color: #f85182;
  }
  
  .radiobuttons {
    padding: 12px 0px 12px 12px;
  }
  
  .num_split {
    padding: 0px 12px 12px 2px;
  }
  
  .stepCount {
    margin: auto !important;
    padding-top: 15px;
  }
  
  .addbuton .v-btn:not(.v-btn--round).v-size--x-large {
    height: 81px;
    min-width: 92px;
    padding: 0 23.1111111111px;
  }
  
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
  
  .steps {
    background-image: url("../../assets/fondo.png");
    background-size: 70em;
    background-repeat: no-repeat;
    background-position: 20% 75%;
    border-radius: 5px;
    height: 480px;
    width: 280px !important;
  }
  
  .campaign {
    font-family: "Circular Medium";
  }
  </style>