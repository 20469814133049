<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        :return-value.sync="picker"
        @click:outside="$emit('close')"
        persistent
        width="290px"
      >
        <template>
          <v-row justify="center">
            <v-date-picker v-model="picker">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$emit('close')"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="tvf_color"
                @click="time"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-row>
        </template>
      </v-dialog>
      <time-selector
          v-if="addTime"
          @close="addTime = false"           
      />
    </v-row>
  </div>
</template>

<script>
import TimeSelector from '../calendar/Timepicker.vue'

export default {
  components: {
    TimeSelector
  },    
  data() {
    return {
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      addTime: false,
      errors: [],
      agencies: [],
      databases: [],
    };
  },

  created() {    
    this.$root.eventHub.$on("close-dialog", () => {
        this.visible = false
    })
  },

  mounted() {
    
  },

  methods: {  
    time() {
      this.addTime = !this.addTime      
    }
  }
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8,
.col-md-8 {
  padding: 0px;
}

.v-application div.v-picker__title, .v-application .accent{
    background-color: #F85182 !important;
}


</style>