<template>
  <v-dialog
    :value="true"
    persistent
    max-width="1370px"
  >
    <v-card class="pa-8 d-flex">
      <v-col cols="3">
        <div class="steps my-6">
          <v-row class="d-flex">
            <img
              :src="sendIcon"
              class="send"
            />
            <div class="mt-12">
              <span class="addnew mt-1 mx-4 other--text">ADD NEW</span><br />
              <span class="campaign mt-1 mx-4 other--text">CAMPAIGN</span>
            </div>
          </v-row>
          <v-row>
            <Stepper
              class="caption mx-15 mt-10"
              :value="step"
              :items="items"
              orientation="vertical"
            >
            </Stepper>
          </v-row>
          <v-row>
            <span class="stepCount caption other--text font-weight-bold">STEP {{ step + 1 }} OF 5</span>
          </v-row>
        </div>
      </v-col>
      <v-col cols="9">
        <v-form
          v-if="step === 0"
          aria-autocomplete="off"
        >
          <v-row class="pt-4">
            <v-col cols="4">
              <label class="caption font-weight-bold">Campaign Name</label>
              <v-text-field
                class="input caption"
                :rules="nameRules"
                v-model="templateLocal.campaign_name"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <label class="caption font-weight-bold">Agency</label>
              <v-autocomplete
                v-model="templateLocal.agency"
                :rules="agencyRules"
                class="select-input caption"
                background-color="#F8F9FB"
                :items="agencies"
                item-value="id"
                item-text="name"
                dense
                solo
                flat
              ></v-autocomplete>
              <label class="caption font-weight-bold">Tags</label>
              <v-autocomplete
                class="caption"
                :items='tagsList'
                background-color="#F8F9FB"
                multiple
                item-value="id"
                item-text="name"
                v-model="tagsLocal"
                solo
                dense
                flat
              >
              </v-autocomplete>
              <label class="caption font-weight-bold">Comments</label>
              <v-textarea
                class="caption"
                v-model="templateLocal.comment"
                background-color="#F8F9FB"
                dense
                solo
                flat
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col cols="4">
              <div>
                <label class="caption font-weight-bold">Country</label>
                <v-autocomplete
                  v-model="templateLocal.country"
                  :rules="countryRules"
                  class="select-input caption"
                  background-color="#F8F9FB"
                  :items="countries"
                  item-value="id"
                  item-text="name"
                  dense
                  solo
                  flat
                  @change="getDatabases(templateLocal.country)"
                ></v-autocomplete>
              </div>
              <div>
                <label class="caption font-weight-bold">Database(s) Domain(s)</label>
                <v-autocomplete
                  v-model="databasesLocal"
                  class="dbSize caption"
                  :items='databaseList'
                  :disabled="!templateLocal.country"
                  background-color="#F8F9FB"
                  multiple
                  return-object
                  item-value="id"
                  item-text="name"
                  solo
                  dense
                  flat
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggle"
                    >
                      <v-list-item-action>
                        <v-icon :color="databasesLocal.length > 0 ? 'tvf_color darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </div>
              <div
                v-for="(pay, index) in paysLocal"
                :key="`fieldInput-${index}`"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <label class="caption font-weight-bold">Payout</label>
                    <v-text-field
                      class="caption"
                      v-model="pay.payout"
                      step="0.01"
                      type="number"
                      background-color="#F8F9FB"
                      required
                      dense
                      solo
                      flat
                      :disabled="pay.idPaymodel === 5"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <label class="caption font-weight-bold">Currency</label>
                    <v-select
                      v-model="pay.idCurrency"
                      class="select-input caption"
                      background-color="#F8F9FB"
                      :items="currencies"
                      item-value="id"
                      item-text="symbol"
                      dense
                      solo
                      flat
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <label class="caption font-weight-bold">Paymodel</label>
                    <v-select
                      v-model="pay.idPaymodel"
                      class="select-input caption"
                      background-color="#F8F9FB"
                      :items="paymodels"
                      item-value="id"
                      item-text="name"
                      dense
                      solo
                      flat
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
              <v-btn
                class="addMore my-6 white--text rounded mr-2"
                @click="addPays()"
                elevation="0"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
                add payout
              </v-btn>
              <v-btn
                v-if="paysLocal.length > 1"
                class="addMore my-6 white--text rounded"
                @click="removePays(index)"
                elevation="0"
              >
                <v-icon>
                  mdi-minus
                </v-icon>
              </v-btn>
              <!--  <v-row>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  md="4"
                  sm="4"
                >
                  <v-checkbox
                    class="caption mt-4"
                    v-model="split"
                    label="split"
                    color="tvf_color"
                    value="split"
                  >
                  </v-checkbox>
                </v-col>
                <v-col
                  class="num_split"
                  cols="12"
                  md="3"
                  sm="3"
                >
                  <label class="caption font-weight-bold">Num. Split</label>
                  <v-text-field
                    class="caption"
                    v-model="num_split"
                    type="number"
                    background-color="#F8F9FB"
                    required
                    dense
                    solo
                    flat
                  ></v-text-field>
                </v-col>
              </v-row> -->
            </v-col>
            <v-col
              cols="4"
              class="mt-2"
            >
              <div class="ml-4 mb-14">
                <span class="subtitle-1 font-weight-black">FAQ</span><br />
                <span class="caption font-weight-bold">Question related to the creation of the campaigns.</span><br /><br />
                <span class="caption font-weight-bold">Questions related to campaign subjects.</span><br /><br />
                <span class="caption font-weight-bold">What things are prohibited?</span><br /><br />
                <span class="caption font-weight-bold">Tips for creating a top campaign.</span>
              </div>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-btn
                    class="addButton ml-4 mt-16 white--text"
                    color="#8A0F48"
                    x-large
                  >
                    Do you need help?<br />View the documentation
                  </v-btn>
                </v-col>
                <v-col cols="5">
                  <v-btn
                    class="caption white--text px-11 ml-4"
                    color="black"
                    large
                    @click="backModal()"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-arrow-left
                    </v-icon>
                    Back
                  </v-btn>
                </v-col>
                <v-col cols="5">
                  <v-btn
                    class="caption white--text px-10 ml-1"
                    color="black"
                    large
                    @click="addDatabase"
                    :disabled="!isComplete"
                  >
                    Next
                    <v-icon
                      right
                      dark
                    >
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-form v-if="step === 1">
          <div v-if="creas">
            <v-row class="pt-4">
              <v-col cols="4">
                <label class="caption font-weight-bold">Sender/From name</label>
                <v-text-field
                  class="input caption"
                  v-model.lazy="creasLocal[dbSelected].from_name"
                  background-color="#F8F9FB"
                  dense
                  solo
                  flat
                  @change="actualizarArray('from_name')"
                ></v-text-field>
                <label class="caption font-weight-bold">Reply name</label>
                <v-text-field
                  class="input caption"
                  v-model.lazy="creasLocal[dbSelected].reply_name"
                  background-color="#F8F9FB"
                  dense
                  solo
                  flat
                  @change="actualizarArray('reply_name')"
                ></v-text-field>
                <label class="caption font-weight-bold">Subject</label>
                <v-text-field
                  class="input caption"
                  v-model.lazy="creasLocal[dbSelected].subject"
                  background-color="#F8F9FB"
                  dense
                  solo
                  flat
                  @change="actualizarArray('subject')"
                ></v-text-field>
                <label v-if="customs.length > 0" class="caption font-weight-bold">Customs</label>
                <v-select
                  v-if="customs.length > 0"
                  class="caption"
                  :rules="customsRules"
                  v-model="creasLocal[dbSelected].variables"
                  background-color="#F8F9FB"
                  :items="customs"
                  dense
                  solo
                  flat
                ></v-select>        
                <v-text-field
                  class="caption"
                  v-model="creasLocal[dbSelected].variables"
                  placeholder="Variables"
                  background-color="#F8F9FB"
                  dense
                  solo
                  flat
                  @change="actualizarArray('variables')"
                ></v-text-field>        
                <label class="caption font-weight-bold">Plain text</label>
                <v-textarea
                  class="caption font-weight-regular"
                  v-model="templateLocal.text_version"
                  background-color="#F8F9FB"
                  dense
                  solo
                  flat
                  rows="3"
                  @change="actualizarArray('text_version')"
                ></v-textarea>
              </v-col>
              <v-col cols="4">
                <label class="caption font-weight-bold">From email</label>
                <v-text-field
                  class="input caption"
                  v-model.lazy="creasLocal[dbSelected].from_email"
                  background-color="#F8F9FB"
                  :disabled="dbSelected === 0"
                  dense
                  solo
                  flat
                  @change="actualizarArray('from_email')"
                ></v-text-field>
                <label class="caption font-weight-bold">Reply email</label>
                <v-text-field
                  class="input caption"
                  v-model.lazy="creasLocal[dbSelected].reply_email"
                  background-color="#F8F9FB"
                  :disabled="dbSelected === 0"
                  dense
                  solo
                  flat
                  @change="actualizarArray('reply_email')"
                ></v-text-field>
                <label class="caption font-weight-bold">Pre-header</label>
                <v-text-field
                  class="caption"
                  v-model.lazy="creasLocal[dbSelected].preheader"
                  background-color="#F8F9FB"
                  dense
                  solo
                  flat
                  @change="actualizarArray('preheader')"
                ></v-text-field>                
                <v-row class="d-flex mx-1">
                  <label class="caption font-weight-bold mr-auto">Html</label>
                  <v-checkbox
                    id="kit"
                    class="caption"
                    v-model="kitHtml"
                    label="Kit from files"
                    color="tvf_color"
                    :value=true
                  ></v-checkbox>
                </v-row>
                <div
                  v-if="kitHtml === true"
                  class="flex items-center justify-center text-center"
                  id="app"
                >
                  <v-file-input
                    v-model="creasLocal[dbSelected].files"
                    type="file"
                    background-color="#F8F9FB"
                    solo
                    flat
                    small-chips
                    multiple
                    class="caption opacity-0 overflow-hidden absolute"
                    ref="file"
                    accept=".jpg,.jpeg,.png,.html"
                  >
                  </v-file-input>
                </div>
                <v-textarea
                  v-else
                  class="caption"
                  v-model="creasLocal[dbSelected].html_original"
                  background-color="#F8F9FB"
                  dense
                  solo
                  flat
                  rows="7"
                  @input="getPlainText(creasLocal[dbSelected].html_original)"
                  @change="actualizarArray('html_original')"
                ></v-textarea>
              </v-col>
              <v-col cols="4">
                <div class="ml-2 pt-3 databases1">
                  <v-btn
                    :class="dbSelected === 0 ? 'commond_btn caption px-16 white--text':'commond_btn caption px-16 black--text'"
                    :color="dbSelected === 0 ? 'black': '#F8F9FB'"
                    x-large
                    elevation="0"
                    @click="assignId(0)"
                  >
                    Commond values
                    <v-icon
                      right
                      dark
                    >
                      mdi-check
                    </v-icon>
                  </v-btn>
                  <div
                    v-for="database, index in databaseFinal"
                    :key="index"
                    class="d-flex"
                  >
                    <v-btn
                      :class="database.id === dbSelected ? 'db_btn caption mt-3 white--text':'db_btn caption mt-3 black--text'"
                      :color="database.id === dbSelected ? 'black': '#F8F9FB'"
                      elevation="0"
                      @click="assignId(database.id)"
                    >
                      <v-icon
                        v-if="formsCreaValid[database.id]"
                        left
                        dark
                        color="green"
                      >
                        mdi-check-circle
                      </v-icon>
                      <v-icon
                        v-else
                        left
                        dark
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                      {{ database.name }}
                    </v-btn>
                    <v-checkbox
                      class="mt-4"
                      v-model="creasLocal[database.id].status"
                    ></v-checkbox>
                  </div>
                </div>
                <div class="mt-1">
                  <v-btn
                    class="draft_btn caption ml-4 px-16 mt-3 white--text"
                    color="tvf_color"
                    large
                    @click="saveDraft"
                    :loading="loadingDraft"
                    :disabled="loadingDraft"
                  >
                    <v-icon
                      left
                      dark
                    >mdi-eraser</v-icon>Save as draft
                  </v-btn>
                  <v-row class="mt-2">
                    <v-col cols="5">
                      <v-btn
                        class="caption white--text px-6 ml-4"
                        color="black"
                        large
                        block
                        :loading="loading"
                        :disabled="loading"
                        @click="backPage"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-arrow-left
                        </v-icon>
                        Back
                      </v-btn>
                    </v-col>
                    <v-col cols="5">
                      <v-btn
                        class="caption white--text px-6"
                        color="black"
                        large
                        :loading="loading"
                        :disabled="!hasChecked"
                        block
                        @click="createCampaign"
                      >
                        Next
                        <v-icon
                          right
                          dark
                        >
                          mdi-arrow-right
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <div
          class="d-flex pt-4"
          style="width: 100%"
          v-if="step === 2"
        >
          <div style="width: 66%; display: grid; grid-template-rows: 2fr 1fr;">
            <div>
              <v-expansion-panels
                multiple
                class="pb-4"
                v-if="result"
              >
                <v-expansion-panel class="custom-expansion-panel">
                  <v-expansion-panel-header>
                    <p class="body-2 font-weight-bold mt-3">Status general:</p>
                    <p
                      v-if="result.status !== 'OK'"
                      class="caption red--text mt-4"
                    >
                      <v-icon
                        small
                        color="red"
                      >mdi-alert
                      </v-icon> {{ result.status }}
                    </p>
                    <p
                      v-else
                      class="caption green--text mt-4"
                    >
                      <v-icon
                        small
                        color="green"
                      >mdi-check
                      </v-icon> {{ result.status }}
                    </p>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="scrollable-content">
                    <div
                      class="pt-2"
                      v-if="result[dbSelected].pixels"
                    >
                      <p class="body-1 font-weight-bold">PIXELS</p>
                      <div
                        v-for="pixel, index in result[dbSelected].pixels"
                        :key="index"
                        class="pa-4 mb-6" 
                        style="border: 1px solid #fff; border-radius: 5px;background-color: #F8F9FB;"
                      >
                        <p class="caption font-weight-bold">URL: <span class="caption">{{ pixel.url }}</span></p>
                        <p class="caption font-weight-bold">STATUS: <span class="red--text">{{ pixel.status }}</span></p>
                        <p v-if="pixel.status === 'blacklist'" class="caption font-weight-bold">BLACKLIST:</p>
                        <div
                          v-for="bl, index in pixel.blacklist"
                          :key="index"
                        >
                          <p
                            v-if="pixel.status === 'Blacklist'"
                            class="caption red--text"
                          >{{ bl }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="item"
                      v-if="result[dbSelected].status === 'html_no_equals'"
                    >
                      <p class="caption font-weight-bold">PERCENT: <span class="caption">{{ result[dbSelected].percent.toFixed(2) }} %</span></p>
                      <p class="caption font-weight-bold">STATUS: <span class="red--text ">{{ result[dbSelected].status }}</span></p>
                    </div>
                    <div
                      class="item"
                      v-if="result[dbSelected].hrefs"
                    >
                      <p class="body-1 font-weight-bold">HREFS</p>
                      <div
                        v-for="href, index in result[dbSelected].hrefs"
                        :key="index"
                        class="pa-4 mb-6" 
                        style="border: 1px solid #fff; border-radius: 5px;background-color: #F8F9FB;"
                      >
                        <p class="caption font-weight-bold">URL: <span class="caption">{{ href.url }}</span></p>
                        <p class="caption font-weight-bold">STATUS: <span class="red--text ">{{ href.status }}</span></p>
                        <p v-if="href.status === 'blacklist'" class="caption font-weight-bold">BLACKLIST:</p>
                        <div
                          v-for="bl, index in href.blacklist"
                          :key="index"
                        >
                          <p
                            v-if="href.status === 'blacklist'"
                            class="caption red--text"
                          >{{ bl }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="item"
                      v-if="result[dbSelected].srcs"
                    >
                      <p class="body-1 font-weight-bold">SRCS</p>
                      <div
                        v-for="src, index in result[dbSelected].srcs"
                        :key="index"
                        class="pa-4 mb-6" 
                        style="border: 1px solid #fff; border-radius: 5px;background-color: #F8F9FB;"
                      >
                        <p class="caption">{{ src }}</p>
                      </div>
                    </div>                    
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr;">
              <div style="margin-right: 10px">
                <v-btn
                  class="caption white--text"
                  color="black"
                  large
                  block
                  @click="originalPreview(dbSelected)"
                >
                  <v-icon
                    left
                    dark
                  >mdi-file-outline</v-icon> Original preview
                </v-btn>
                <v-btn
                  class="caption white--text mt-4"
                  color="black"
                  large
                  block
                  @click="processedPreview"
                >
                  <v-icon
                    left
                    dark
                  >mdi-file-code-outline</v-icon> Processed Preview
                </v-btn>
                <v-btn
                  class="caption white--text mt-4"
                  color="black"
                  large
                  block
                  @click="sendBat(dbSelected)"
                >
                  <v-icon
                    left
                    dark
                  >mdi-email</v-icon> Bat
                </v-btn>
              </div>
              <div style="margin-left: 10px">
                <p></p>
                <v-btn
                  class="draft_btn caption px-16 mt-15 white--text"
                  color="tvf_color"
                  large
                  block
                  @click="saveDraft"
                >
                  <v-icon
                    left
                    dark
                  >mdi-eraser</v-icon> Save as draft
                </v-btn>
                <v-btn
                  class="caption white--text mt-4"
                  color="#FF005B"
                  large
                  block
                  @click="confirmRefuse"
                >
                  <v-icon
                    left
                    dark
                  >mdi-close</v-icon> Refuse Crea
                </v-btn>
              </div>
            </div>
          </div>
          <div style="width: 30%; margin-left: 20px">
            <div class="databases3 ml-4">
              <div
                v-for="(crea, index) in creas_response"
                :key="index"
              >
                <v-btn
                  v-if="crea.status === null"
                  :class="crea.database_id === dbSelected ? 'db_btn2 caption mt-3 white--text' : 'db_btn2 caption mt-3 black--text'"
                  :color="crea.database_id === dbSelected ? 'black': '#F8F9FB'"
                  elevation="0"
                  x-large
                  @click="assignIdSendouts(crea.database_id)"
                >
                  {{ crea.database.name }}
                  <v-icon
                    v-if="result.status === 'OK' || result.status === ''"
                    right
                    dark
                    color="green"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    right
                    dark
                  >mdi-alert-circle-outline</v-icon>
                </v-btn>
              </div>
            </div>
            <v-row class="mt-1">
              <v-col cols="5">
                <v-btn
                  class="caption white--text px-6 ml-4"
                  color="black"
                  large
                  block
                  @click="backPage"
                >
                  <v-icon
                    left
                    dark
                  >mdi-arrow-left</v-icon>Back
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-btn
                  v-if="result.status === 'OK' || result.status === ''"
                  class="caption white--text px-6"
                  color="black"
                  :disabled="loading"
                  large
                  block
                  @click="nextPage"
                >
                  <v-icon dark>mdi-arrow-right</v-icon>
                  Next
                </v-btn>
                <v-btn
                  v-else
                  class="caption white--text px-6"
                  color="red"
                  :disabled="loading || result.status === 'html_not_equal'"
                  large
                  block
                  @click="nextPage"
                >
                  Next
                  <v-icon small>mdi-alert
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-form v-if="step === 3">
          <v-row class="pt-6">
            <v-col
              cols="4"
              v-if="segmentsList.length"
            >
              <LoadingSpinner v-if="isLoading" />
              <div class="segments_section">
                <div
                  v-for="(input, index) in segments[dbSelected].segment"
                  :key="`fieldInput-${index}`"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="8"
                      md="8"
                    >
                      <label class="caption font-weight-bold">Segment</label>
                      <v-autocomplete
                        class="caption"
                        v-model="input.id"
                        background-color="#F8F9FB"
                        :items="segmentsList"
                        item-value="Id"
                        item-text="Name"
                        return-object
                        dense
                        solo
                        flat
                        :disabled="loading"
                        @change="getAmount(input.id, index)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <label class="caption font-weight-bold">Amount</label>
                      <v-text-field
                        class="caption"
                        v-model="input.amount"
                        :loading="loading"
                        readonly
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-btn
                class="caption px-16 mt-3 white--text"
                color="tvf_color"
                elevation="0"
                block
                :disabled="loading"
                @click="addSegment()"
              >
                Add segment
              </v-btn>
              <v-btn
                v-if="segments[dbSelected].segment.length > 1"
                class="caption px-16 mt-3 white--text"
                color="tvf_color"
                elevation="0"
                block
                @click="removeSegment()"
              >
                Delete segment
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              v-else
            >
              <div>
                <v-row>
                </v-row>
              </div>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col
                  class="schedule"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <label class="caption font-weight-bold">Send date</label><br />
                  <date-picker
                    prefix-class="xmx"
                    class="caption datetime"
                    :rules="dateRules"
                    readonly
                    v-model="sendouts[dbSelected].send_date"
                    value-type="timestamp"
                    format="YYYY-MM-DD HH:mm:ss"
                    :disabled-date="notBeforeToday"
                    type="datetime"
                  >
                  </date-picker>
                </v-col>
                <v-col
                  class="schedule1"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <label class="caption font-weight-bold">Throttle</label>
                  <v-text-field
                    class="caption"
                    type="number"
                    :rules="throttleRules"
                    :value="sendout.throttle"
                    v-model="sendouts[dbSelected].throttle"
                    background-color="#F8F9FB"
                    dense
                    solo
                    flat
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <label class="caption font-weight-bold">Limit</label>
                  <v-text-field
                    class="caption"
                    type="number"
                    v-model="sendouts[dbSelected].limit"
                    :value="sendout.limit"
                    background-color="#F8F9FB"
                    dense
                    solo
                    flat
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="headerStyle">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <label class="caption font-weight-bold">Header</label>
                  <v-select
                    class="caption"
                    :rules="headerRules"
                    v-model="sendouts[dbSelected].header"
                    background-color="#F8F9FB"
                    :items="headers"
                    item-value="id"
                    item-text="name"
                    dense
                    solo
                    flat
                  ></v-select>
                  <label class="caption font-weight-bold">Footer</label>
                  <v-select
                    class="caption"
                    :rules="footerRules"
                    v-model="sendouts[dbSelected].footer"
                    background-color="#F8F9FB"
                    :items="footers"
                    item-value="id"
                    item-text="name"
                    dense
                    solo
                    flat
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <div class="databases4 ml-4">
                <div
                  v-for="crea, index in creas_response"
                  :key="index"
                >
                  <v-btn
                    v-if="crea.status === null"
                    :class="crea.database_id === dbSelected ? 'db_btn caption mt-3 white--text':'db_btn caption mt-3 black--text'"
                    :color="crea.database_id === dbSelected ? 'black': '#F8F9FB'"
                    elevation="0"
                    x-large
                    block
                    @click="assignIdSendouts(crea.database_id)"
                  >
                    {{ crea.database.name }}
                    <v-icon
                      v-if="formsValid[crea.database_id]"
                      right
                      dark
                      color="green"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      right
                      dark
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <v-row class="mt-1">
                <v-col cols="5">
                  <v-btn
                    class="caption white--text px-4 ml-4"
                    color="black"
                    large
                    block
                    @click="backPage"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-arrow-left
                    </v-icon>
                    Back
                  </v-btn>
                </v-col>
                <v-col cols="5">
                  <v-btn
                    class="caption white--text"
                    color="black"
                    :loading="loading"
                    :disabled="loading"
                    large
                    block
                    @click="saveAndSchedule"
                  >
                    Save & <br />
                    Schedule
                    <v-icon
                      right
                      dark
                    >
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <div v-if="step === 4">
          <v-row class="mb-4">
            <v-col cols="4">
              <div class="section1_1 mt-6">
                <v-row class="pl-4">
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <label class="caption font-weight-bold">Name</label><br />
                    <span class="caption">{{ campaign.template.name }}</span>
                  </v-col>
                  <v-col
                    class="d-flex justify-space-between"
                    cols="12"
                    sm="7"
                    md="7"
                  >
                  <div>
                    <label class="caption font-weight-bold">Country</label><br />
                    <span class="caption">{{ campaign.template.country.name }}</span><br />
                  </div>
                  <div>
                    <label class="caption font-weight-bold">Agency</label><br />
                    <span class="caption">{{ campaign.template.agency.name }}</span>
                  </div>
                  </v-col>
                </v-row>
              </div>            
              <div class="section2_2">
                <v-row class="mt-6 pl-4">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="caption font-weight-bold">Send Date</label><br />
                    <span class="caption">{{ sendouts_response[dbSelected].send_date }}</span><br /><br />
                    <label class="caption font-weight-bold">Header</label><br />
                    <span class="caption">{{ sendouts_response[dbSelected].headerName }}</span><br /><br />
                    <label class="caption font-weight-bold">Segment</label><br />
                    <div
                      v-for="input,index in this.sendouts[this.dbSelected].segmentation"
                      :key="index"
                    >
                      <span
                        v-if="input != null"
                        class="caption"
                      >{{ input }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <label class="caption font-weight-bold">Throttle</label><br />
                    <span class="caption">{{ sendouts_response[dbSelected].throttle }}</span><br /><br />
                    <label class="caption font-weight-bold">Footer</label><br />
                    <span class="caption">{{ sendouts_response[dbSelected].footerName }}</span><br /><br />
                    <label class="caption font-weight-bold">Amount</label><br />
                    <span class="caption">{{ sendouts_response[dbSelected].amount_sent }}</span><br />
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col
                  cols="6"
                  class="d-flex pt-8"
                >
                  <v-btn
                    class="functions1 caption white--text mr-4"
                    color="black"
                    x-large
                    block
                    @click='previewFinalHtml(dbSelected)'
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-eye
                    </v-icon>
                    PREVIEW
                  </v-btn>
                  <v-btn
                    class="functions2 caption white--text pt-1 ml-2"
                    color="black"
                    x-large
                    block
                    @click="previewText"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-file-document-outline
                    </v-icon>
                    text <br />version
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <div class="section1 mt-6">
                <v-row class="pl-4">
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                  >
                    <label class="caption font-weight-bold">From Name</label><br />
                    <span class="caption">{{ creasLocal[dbSelected].from_name }}</span><br />
                    <label class="caption font-weight-bold">Reply Name</label><br />
                    <span class="caption">{{ creasLocal[dbSelected].reply_name }}</span><br />
                    <label class="caption font-weight-bold">Sender Domain</label><br />
                    <span class="caption">{{ sendouts_response[dbSelected].sender_domain }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="7"
                    md="7"
                  >
                    <label class="caption font-weight-bold">From Email</label><br />
                    <span class="caption">{{ creasLocal[dbSelected].from_email }}</span><br />
                    <label class="caption font-weight-bold">Reply Email</label><br />
                    <span class="caption">{{ creasLocal[dbSelected].reply_email }}</span><br />
                    <label class="caption font-weight-bold">Tag</label><br />
                    <div
                      v-for="input,index in campaign.template.tags"
                      :key="index"
                    >
                      <span class="caption">{{ input.name }}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="section2">
                <v-row class="mt-6 pl-4">
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <label class="caption font-weight-bold">Variable</label><br />
                    <span class="caption">{{ creasLocal[dbSelected].variables }}</span><br />
                    <label class="caption font-weight-bold">Subject</label><br />
                    <span class="caption">{{ creasLocal[dbSelected].subject }}</span><br />
                    <label class="caption font-weight-bold">Pre-header</label><br />
                    <span class="caption">{{ creasLocal[dbSelected].preheader }}</span>
                  </v-col>
                </v-row>
              </div>
              <div class="section3 mt-3">
                <v-row class="mt-1 pl-6">
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                  >
                    <label class="caption font-weight-bold">Payout</label><br />
                    <div
                      v-for="input,index in campaign.template.paymodels"
                      :key="index"
                    >
                      <span class="caption">{{ input.paymodel_template.payout }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="5"
                    md="5"
                  >
                    <label class="caption font-weight-bold">Paymodel</label><br />
                    <div
                      v-for="input,index in campaign.template.paymodels"
                      :key="index"
                    >
                      <span class="caption">{{ input.name }}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col
                  cols="6"
                  class="d-flex pt-8"
                >
                  <v-btn
                    class="functions2 caption white--text mr-1"
                    color="#8A0F48"
                    x-large
                    block
                    @click="validateBAT(dbSelected)"
                  >
                    VALIDATE BAT
                  </v-btn>
                </v-col>
                <v-col
                  cols="6"
                  class="d-flex pt-8"
                >
                  <v-btn
                    class="functions2 caption white--text mr-1"
                    color="black"
                    x-large
                    block
                    @click="backPage"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-arrow-left
                    </v-icon>
                    Back
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <div class="databases5 ml-4 mb-14">
                <div
                  v-for="crea, index in creas_response"
                  :key="index"
                >
                  <v-btn
                    v-if="crea.status === null"
                    :class="crea.database_id === dbSelected ? 'db_btn caption mt-3 white--text':'db_btn caption px-16 mt-3 black--text'"
                    :color="crea.database_id === dbSelected ? 'black': '#F8F9FB'"
                    x-large
                    elevation="0"
                    @click="assignId(crea.database_id)"
                  >
                    {{ crea.database.name }}
                    <v-icon
                      right
                      dark
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <div>
                <v-btn
                  class="draft_btn caption ml-4 px-16 mt-3 white--text"
                  color="#8A0F48"
                  large
                  @click="validateSendout"
                >
                  VALIDATE & SCHEDULE
                </v-btn>
                <v-btn
                  class="draft_btn caption ml-4 px-16 mt-3 white--text"
                  color="black"
                  large
                  @click="closeModal()"
                  :disabled="batValidated"
                >
                  SCHEDULE ALL
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-card>
    <show-preview
      v-if="preview"
      @close="preview = false"
      :html="html"
    ></show-preview>
    <send-bat
      v-if="bat"
      :validation="validation"
      :html="html"
      :text_version="text_version"
      :dbCountry="dbCountry_id"
      :sendout="sendout"
      :crea="crea"
      :campaign="campaign"
      :dbId="dbId"
      :db="db"
      :sender="sender"
      :campaignName="campaignName"
      :dbName="dbName"
      @close="bat = false"
    ></send-bat>
    <v-dialog
      v-model="refuse"
      :value="true"
      persistent
      @click:outside="refuse = false"
      max-width="500px"
    >
      <v-card>
        <v-form>
          <v-card-text>
            <v-container>
              <v-col>
                <v-row class="px-8 py-4">
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <label class="caption font-weight-bold">Comment</label>
                    <v-textarea
                      class="caption font-weight-regular"
                      v-model="comment"
                      background-color="#F8F9FB"
                      dense
                      solo
                      flat
                      rows="3"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-btn
                      class="caption mt-3 white--text"
                      color="tvf_color"
                      block
                      large
                      :loading="loading"
                      :disabled="loading"
                      @click="refuseBtn"
                    >
                      Refuse
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { Stepper } from "@progress/kendo-vue-layout";
import ApiService from "../../services/api.service";
import ShowPreview from "@/components/campaign/htmlPreview";
import SendBat from "@/components/campaign/SendBat";
import RefuseCrea from "@/components/campaign/RefuseCrea";
import LoadingSpinner from "@/components/core/LoadingSpinner";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: {
    batValidated: {
      type: Boolean,
      default: true,
    },
    template: {
      type: Object,
    },
    tags: {
      type: Array,
    },
    pays: {
      type: Array,
    },
    creas: {
      type: Array,
    },
    templateID: {
      type: Number,
    },
    databases: {
      type: Array,
    },
    dbCountry: {
      type: Number,
    },
    createModal: {
      type: Boolean,
    },
    stepCampaign: {
      type: Number,
    },
    sendoutsLocal: {
      type: Array,
    },
    selectedDb: {
      type: Number,
    },
  },
  components: {
    Stepper,
    ShowPreview,
    SendBat,
    RefuseCrea,
    DatePicker,
    LoadingSpinner,
  },
  data() {
    //DATOS
    return {
      sendIcon: require("@/assets/icons/send_icon.png"),
      dbSelected: this.selectedDb,
      loading: false,
      isLoading: false,
      controller: "",
      kitHtml: false,
      all: false,
      split: false,
      preview: false,
      bat: false,
      refuse: false,
      validation: false,
      refuseCrea: false,
      loadingDraft: false,
      hasChecked: false,
      batSended: this.batValidated,
      num_split: "",
      comment: "",
      amount: "",
      sender: "",
      step: this.stepCampaign,
      creasLocal: this.creas,
      templateLocal: this.template,
      tagsLocal: this.tags,
      paysLocal: this.pays,
      databasesLocal: this.databases,
      idTemplate: this.templateID,
      crea: {
        idCrea: null,
        database_id: "",
        template_id: "",
        html_original: "",
        files: [],
        from_name: "",
        from_email: "",
        reply_name: "",
        reply_email: "",
        subject: "",
        variables: "",
        preheader: "",
        status: false,
      },
      formsValid: {},
      formsCreaValid: {},
      htmlNotEquals: [],
      result: [],
      paymodels: [],
      headers: [],
      footers: [],
      tagsList: [],
      tagsIds: [],
      currencies: [],
      status: "",
      databaseList: [],
      databaseListAux: [],
      databaseFinal: [],
      custom_field: "",
      campaign: [],
      creasFinal: [],
      filelist: [],
      agencies: [],
      countries: [],
      header: "",
      headers: [],
      footer: "",
      footers: [],
      creas_response: [],
      time: "",
      segmentsList: [],
      sendouts: this.sendoutsLocal,
      sendouts_response: [],
      idSendouts: [],
      segmentsFinal: [],
      segments: [],
      segment: {
        database_id: "",
        id: "",
        amount: "",
      },
      sendout: {
        identifier: null,
        user_id: this.$store.state.auth.user.id,
        database_id: "",
        crea_id: "",
        segmentation: {},
        send_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        limit: null,
        throttle: 24,
        header: "",
        footer: "",
        comment: "",
        type: "sendout",
        status: "Pending validation",
      },
      customs: [],
      customs_mock: [
        {
          country: 6,
          variables: ["&firstname={{firstname}}&lastname={{lastname}}&email={{email}}"]
        },
        {
          country: 2,
          variables: [
            "&nome={{firstname}}&cognome={{lastname}}&email={{email}}",
            "&email={{email}}&cognome={{lastname}}&nome={{firstname}}",
            "&email={{email}}&cognome={{lastname}}&nome={{firstname}}&cellulare=",
            "&email={{email}}&cognome={{lastname}}&nome={{firstname}}&telefono=",
            "&nome={{firstname}}&email={{email}}&cognome={{lastname}}",
            "&aff_sub2={{email}}&aff_sub3={{firstname}}&aff_sub4={{lastname}}>"
          ],
        },
      ],
      items: [
        {
          icon: "k-i-pencil",
          label: "Basic \n Information",
        },
        {
          icon: "k-i-code",
          label: "Content",
        },
        {
          icon: "k-i-eye",
          label: "Preview",
        },
        {
          icon: "k-i-calendar",
          label: "Schedule",
        },
        {
          icon: "k-i-check",
          label: "Confirm Schedule",
        },
      ],
      dateRules: [(v) => !!v || "Send date is required"],
      nameRules: [(v) => !!v || "Campaign name is required"],
      agencyRules: [(v) => !!v || "Agency is required"],
      countryRules: [(v) => !!v || "Country is required"],
      throttleRules: [(v) => !!v || "Throttle name is required"],
      headerRules: [(v) => !!v || "Header is required"],
      footerRules: [(v) => !!v || "Footer is required"],
      fromNameRules: [(v) => !!v || "From name are required"],
      fromEmailRules: [(v) => !!v || "From Email are required"],
      replyNameRules: [(v) => !!v || "Reply Name are required"],
      replyEmailRules: [(v) => !!v || "Reply Email are required"],
    };
  },

  mounted() {
    this.getAgencies();
    this.getCountriesDB().then(() => {
      // Solo cuando databaseListAux está listo, se llama a getDatabases
      if (this.templateLocal && this.templateLocal.country) {
        this.getDatabases(this.templateLocal.country);
      }
    });
    this.getTags();
    this.getPaymodels();
    this.getCurrencies();
    this.$store.commit("getPrefills");
  },

  watch: {
    creas: {
      handler(newValue) {
        this.creasLocal = newValue;
      },
      immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
    },
    creasLocal: {
      deep: true,
      handler() {
        // Verifica si algún checkbox está activado
        this.hasChecked = Object.values(this.creasLocal).some(
          (database) => database.status
        );

        // Verificar la validez de los campos
        this.checkCreaFieldsValidity();
      },
    },
    // Suponiendo que "templateLocal" es un objeto con el campo "text_version"
    "templateLocal.text_version": function () {
      this.checkCreaFieldsValidity();
    },
    tags: {
      handler(newValue) {
        this.tagsLocal = newValue;
      },
      immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
    },
    template: {
      handler(newValue) {
        this.templateLocal = newValue;
        // Aquí llamamos a getDatabases si templateLocal.country tiene un valor
        if (this.templateLocal.country) {
          this.getDatabases(this.templateLocal.country);
        }
      },
      immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
    },
    templateID: {
      handler(newValue) {
        this.idTemplate = newValue;
      },
      immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
    },
    databases: {
      handler(newValue) {
        this.databasesLocal = newValue;
      },
      immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
    },
    pays: {
      handler(newValue) {
        this.paysLocal = newValue;
      },
      immediate: true, // Ejecuta el watcher inmediatamente después de la creación del componente
    },
    stepCampaign: {
      handler(newValue) {
        this.step = newValue;
      },
      immediate: true,
    },
    sendoutsLocal: {
      handler(newValue) {
        this.sendouts = newValue;
      },
      immediate: true,
    },
    selectedDb: {
      handler(newValue) {
        this.dbSelected = newValue;
      },
      immediate: true,
    },
    selectedItem(newValue) {
      if (newValue) {
        this.keyValueObject = { [newValue.id]: newValue.name };
      } else {
        this.keyValueObject = {};
      }
    },
    segments: {
      handler() {
        this.checkFormValidity();
      },
      deep: true,
    },
    sendouts: {
      handler() {
        this.checkFormValidity();
      },
      deep: true,
    },
  },

  computed: {
    isComplete() {
      return (
        this.templateLocal.campaign_name &&
        this.templateLocal.agency &&
        this.templateLocal.country &&
        this.tagsLocal.length > 0 &&
        this.databasesLocal.length > 0 &&
        this.paysLocal.every(
          (pay) => pay.payout && pay.idCurrency && pay.idPaymodel
        )
      );
    },

    /* allFormsValid() {
        console.log('Checking allFormsValid');
        console.log('formsValid:', this.formsValid);
        const allValid = Object.values(this.formsValid).every(item => item === true);
        console.log('allValid:', allValid);
        return allValid;
      }, */

    isFormValid() {
      return (
        this.segments[this.dbSelected].segment.every(
          (segment) => segment.id && segment.amount
        ) &&
        this.sendouts[this.dbSelected].send_date &&
        this.sendouts[this.dbSelected].header &&
        this.sendouts[this.dbSelected].footer
      );
    },

    likesAllDb() {
      return this.databasesLocal.length === this.databaseList.length;
    },
    likesSomeDb() {
      return this.databasesLocal.length > 0 && !this.likesAllDb;
    },

    icon() {
      if (this.likesAllDb) return "mdi-close-box";
      if (this.likesSomeDb) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  methods: {

    checkFormValidity() {
      for (let dbID of Object.keys(this.segments)) {
        const isValid =
          this.segments[dbID].segment.every(
            (segment) => segment.id && segment.amount
          ) &&
          this.sendouts[dbID].send_date &&
          this.sendouts[dbID].header &&
          this.sendouts[dbID].footer;
        Vue.set(this.formsValid, dbID, isValid);
      }
    },

    checkCreaFieldsValidity() {
      for (let index in this.creasLocal) {
        if (this.creasLocal[index]) {
          const crea = this.creasLocal[index];
          const isValid =
            crea.html_original &&
            crea.reply_email &&
            crea.from_email &&
            this.templateLocal.text_version &&
            crea.subject &&
            crea.reply_name &&
            crea.from_name;
          Vue.set(this.formsCreaValid, index, isValid);
        } else {
          Vue.set(this.formsCreaValid, index, false);
        }
      }
    },

    backModal() {
      this.paysLocal.forEach((pay) => {
        (pay.idCurrency = 1), (pay.idPaymodel = ""), (pay.payout = "");
      });
      this.$store.dispatch("getHTML");
      this.$emit("close");
    },

    ////////////dESHABILITAR FECHAS ANTERIORES A HOY EN EL DATEPICKER

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    stringToTimestamp(dateString) {
      return new Date(dateString).getTime();
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllDb) {
          this.databasesLocal = [];
        } else {
          this.databasesLocal = this.databaseList.slice();
        }
      });
    },

    actualizarArray(input) {
      if (this.dbSelected === 0) {
        this.creasLocal.forEach((objeto, indice) => {
          if (indice !== 0) {
            objeto[input] = this.creasLocal[0][input];
          }
        });
      }
    },

    //DE HTML A TEXTO PLANO

    getPlainText(html) {
      const doc = new DOMParser().parseFromString(html, "text/html");
      const walker = document.createTreeWalker(
        doc.body,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );
      let text = "";
      while (walker.nextNode()) {
        text += walker.currentNode.textContent.trim() + " ";
      }
      this.template.text_version = text.replace(/\s+/g, " ");
    },

    /////////////////////////////////////////////////////////////////////////////////ASIGNAR DATABASE ID

    assignId(id) {
      this.dbSelected = id;

      this.customs_mock.forEach(cust => {
        if(this.templateLocal.country === cust.country){
          this.customs = cust.variables
        }
      })
    },

    //TRAER LOS SEGMENTOS Y HEADER/FOOTER SEGÚN DB

    assignIdSendouts(id) {
      this.dbSelected = id;
      this.segmentsList = [];
      this.headers = [];
      this.footers = [];

      if (!this.segments.hasOwnProperty(this.dbSelected)) {
        const newSegment = { ...this.segment, database_id: this.dbSelected };
        Vue.set(this.segments, this.dbSelected, { segment: [newSegment] });
      }

      this.isLoading = true;
      ApiService.get("campaign/getListSegment/" + this.dbSelected)
        .then((response) => {
          this.isLoading = false;
          this.segmentsList = response.data.segments;
        })
        .catch((error) => {
          this.isLoading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });

      ApiService.get("hf/listByDb/" + this.dbSelected)
        .then((response) => {
          let list = response.data.data;
          list.forEach((hf) => {
            if (hf.type === "header") {
              this.headers.push(hf);
            } else {
              this.footers.push(hf);
            }
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    /////////////////////////////////////////////////////////////////////////////////AÑADIR ID DATABASES AL ARRAY CREAS

    addDatabase() {
      this.dbSelected = 0;

      this.databaseFinal = this.databasesLocal.sort(function (a, b) {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      if (this.createModal === true) {
        this.creasLocal = [];
        let self = this;
        this.databaseFinal.forEach((element, index) => {
          (function (element, crea) {
            var data = crea;
            if (index === 0) {
              Vue.set(self.creasLocal, 0, Object.assign({}, data));
            }
            data.database_id = element.id;
            data.from_email = element.from_email;
            data.from_name = element.from_name;
            data.reply_email = element.reply_email;
            data.reply_name = element.reply_name;
            Vue.set(self.creasLocal, element.id, Object.assign({}, data));
          })(element, this.crea);
        });
      } else {
        // Inicia la comparación de arrays cuando createModal es 'false'
        this.databaseFinal.forEach((dbElement) => {
          let foundInCreasLocal = false;
          this.creasLocal.forEach((creaElement, creaIndex) => {
            if (dbElement.id === creaElement.database_id) {
              foundInCreasLocal = true;
            }
          });

          if (!foundInCreasLocal) {
            let newCrea = Object.assign({}, this.crea);
            newCrea.database_id = dbElement.id;
            newCrea.from_email = dbElement.from_email;
            newCrea.from_name = dbElement.from_name;
            newCrea.reply_email = dbElement.reply_email;
            newCrea.reply_name = dbElement.reply_name;
            this.creasLocal[dbElement.id] = newCrea;
          }
        });

        // Filtra el array creasLocal para eliminar los objetos que tienen un database_id diferente a los id en databaseFinal

        const creasLocalAux = this.creasLocal.filter((creaElement) => {
          return this.databaseFinal.some((dbElement) => {
            return dbElement.id === creaElement.database_id;
          });
        });
        this.creasLocal = [];

        creasLocalAux.forEach((element) => {
          Vue.set(
            this.creasLocal,
            element.database_id,
            Object.assign({}, element)
          );
        });

        Vue.set(this.creasLocal, 0, Object.assign({}, this.crea));
      }

      this.step = this.step + 1;
    },

    previewHtml(id) {
      this.html = this.creasLocal[id].html_original;
      this.preview = true;
    },

    previewFinalHtml(id) {
      this.html = this.sendouts_response[id].final_html;
      this.preview = true;
    },

    previewText() {
      this.html = this.template.text_version;
      this.preview = true;
    },

    onChange() {
      this.filelist = [...this.$refs.file.files];
    },

    remove(i) {
      this.filelist.splice(i, 1);
    },

    sendBat(id) {
      this.bat = true;
      this.dbId = id;
      this.creas_response.forEach((crea) => {
        if (crea.database_id === id) {
          this.crea = crea;
          this.html = crea.htmlFinal;
          this.sender = crea.from_name;
          this.dbCountry_id = crea.database.country_id;
        }
      });
      this.text_version = this.campaign.template.text_version;
      this.validation = false;
    },

    originalPreview() {
      this.creas_response.forEach((crea) => {
        if (crea.database_id === this.dbSelected) {
          this.html = crea.html_original;
          const nuevaVentana = window.open();
          nuevaVentana.document.title = "ORIGINAL PREVIEW";
          nuevaVentana.document.write(
            '<div style="background-color: #F85182; color: white; padding: 5px 10px 5px 10px; margin-bottom: 10px"><h3 style="text-align: center;">ORIGINAL PREVIEW</h3></div>'
          );
          nuevaVentana.document.write(this.html);
        }
      });
    },

    processedPreview() {
      this.creas_response.forEach((crea) => {
        if (crea.database_id === this.dbSelected) {
          this.html = crea.htmlFinal;
          const nuevaVentana = window.open();
          nuevaVentana.document.title = "PROCESSED PREVIEW";
          nuevaVentana.document.write(
            '<div style="background-color: #F85182; color: white; padding: 5px 10px 5px 10px; margin-bottom: 10px"><h3 style="text-align: center;">PROCESSED PREVIEW</h3></div>'
          );
          nuevaVentana.document.write(this.html);
        }
      });
    },

    validateBAT(id) {
      this.db = id;
      this.bat = true;
      this.sendout = this.sendouts_response;
      this.validation = true;
      this.batValidated = !this.batSended;
      this.creas_response.forEach((crea) => {
        if (crea.database_id === id) {
          this.html = crea.htmlFinal;
          this.dbCountry_id = crea.database.country_id;
          this.dbName = crea.database.name;
        }
      });
      this.text_version = this.campaign.template.text_version;
      this.campaignName = this.campaign.template.name;
    },

    confirmRefuse() {
      this.tags = this.tagsLocal;
      this.creas = this.creasLocal;
      this.template = this.templateLocal;
      this.loading = false;
      this.refuse = true;
    },

    getAmount(item, index) {
      this.loading = true;

      this.controller = new AbortController();
      ApiService.get(
        "campaign/getSegmentSize/" + this.dbSelected + "/" + item.Id,
        {
          signal: this.controller.signal,
        }
      )
        .then((response) => {
          this.loading = false;
          this.segments[this.dbSelected].segment[index].amount =
            response.data.subscribers;
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.loading = false;
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    cancelSegment() {
      this.controller.abort("Canceled");
    },

    /////////////////////////////////////////////////////////////////////////////////CREAR CAMPAÑA

    async createCampaign() {
      this.creasFinal = [];
      this.tagsIds = [];

      this.tagsLocal.forEach((tag) => {
        this.tagsIds.push({ tag_id: tag });
      });

      this.creasLocal.forEach((crea, index) => {
        this.creasLocal[index].idCrea = crea.id;
        this.creasLocal[index].kitHtml = this.kitHtml;
        /*  if (this.createModal === false) {
          
        } */
      });
      //CLONAMOS EL ARRAY PRINCIPAL DE CREAS PARA PODER MODIFICAR LOS DATOS Y NO LOS PONGA POR DEFECTO

      this.creasFinal = JSON.parse(JSON.stringify(this.creasLocal));
      this.creasFinal[0].database_id = 0;

      const formData = new FormData();

      /*       const arrayFiles = [];
       */
      this.creasLocal.forEach((crea, index) => {
        if (crea != null && crea.database_id != "") {
          /* arrayFiles[crea.database_id] = arrayFiles[crea.database_id] || []; // Inicializa el array si no existe
          for (let file of this.creasFinal[index].files) {
            arrayFiles[crea.database_id].push(file); // Almacena todos los archivos para un database_id particular
          } */

          if (crea.status === false) {
            this.creasFinal[index].status = "Draft";
          } else {
            this.creasFinal[index].status = null;
          }
        }
      });
      /*       console.log(this.creasFinal)
       */ /*       console.log(arrayFiles)
       */
      //QUITAMOS LA PRIMERA POSICIÓN DEL ARRAY QUE CORRESPONDE AL COMMOND VALUES
      this.creasFinal.splice(0, 1);

      const requestTemplate = {
        idTemplate: this.idTemplate,
        name: this.templateLocal.campaign_name,
        country_id: this.templateLocal.country,
        agency_id: this.templateLocal.agency,
        user_id_creator: this.$store.state.auth.user.id,
        user_id_designer: null,
        comment: this.templateLocal.comment,
        text_version: this.templateLocal.text_version,
        from_email: this.templateLocal.from_email,
        from_name: this.templateLocal.from_name,
        reply_email: this.templateLocal.reply_email,
        reply_name: this.templateLocal.reply_name,
      };

      //QUITAMOS AQUELLOS REGISTROS QUE NO TENGAN DATOS
      var creas_filtered = this.creasFinal.filter(function (el) {
        return el != null;
      });

      /*    var finalFiles = arrayFiles.filter(function (el) {
        return el != null;
      });

      // Append files individually
      Object.keys(finalFiles).forEach(key => {
          finalFiles[key].forEach((file, index) => {
            formData.append(`files[${key}][${index}]`, file);
          });
        }); */

      // additional data
      /*       formData.append("files", finalFiles);
       */ formData.append("requestTemplate", JSON.stringify(requestTemplate));
      formData.append("creas", JSON.stringify(creas_filtered));
      formData.append("payouts", JSON.stringify(this.paysLocal));
      formData.append("tags", JSON.stringify(this.tagsIds));
      formData.append("status", "Kit Ok");

      /*       console.log(creas_filtered)
       */ this.loading = true;
      this.loadingDraft = true;

      await ApiService.post("campaign/createCampaign", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          this.loading = false;
          this.loadingDraft = false;
          this.creas_response = [];
          this.sendouts = [];
          this.campaign = response.data;
          this.creas_response = response.data.creas;
          this.result = response.data.result;
          this.creas_response.forEach((crea, index) => {
            if (crea.status == null) {
              this.sendout.database_id = crea.database_id;
              this.sendout.from_email = crea.database.from_email;
              this.sendout.from_name = crea.database.from_name;
              Vue.set(
                this.sendouts,
                crea.database_id,
                Object.assign({}, this.sendout)
              );
              this.creasLocal[crea.database_id].idCrea = crea.id;
              this.sendouts[crea.database_id].crea_id = crea.id;
            }
          });
          //ASIGNAMOS EL ID DEL PRIMER ÍNDICE A LA VARIABLE DBSELECTED PARA QUE NO TENGA EN CUENTA EL 0 QUE ES DE COMMOND VALUES

          this.dbSelected = creas_filtered[0].database_id;
          this.segment.database_id = this.dbSelected;

          Vue.set(
            this.segments,
            this.dbSelected,
            Object.assign({}, { segment: [this.segment] })
          );
          this.segments[this.dbSelected].segment.forEach((seg) => {
            seg.database_id = this.dbSelected;
          });

          this.idTemplate = response.data.template.id;
          this.nextPage();
        })
        .catch((error) => {
          this.loading = false;
          this.loadingDraft = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.htmlNotEquals = error.idDatabases;
                this.loading = false;
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });

      this.segmentsList = [];
      ApiService.get("campaign/getListSegment/" + this.dbSelected)
        .then((response) => {
          this.segmentsList = response.data.segments;
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });

      this.headers = [];
      this.footers = [];
      ApiService.get("hf/listByDb/" + this.dbSelected)
        .then((response) => {
          let list = response.data.data;
          list.forEach((hf) => {
            if (hf.type === "header") {
              this.headers.push(hf);
            } else {
              this.footers.push(hf);
            }
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    /////////////////////////////////////////////////////////////////////////////////RECHAZAR CREA

    refuseBtn() {
      ApiService.patch("campaign/putRefusedCampaign/" + this.idTemplate, {
        comment_refuse: this.comment,
      }).then((response) => {
        this.$emit("close");
        this.$store.dispatch("getHTML");
        this.$store.dispatch("getSendouts");
        this.$store
          .dispatch("setSnackbar", {
            color: "success",
            text: "Campaign has been refused",
            timeout: 3000,
          })
          .catch((error) => {
            if (typeof error.response.data === "object") {
              Object.values(error.response.data)
                .flat()
                .forEach((error) => {
                  this.$store.dispatch("setSnackbar", {
                    color: "tvf_secondary",
                    text: error.message,
                    timeout: 3000,
                  });
                });
            }
          });
      });
    },

    closeModal() {
      this.$emit("close");
      this.$store.dispatch("getHTML");
      this.$store.dispatch("getSendouts");
    },

    /////////////////////////////////////////////////////////////////////////////////GUARDAR BORRADOR
    /////////////DONE
    async saveDraft() {
      this.creasFinal = [];
      this.tagsIds = [];

      this.tagsLocal.forEach((tag) => {
        this.tagsIds.push({ tag_id: tag });
      });

      this.creasLocal.forEach((crea, index) => {
        this.creasLocal[index].idCrea = crea.id;
      });

      //CLONAMOS EL ARRAY PRINCIPAL DE CREAS PARA PODER MODIFICAR LOS DATOS Y NO OS PONGA POR DEFECTO

      this.creasFinal = JSON.parse(JSON.stringify(this.creasLocal));
      this.creasFinal[0].database_id = 0;

      const formData = new FormData();

      /*       const arrayFiles = [];
       */

      this.creasLocal.forEach((crea, index) => {
        if (crea != null && crea.database_id != "") {
          /* arrayFiles[crea.database_id] = arrayFiles[crea.database_id] || []; // Inicializa el array si no existe
          for (let file of this.creasFinal[index].files) {
            arrayFiles[crea.database_id].push(file); // Almacena todos los archivos para un database_id particular
          } */

          if (crea.status === false || crea.status === true) {
            this.creasFinal[index].status = "Draft";
          }
        }
      });

      //QUITAMOS LA PRIMERA OPCIÓN

      this.creasFinal.splice(0, 1);

      const requestTemplate = {
        idTemplate: this.idTemplate,
        name: this.templateLocal.campaign_name,
        country_id: this.templateLocal.country,
        agency_id: this.templateLocal.agency,
        user_id_creator: this.$store.state.auth.user.id,
        user_id_designer: null,
        comment: this.templateLocal.comment,
        text_version: this.templateLocal.text_version,
        from_email: this.templateLocal.from_email,
        from_name: this.templateLocal.from_name,
        reply_email: this.templateLocal.reply_email,
        reply_name: this.templateLocal.reply_name,
      };

      var creas_filtered = this.creasFinal.filter(function (el) {
        return el != null;
      });
      console.log(creas_filtered)
      /*    var finalFiles = arrayFiles.filter(function (el) {
        return el != null;
      });

      // Append files individually
      Object.keys(finalFiles).forEach(key => {
          finalFiles[key].forEach((file, index) => {
            formData.append(`files[${key}][${index}]`, file);
          });
        }); */

      // additional data
      /*       formData.append("files", finalFiles);
       */ formData.append("requestTemplate", JSON.stringify(requestTemplate));
      formData.append("creas", JSON.stringify(creas_filtered));
      formData.append("payouts", JSON.stringify(this.paysLocal));
      formData.append("tags", JSON.stringify(this.tagsIds));
      formData.append("status", "Draft");

      this.loading = true;
      this.loadingDraft = true;
      await ApiService.post("campaign/createCampaign", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          this.loadingDraft = false;
          this.$emit("close");
          this.$store.dispatch("getHTML");
          this.$store.dispatch("getSendouts");
          this.creas_response = [];
          this.sendouts = [];
          this.campaign = response.data;
          this.creas_response = response.data.creas;
          this.creas_response.forEach((crea, index) => {
            if (crea.status == null) {
              this.creasLocal[crea.database_id].idCrea = crea.id;
            }
          });
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Saved as draft",
            timeout: 3000,
          });
        })
        .catch((error) => {
          this.loadingDraft = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    /////////////////////////////////////////////////////////////////////////////////GUARDAR BORRADOR CON SENDOUT

    async saveAndSchedule() {
      this.sendoutsFinal = [];
      let isValid = true; // Nueva variable para verificar si todos los send_date son válidos

      this.sendouts.forEach((send, indexSendout) => {
        const selectedSegments = {};
        this.segments.forEach((segSelected, indexSegment) => {
          if (indexSendout === indexSegment) {
            segSelected.segment.forEach((segment) => {
              Vue.set(selectedSegments, segment.id.Id, segment.id.Name);
            });
          }
        });
        this.sendouts[indexSendout].segmentation = selectedSegments;
      });

      this.sendouts.forEach((sendout, index) => {
        const timeStampMilisegundos = this.stringToTimestamp(sendout.send_date);

        if (sendout != null) {
          if (!sendout.send_date) {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: "Send date is required!",
              timeout: 3000,
            });
            isValid = false; // Si el send_date está vacío, establecer isValid en false
            return;
          }
          const sendoutForApi = Object.assign({}, sendout);
          sendoutForApi.send_date = Math.floor(timeStampMilisegundos / 1000);
          Vue.set(this.sendoutsFinal, index, sendoutForApi);
        }
      });

      var sendouts_filtered = this.sendoutsFinal.filter(function (el) {
        return el != null;
      });

      // Si isValid es falso, detener la ejecución de la función
      if (!isValid) {
        return;
      }

      this.loading = true;

      await ApiService.post("campaign/createSendouts", {
        sendouts: sendouts_filtered,
      })
        .then((response) => {
          this.loading = false;
          this.sendouts_response = [];
          this.idSendouts = [];
          var sendouts = response.data.sendouts;
          sendouts.forEach((sendout, index) => {
            Vue.set(
              this.sendouts_response,
              sendout.database_id,
              Object.assign({}, sendout)
            );
          });
          this.sendouts_response.forEach((sendout, index) => {
            Vue.set(
              this.segmentsFinal,
              sendout.database_id,
              Object.assign({}, JSON.parse(sendout.segmentation))
            );
          });
          this.nextPage();
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    async validateSendout() {
      this.idSendouts = [];
      this.sendouts_response.forEach((sendout, index) => {
        this.idSendouts.push(sendout.id);
      });

      await ApiService.post("campaign/validateSendouts", {
        idSendouts: this.idSendouts,
      })
        .then((response) => {
          this.$emit("close");
          this.$store.dispatch("getHTML");
          this.$store.dispatch("getSendouts");
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Sendout validated & scheduled",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    addPays() {
      this.paysLocal.push({});
    },

    removePays(index) {
      this.paysLocal.splice(index, 1);
    },

    addSegment() {
      if (!this.segments[this.dbSelected].segment) {
        this.$set(this.segments[this.dbSelected], "segment", [this.segment]);
      }
      this.segments[this.dbSelected].segment.push({});
    },

    removeSegment() {
      this.segments[this.dbSelected].segment.pop();
    },

    nextPage() {
      this.step = this.step + 1;
    },

    backPage() {
      this.segments.forEach((segs) => {
        segs.segment.forEach((seg) => {
          (seg.database_id = ""), (seg.id = ""), (seg.amount = "");
        });
      });
      this.step = this.step - 1;
      this.creasFinal = [];
    },

    getDatabases(item) {
      // Primero, crea una copia del arreglo original (databaseListAux)
      const newDatabaseList = this.databaseListAux.slice();
      // Luego, filtra la lista de bases de datos según el country_id seleccionado
      const filteredDatabaseList = newDatabaseList.filter(
        (database) => database.country_id === item
      );

      // Ahora, limpia el arreglo databaseList
      this.databaseList.splice(0, this.databaseList.length);

      // Finalmente, copia los elementos de filteredDatabaseList en databaseList
      filteredDatabaseList.forEach((database, index) => {
        Vue.set(this.databaseList, index, Object.assign({}, database));
      });
    },

    getCurrencies() {
      ApiService.get("currencies/list")
        .then((response) => {
          const currencies = response.data.data;
          this.currencies = currencies.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    getAgencies() {
      ApiService.get("agencies/list")
        .then((response) => {
          const agencies = response.data.data;
          this.agencies = agencies.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    getTags() {
      ApiService.get("tags/list")
        .then((response) => {
          const tags = response.data.data;
          this.tagsList = tags.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    getPaymodels() {
      ApiService.get("paymodels/list")
        .then((response) => {
          const paymodels = response.data.data;
          this.paymodels = paymodels.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    async getCountriesDB() {
      await ApiService.get(
        "campaign/getDBCountriesByUser/" + this.$store.state.auth.user.id
      )
      .then((response) => {
        const countries = response.data.countries;
        this.countries = countries.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        const databases = response.data.databases;
        this.databaseList = databases.sort(function (a, b) {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.databaseList.forEach((db, index) => {
          Vue.set(this.databaseListAux, index, Object.assign({}, db));
        });
      })
      .catch((error) => {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
          .flat()
          .forEach((error) => {
            this.$store.dispatch("setSnackbar", {
              color: "tvf_secondary",
              text: error.message,
              timeout: 3000,
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Circular Std";
  src: url("~@/assets/fonts/CircularStd-Light.otf"),
    url("~@/assets/fonts/CircularStd-Light Italic.otf");
}

@font-face {
  font-family: "Circular Medium";
  src: url("~@/assets/fonts/CircularStd-Medium.otf");
}

@font-face {
  font-family: "Circular Black";
  src: url("~@/assets/fonts/CircularStd-Black.otf"),
    url("~@/assets/fonts/CircularStd-BlackItalic.otf");
}

.section {
  padding: 0px 12px 25px 12px !important;
}
.section1 {
  min-height: 37% !important;
  background-color: #f8f9fb;
  border-radius: 5px;
}
.section1_1 {
  height: 28% !important;
  background-color: #f8f9fb;
  border-radius: 5px;
}

.section1 > label {
  font-family: "Circular Black";
}

.section2 {
  min-height: 33% !important;
  background-color: #f8f9fb;
  border-radius: 5px;
}

.section2_2 {
  min-height: 44% !important;
  max-height: 50% !important;
  background-color: #f8f9fb;
  border-radius: 5px;
}

.section3 {
  min-height: 17% !important;
  padding-top: 0.1px;
  background-color: #f8f9fb;
  border-radius: 5px;
}

.columnPreview {
  max-width: 27% !important;
}
.mx-input-wrapper .mx-input {
  background-color: #f8f9fb !important;
}

.scrollable-content {
  max-height: 300px; /* Establece la altura máxima deseada */
  overflow-y: auto; /* Añade un scroll vertical cuando el contenido exceda la altura */
}
.send {
  margin-top: 18%;
  margin-left: 20%;
}

[v-cloak] {
  display: none;
}

.schedule1 {
  padding: 12px 12px 0px 12px !important;
}

.v-application .addButton {
  caret-color: #f85182 !important;
  background-color: #f85182;
  font-size: 13px;
  height: 70px !important;
  width: 95%;
}

.functions1,
.functions2,
.functions3 {
  height: 40px !important;
}

.spacer-preview {
  height: 335px;
}
.spacer-preview2 {
  height: 375px;
}

.databases {
  width: 100%;
}

.databases1 {
  min-height: 370px;
  max-height: 370px;
  overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
  overflow-x: hidden; /* Esconde el desplazamiento horizontal */
}

.databases3 {
  min-height: 437px;
  max-height: 437px;
  overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
  overflow-x: hidden; /* Esconde el desplazamiento horizontal */
}

.databases4 {
  min-height: 415px;
  max-height: 415px;
  overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
  overflow-x: hidden;
}

.databases5 {
  min-height: 75%;
  max-height: 75%;
  overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
  overflow-x: hidden;
}

.segments_section {
  min-height: 390px !important;
  max-height: 390px !important;
  overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
  overflow-x: hidden;
}

.draft_btn {
  width: 95%;
  max-height: 50px !important;
}

.db_btn {
  max-height: 30px !important;
  width: 90%;
  text-transform: capitalize;
}

.db_btn2 {
  max-height: 30px !important;
  width: 100%;
  text-transform: capitalize;
}

.commond_btn {
  max-height: 30px !important;
  width: 100%;
  text-transform: capitalize;
}

.col-5 {
  max-width: 50%;
  flex: 50%;
}

.functions1,
.functions3 {
  text-transform: capitalize;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #f85182;
}

.addMore {
  background-color: #f85182;
}

.radiobuttons {
  padding: 12px 0px 12px 12px;
}

.num_split {
  padding: 0px 12px 12px 2px;
}

.stepCount {
  margin: auto !important;
  padding-top: 15px;
}

.addbuton .v-btn:not(.v-btn--round).v-size--x-large {
  height: 81px;
  min-width: 92px;
  padding: 0 23.1111111111px;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.custom-expansion-panel {
  max-width: 630px; /* Establece el ancho máximo deseado */
}
.steps {
  background-image: url("../../assets/fondo.png");
  background-size: 70em;
  background-repeat: no-repeat;
  background-position: 20% 75%;
  border-radius: 5px;
  height: 500px;
  width: 280px !important;
}

.campaign {
  font-family: "Circular Medium";
}
</style>