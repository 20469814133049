<template>
  <div>
    <v-card
      id="content"
      elevation="1"
    >
      <LoadingSpinner v-if="loading" />
      <v-data-table
        class="list px-10 pt-10"
        v-if="!loading"
        :headers="headers"
        :items="filteredTemplates"
        :items-per-page="itemsPerPage"
        :search="search"
        :page.sync="page"
        @page-count="pageCount = $event"
        show-expand
        single-expand
        @item-expanded="loadDetails"
        @update-expanded-table="updateExpandedTable"
      >
        <template v-slot:[`header.name`]="{ header }">
          {{ header.text }}
          <v-menu
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon
                  small
                  :color="nameFilter ? 'black' : ''"
                >mdi-magnify</v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="nameFilter"
                class="pa-2 caption"
                type="text"
                :autofocus="true"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <v-btn
                @click="nameFilter = ''"
                small
                text
                color="primary"
                class="ml-2 mb-2"
              >Clean</v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:[`header.agency.name`]="{ header }">
          {{ header.text }}
          <v-menu
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon
                  small
                  :color="agencyFilter ? 'black' : ''"
                >mdi-magnify</v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="agencyFilter"
                class="pa-2 caption"
                type="text"
                :autofocus="true"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <v-btn
                @click="agencyFilter = ''"
                small
                text
                color="primary"
                class="ml-2 mb-2"
              >Clean</v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:[`header.country.acronym`]="{ header }">
          {{ header.text }}
          <v-menu
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon
                  small
                  :color="agencyFilter ? 'black' : ''"
                >mdi-magnify</v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="countryFilter"
                class="pa-2 caption"
                type="text"
                :autofocus="true"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <v-btn
                @click="countryFilter = ''"
                small
                text
                color="primary"
                class="ml-2 mb-2"
              >Clean</v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:[`header.tags`]="{ header }">
          {{ header.text }}
          <v-menu
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon
                  small
                  :color="tagFilter ? 'black' : ''"
                >mdi-magnify</v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="tagFilter"
                class="pa-2 caption"
                type="text"
                :autofocus="true"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <v-btn
                @click="tagFilter = ''"
                small
                text
                color="primary"
                class="ml-2 mb-2"
              >Clean</v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand }">
          <v-icon
            :class="[
              'v-data-table__expand-icon',
              { 'v-data-table__expand-icon--active': isExpanded },
            ]"
            @click.stop="expand(!isExpanded)"
          >$expand</v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <v-btn
            class="caption white--text mt-2 ml-2"
            color="tvf_color"
            small
            @click="addNewDomain(item)"
          >
            <v-icon
              small
            >
              mdi-plus
            </v-icon>
            domain
          </v-btn>
          <td :colspan="headers.length">
            <v-simple-table ref="expandibleComponent" class="py-8 pr-3">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left font-weight-black text-uppercase">
                      DOMAIN
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      STATUS
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      SCHEDULE DATE
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      AMOUNT
                    </th>
                    <th class="text-left font-weight-black text-uppercase">
                      TIMES SENT
                    </th>
                    <th class="text-left font-weight-black text-uppercase">

                    </th>
                    <th class="text-center font-weight-black text-uppercase">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="info in tableData"
                    :key="info.id"
                  >
                    <td width="25%">
                      {{ info.name }}
                    </td>
                    <td width="10%">
                      <v-chip
                        v-if="info.creas[0].status !== null"
                        class="status black--text font-weight-bold px-2"
                        :color="info.creas[0].status.name==='In Progress'?'#FBBA3C':info.creas[0].status.name==='Draft'?'#A3A3A3':info.creas[0].status.name==='Kit Ok'?'#3CFBB6':'rgba(255, 0, 91, 0.6)'"
                        depressed
                        small
                      >
                        {{ info.creas[0].status.name }}
                      </v-chip>
                    </td>
                    <td>
                      {{ info.creas[0].schedule_date }}
                    </td>
                    <td>
                      {{ info.creas[0].amount | formatNumber }}
                    </td>
                    <td>
                      {{ info.creas[0].time_sent }}
                    </td>
                    <div ref="htmlCode"></div>
                    <td class="text-right">
                      <v-btn
                        icon
                        color="black"
                      >
                        <v-icon
                          v-model="item.actions"
                          @click="copyToClipboard(info)"
                          title="Copy HTML code to clipboard"
                        >
                          mdi-paperclip
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="black"
                      >
                        <v-icon
                          v-model="item.actions"
                          @click="openCreaCode(info)"
                          title="Check original/processed HTML code"
                        >
                          mdi-magnify
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="black"
                      >
                        <v-icon
                          v-model="item.actions"
                          @click="originalPreview(info)"
                          title="See original preview"
                        >
                          mdi-file-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="black"
                      >
                        <v-icon
                          v-model="item.actions"
                          @click="processedPreview(info)"
                          title="See processed preview"
                        >
                          mdi-file-code-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="black"
                      >
                        <v-icon
                          v-model="item.actions"
                          :color="info.creas[0].blacklist === 0 ?'black':'red'"
                          @click="showLinks(info)"
                          title="Check links, tracking pixels and added variables"
                        >
                          mdi-link
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="black"
                      >
                        <v-icon
                          v-model="item.actions"
                          @click="modalBat(info)"
                          title="Send BAT"
                        >
                          mdi-email-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="black"
                        v-if="template_preview.status.name === 'Kit Ok'"
                      >
                        <v-icon
                          v-model="item.actions"
                          @click="scheduleCrea(info)"
                          title="Schedule creativity"                          
                        >
                          mdi-calendar-range-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="black"                        
                      >
                        <v-icon
                          v-model="item.actions"
                          @click="openCreaConfirmation(info)"
                          title="Delete this domain from this creativity"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            v-if="item.status"
            class="status black--text px-2"
            :color="item.status.name==='In Progress'?'#FBBA3C':item.status.name==='Draft'?'#A3A3A3':item.status.name==='Refused'?'rgba(255, 0, 91, 0.6)':'#3CFBB6'"
            depressed
            small
          >
            {{ item.status.name }}
          </v-chip>
        </template>
        <template v-slot:[`item.priority`]="{ item }">
          <v-chip
            v-if="item.priority"
            class="status black--text font-weight-bold px-2"
            :color="item.priority ==='Fast'?'rgba(255, 0, 91, 0.6)':item.priority==='Medium'?'#FBBA3C':item.priority==='Draft'?'#A3A3A3':'#3CFBB6'"
            depressed
            small
          >
            {{ item.priority }}
          </v-chip>
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          {{ item.tags.map(t => t.name).join(', ') }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            small
            @click="openModalCode(item)"
          >
            <v-icon
              color="black"
              v-model="item.actions"
              title="Check original/processed HTML code"
            >
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            v-can="edit_code"
            v-if="item.status.name !== 'Draft' "
            @click="openModalDesigner(item)"
          >
            <v-icon
              color="black"
              v-model="item.actions"
              title="Edit processed HTML code"
            >
              mdi-code-tags
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click="editTemplate(item)"
            title="Edit template/creativity"
          >
            <v-icon
              color="black"
              v-model="item.actions"
            >
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
          <v-btn
            v-if="item.status.name === 'Kit Ok' || item.status.name === 'Draft'"
            icon
            small
            @click="refuseCampaign(item)"
          >
            <v-icon
              color="black"
              v-model="item.actions"
              title="Refuse creativity"
            >
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            small
            @click="campaignDone(item)"
          >
            <v-icon
              color="black"
              v-model="item.actions"
              title="Mark creativity as done"
            >
              mdi-check
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click="duplicate(item)"
          >
            <v-icon
              color="black"
              v-model="item.actions"
              title="Duplicate this creativity"
            >
              mdi-content-copy
            </v-icon>
          </v-btn>
          <v-btn
            icon
            color="black"
          >
            <v-icon
              v-model="item.actions"
              @click="showPays(item)"
              title="Check payouts and paymodels"
            >
              mdi-information-outline
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click="scheduleCampaign(item)"
            v-if="item.status.name == 'Kit Ok'"
          >
            <v-icon
              color="black"
              v-model="item.actions"
              title="Schedule creativity"
            >
              mdi-calendar-range-outline
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click="openConfirmation(item)"
          >
            <v-icon
              color="black"
              v-model="item.actions"
              title="Delete this creativity"
            >
              mdi-delete-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog
        v-model="viewCode"
        :value="true"
        persistent
        @click:outside="viewCode = false"
        max-width="750px"
      >
        <v-card>
          <v-form
            v-model="isFormValid"
            @submit.prevent="saveDesignerChanges"
          >
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="d-flex justify-center py-4">
                    <span class="headline">{{ template_name }}</span>
                  </v-row>
                  <v-row class="px-4 py-4">
                    <v-col
                      v-if="!designerMode"
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <label class="caption font-weight-bold">Subject</label>
                      <v-text-field
                        class="caption"
                        v-model="subject"
                        background-color="#F8F9FB"
                        dense
                        readonly
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="!designerMode"
                      cols="6"
                      sm="6"
                      md="6"
                    >
                      <label class="caption font-weight-bold">Pre-header</label>
                      <v-text-field
                        class="caption"
                        v-model="pre_header"
                        background-color="#F8F9FB"
                        dense
                        readonly
                        solo
                        flat
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label class="caption font-weight-bold">HTML Processed</label>
                      <v-textarea
                        v-if="designerMode"
                        class="caption font-weight-regular"
                        v-model="html_processed"
                        background-color="#F8F9FB"
                        dense
                        solo
                        flat
                        rows="4"
                      ></v-textarea>
                      <v-textarea
                        v-else
                        class="caption font-weight-regular"
                        v-model="html_processed"
                        background-color="#F8F9FB"
                        readonly
                        dense
                        solo
                        flat
                        rows="4"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label class="caption font-weight-bold">HTML Original</label>
                      <v-textarea
                        class="caption font-weight-regular"
                        v-model="html_original"
                        background-color="#F8F9FB"
                        readonly
                        dense
                        solo
                        flat
                        rows="4"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label class="caption font-weight-bold">Text</label>
                      <v-textarea
                        class="caption font-weight-regular"
                        v-model="text_version"
                        background-color="#F8F9FB"
                        readonly
                        dense
                        solo
                        flat
                        rows="3"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label class="caption font-weight-bold">Comment</label>
                      <v-textarea
                        class="caption font-weight-regular"
                        v-model="comment"
                        background-color="#F8F9FB"
                        readonly
                        dense
                        solo
                        flat
                        rows="3"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-btn
                        v-if="designerMode"
                        class="caption my-3 white--text"
                        color="tvf_color"
                        block
                        large
                        :disabled='!isFormValid'
                        type="submit"
                      >
                        Save Changes
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="modal_links"
        :value="true"
        persistent
        @click:outside="modal_links = false"
        max-width="750px"
      >
        <v-card>
          <v-form v-model="isFormValid">
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="d-flex justify-center py-4">
                    <span class="headline">{{ nameCrea }}</span>
                  </v-row>
                  <v-col
                    class="pt-6"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <label class="subtitle-1 font-weight-medium">LINK(s)</label>
                  </v-col>
                  <v-row class="px-4 py-4">
                    <v-col>
                      <div
                        v-for="(link, index) in links"
                        :key="index"
                      >
                        <div v-if="index === 'added_vars'">
                          <v-col
                            class="mb-4"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <label class="subtitle-2 font-weight-medium">VARIABLES: {{ link }}</label>
                          </v-col>
                        </div>
                        <div v-else>
                          <label>{{ index }}</label>
                          <div v-if="link.blacklist">
                            <v-col
                              class="mb-4"
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-btn
                                class="caption my-3 white--text"
                                color="red"
                                large
                                @click="showBlacklist(link)"
                              >
                                <v-icon
                                  class="mr-2"
                                  color="white"
                                >
                                  mdi-alert
                                </v-icon>
                                BLACKLIST
                              </v-btn>
                            </v-col>
                          </div>
                          <v-text-field
                            class="caption mt-2"
                            v-model="link.href_original"
                            placeholder="Original link"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                          <v-text-field
                            class="caption"
                            v-model="link.href_final"
                            placeholder="Shorted link"
                            background-color="#F8F9FB"
                            dense
                            solo
                            flat
                          ></v-text-field>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      class="pt-6"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label class="subtitle-1 font-weight-medium">TRACKING PIXEL(s)</label>
                    </v-col>
                    <v-col>
                      <div
                        v-for="(pixel, index) in pixels"
                        :key="index"
                      >
                        <label>{{ index }}</label>
                        <div v-if="pixel.blacklist">
                          <v-col
                            class="mb-4"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-btn
                              class="caption my-3 white--text"
                              color="red"
                              large
                              @click="showBlacklist(pixel)"
                            >
                              <v-icon
                                class="mr-2"
                                color="white"
                              >
                                mdi-alert
                              </v-icon>
                              BLACKLIST
                            </v-btn>
                          </v-col>
                        </div>
                        <v-text-field
                          class="key caption"
                          v-model="pixel.src_original"
                          placeholder="Code"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                        ></v-text-field>
                        <v-text-field
                          class="caption"
                          v-model="pixel.src_final"
                          placeholder="Original link"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="payout_paymodel"
        :value="true"
        persistent
        @click:outside="payout_paymodel = false"
        max-width="750px"
      >
        <v-card>
          <v-data-table
            class="px-10 py-6"
            v-if="!loading"
            :headers="pays_headers"
            :items-per-page="itemsPerPage1"
            :items="paymodels"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-select
                v-if="item.editing"
                class="caption mt-5"
                :items="paymodelList"
                item-value="id"
                item-text="name"
                v-model="item.id"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-select>
              <span v-else>{{ item.name }}</span>
            </template>
            <template v-slot:[`item.paymodel_template.payout`]="{ item }">
              <v-text-field
                v-if="item.editing"
                type="number"
                step=".01"
                class="caption mt-5"
                v-model="item.paymodel_template.payout"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <v-select
                v-if="item.editing"
                v-model="item.paymodel_template.currency_id"
                class="select-input caption"
                background-color="#F8F9FB"
                :items="currencies"
                item-value="id"
                item-text="symbol"
                dense
                solo
                flat
              ></v-select>
              <span v-else>{{ item.paymodel_template.payout | formatDecimal }} {{ currencies.find(currency => currency.id === item.paymodel_template.currency_id).symbol }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="!item.editing"
                small
                class="mr-2"
                @click="startEdit(item)"
              >mdi-pencil</v-icon>
              <v-icon
                v-if="!item.editing"
                small
                class="ml-2"
                @click="deleteRecord(item)"
              >mdi-delete</v-icon>
              <v-icon
                v-if="item.editing"
                small
                class="mr-2"
                @click="saveEdit(item)"
              >mdi-content-save</v-icon>
              <v-icon
                small
                class="ml-2"
                @click="cancelEdit(item)"
                v-if="item.editing"
              >mdi-close</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="blacklistModal"
        :value="true"
        persistent
        @click:outside="blacklistModal = false"
        max-width="500px"
      >
        <v-card class="pa-8">
          <table>
            <tbody>
              <tr
                v-for="(value, key, index) in blackList_info"
                :key="index"
              >
                <td class="py-2">{{ key }}</td>
                <td>{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmation"
        :value="true"
        persistent
        @click:outside="confirmation = false"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-col>
              <v-row class="pa-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>{{ name }}</h4>
                  <p
                    v-if="creaModal"
                    class="body-2 mt-4"
                  >Are you sure you want to delete this domain?</p>
                  <p
                    v-else
                    class="body-2 mt-4"
                  >Are you sure you want to delete this template?</p>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4">
                <v-col
                  class="align-center"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-btn
                    v-if="creaModal"
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="deleteCrea()"
                  >
                    Delete
                  </v-btn>
                  <v-btn
                    v-else
                    class="addButton pa-6 mr-8 white--text"
                    block
                    color="tvf_color"
                    @click="deleteTemplate()"
                  >
                    Delete
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
    </v-card>
    <div class="pt-4 pl-4">
      <v-pagination
        v-if="this.$store.state.list.length > 0"
        v-model="page"
        :length="pageCount"
        color="#f5f5f5"
        class="primary--text"
      ></v-pagination>
    </div>
    <edit-campaign
      v-if="updateCampaign"
      @close="updateCampaign = false"
      :template="template"
      :tags="tags"
      :pays="pays"
      :templateID="templateID"
      :creas="creas"
      :databases="databases"
      :stepCampaign="step"
      :selectedDb="dbSelected"
      :createModal="createModal"
      :sendoutsLocal="sendoutsLocal"
    />
    <schedule-campaign
      v-if="scheduleModal"
      @close="scheduleModal = false"
      :template="template"
      :tags="tags"
      :pays="pays"
      :templateID="templateID"
      :creas="creasFinal"
      :creasOrder="creas"
      :databases="databases"
      :stepCampaign="step"
      :selectedDb="dbSelected"
      :headers="headersList"
      :footers="footersList"
      :createModal="createModal"
      :sendoutsLocal="sendoutsLocal"
      :segmentsList="segmentsList"
      :segmentsInputs="segments"
    />
    <show-preview
      v-if="preview"
      @close="preview = false"
      :html="html"
    ></show-preview>
    <send-bat
      v-if="bat"
      @close="bat = false"
      :crea="crea"
      :html="html"
      :dbId="dbId"
      :text_version="text_version"
      :dbCountry="dbCountry_id"
      :validation="false"
      :sender="sender"
    ></send-bat>
    <refuse-crea
      v-if="refuse"
      @close="refuse = false"
      :refuseTemplate="refuseTemplate"
      :id="id"
    ></refuse-crea>
    <add-domain
      v-if="domainModal"
      :template="template"
      :item="item"
      @add-item="addItem"
      @close="domainModal = false"
      @new-record-added="addNewRecord"
    ></add-domain>
  </div>
</template>

<script>
import Vue from "vue";
import ApiService from "../services/api.service";
import LoadingSpinner from "../components/core/LoadingSpinner";
import ShowPreview from "../components/campaign/htmlPreview";
import SendBat from "@/components/campaign/SendBat";
import RefuseCrea from "@/components/campaign/RefuseCrea";
import EditCampaign from "../components/campaign/newCampaign.vue";
import ScheduleCampaign from "../components/campaign/scheduleCampaign.vue";
import AddDomain from "../components/campaign/addNewDomain.vue";
import { mapState } from 'vuex';

export default {
  props: ["search"],
  name: "List",
  components: {
    LoadingSpinner,
    ShowPreview,
    SendBat,
    RefuseCrea,
    EditCampaign,
    ScheduleCampaign,
    AddDomain
  },
  data() {
    return {
      edit_code: '',
      step: 0,
      page: 1,
      pageCount: 0,
      dbSelected: 0,
      page1: 1,
      pageCount1: 1,
      itemsPerPage: 10,
      itemsPerPage1: 5,
      editUser: false,
      loading: false,
      updateCampaign: false,
      scheduleModal: false,
      designerMode: false,
      blacklistModal: false,
      isFormValid: false,
      viewCode: false,
      preview: false,
      bat: false,
      modal_links: false,
      domainModal: false,
      refuse: false,
      ///ESTRUCTURA BÁSICA CAMPAIGNS
      idTemplate: null,
      idPaymodel: null,
      idTemplatePaymodel: null,
      item: '',
      template: {
        campaign_name: "",
        agency: "",
        country: "",
        creator_id: this.$store.state.auth.user.id,
        designer_id: null,
        geo_filter: "",
        text_version: "",
        comment: "",
        credits: 1,
        from_email: '',
        from_name: '',
        reply_email: '',
        reply_name: ''
      },
      tags: [],
      pays: [],
      creas: [],
      creasFinal: [],
      databases: [],
      sendoutsLocal: [],
      tableData: [],
      crea: {
        idCrea: null,
        database_id: "",
        template_id: "",
        html_original: "",
        subject: "",
        variables: "",
        preheader: "",
        status: false,
      },
      sendout: {
        identifier: null,
        user_id: this.$store.state.auth.user.id,
        database_id: "",
        crea_id: "",
        segmentation: {},
        send_date: '',
        limit: null,
        throttle: 24,
        header: "",
        footer: "",
        comment: "",
        type: "sendout",
        status: "Pending validation",
      },
      /// FIN ESTRUCTURA BÁSICA CAMPAIGNS
      dbCountry_id: "",
      templateID: null,
      name: "",
      itemId: "",
      id: "",
      date: "",
      subject: "",
      pre_header: "",
      html: "",
      html_original: "",
      html_processed: "",
      text_version: "",
      template_preview: "",
      template_name: "",
      comment: "",
      nameCrea: "",
      variables: "",
      blackList_info: [],
      segmentsList: [],
      segments: [],
      segment: {
        database_id: "",
        id: "",
        amount: "",
      },
      links: [],
      pixels: [],
      info: '',
      domains: [],
      editInput: -1,
      editInput: [],
      databasesId: [],
      headersList: [],
      footersList: [],
      paymodels: [],
      blackList_creas: [],
      paymodelList: [],
      nameFilter: "",
      agencyFilter: "",
      countryFilter: "",
      tagFilter: "",
      role: "",
      singleExpand: false,
      refuseTemplate: false,
      payout_paymodel: false,
      createModal: false,
      confirmation: false,
      creaModal: false,
      status_crea: "",
      pays_headers: [
        {
          text: "PAYMODEL",
          align: "start",
          value: "name",
          sortable: true,
          width: "5%",
        },
        {
          text: "PAYOUT",
          align: "start",
          value: "paymodel_template.payout",
          sortable: true,
          width: "5%",
        },
        {
          text: "TRACKING LINK",
          align: "start",
          value: "tracking",
          sortable: true,
          width: "10%",
        },
        {
          text: "ACTIONS",
          align: "start",
          value: "actions",
          sortable: true,
          width: "6%",
        },
      ],
      headers: [
        {
          text: "STATUS",
          align: "start",
          value: "status",
          sortable: true,
        },
        {
          text: "NAME CAMPAIGN",
          align: "start",
          value: "name",
          sortable: true,
          width: "15%",
        },
        {
          text: "AGENCY",
          align: "start",
          value: "agency.name",
          sortable: true,
          width: "12%",
        },
        {
          text: "USER",
          align: "start",
          value: "creator.name",
          sortable: true,
          width: "5%",
        },
        {
          text: "DESIGNER",
          align: "start",
          value: "designer.name",
          sortable: true,
          width: "5%",
        },
        {
          text: "COUNTRY",
          align: "start",
          value: "country.acronym",
          sortable: true,
          width: "10%",
        },
        {
          text: "TAGS",
          align: "start",
          value: "tags",
          sortable: true,
          width: "7%",
        },
        {
          text: "TIMES SENT",
          align: "start",
          value: "times_sent",
          sortable: true,
          width: "5%",
        },
        {
          text: "DATE",
          align: "start",
          value: "displayDate",
          sortable: true,
          width: "10%",
        },
        {
          text: "ACTIONS",
          align: "start",
          value: "actions",
          sortable: false,
          width: "18%",
        },
      ],
    };
  },

  filters: {
    formatDecimal(value) {
      return (
        value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    },
  },

  computed: {

    ...mapState([
      'list', // esto mapeará this.list a this.$store.state.list
    ]),

    filteredTemplates() {
      return this.list
        .filter((template) => this.filteredAgency(template))
        .filter((template) => this.filteredName(template))
        .filter((template) => this.filteredCountry(template))
        .filter((template) => this.filteredTag(template))
        .map((row) => {
          const displayDate =
            row.status.name === "Refused" && row.refused_at
              ? row.refused_at
              : row.created_at_format;

          return {
            ...row,
            displayDate,
          };
        });
    },
    
  },

  mounted() {
    this.$store.dispatch('getHTML') // aquí debes usar dispatch para llamar a la acción, no commit
    this.getPaymodels();   
    this.$store.commit('getCurrencies') 
  },

  methods: {
    startEdit(item) {
      const index = this.paymodels.indexOf(item);
      
      // Almacenamos el valor original en una propiedad adicional
      Vue.set(this.paymodels[index], "originalName", item.name);
      Vue.set(this.paymodels[index], "originalPayout", item.paymodel_template.payout);
      Vue.set(this.paymodels[index], "paymodel_template.currency_id", item.originalCurrencyId);
      Vue.set(this.paymodels[index], "paymodel_template.paymodel_id", item.id);
      Vue.set(this.paymodels[index], "editing", true); 

      this.idTemplate = item.paymodel_template.template_id,
      this.idTemplatePaymodel = item.paymodel_template.id
    },

    cancelEdit(item) {
      const index = this.paymodels.indexOf(item);

      // Revierte el nombre y el payout a sus valores originales
      Vue.set(this.paymodels[index], "name", item.originalName);
      Vue.set(this.paymodels[index], "paymodel_template.payout", item.originalPayout);
      Vue.set(this.paymodels[index], "paymodel_template.currency_id", item.originalCurrencyId);
      Vue.set(this.paymodels[index], "paymodel_template.paymodel_id", item.id);
      Vue.set(this.paymodels[index], "editing", false);

      // Si el nombre y el payout están vacíos, elimina el registro
      if (item.name === "" && item.paymodel_template.payout === "") {
        this.paymodels.splice(index, 1);
      }
    },

    deleteRecord(item) {
      const index = this.paymodels.indexOf(item);
      this.paymodels.splice(index, 1); // elimina el registro de la lista
    },

    async saveEdit(item) {
      const index = this.paymodels.indexOf(item);

      Vue.set(this.paymodels[index], "id", item.id);
      Vue.set(this.paymodels[index], "name", this.paymodelList.find((x) => x.id === item.id).name);

      // No sobrescribimos paymodel_template completamente, solo actualizamos el payout y el currency_id
      Vue.set(this.paymodels[index], "paymodel_template.payout", item.paymodel_template.payout);
      Vue.set(this.paymodels[index], "paymodel_template.currency_id", item.paymodel_template.currency_id);
      Vue.set(this.paymodels[index], "paymodel_template.paymodel_id", item.id);

      Vue.set(this.paymodels[index], "editing", false);   

      await ApiService.post("templates/assignPaymodel", {
        idTemplate: this.idTemplate,
        idPaymodel: item.id,
        payout: item.paymodel_template.payout,
        idTemplatePaymodel: this.idTemplatePaymodel,
        idCurrency: item.paymodel_template.currency_id
      })
      .then((response) => {
        this.$store.dispatch('getHTML')
        this.$store.dispatch('setSnackbar', {
          color: 'success',
          text: 'Paymodel/payout has been updated!',
          timeout: 3000
        })
      })
      .catch((error) => {
        if (typeof error.response.data === 'object') {

          Object.values(error.response.data.errors).flat().forEach( error => {
            console.log(error)              
            this.$store.dispatch('setSnackbar', {
              color: 'tvf_secondary',
              text: error,
              timeout: 3000
            })       
          })
        }
      });

      // Si el nombre y el payout están vacíos, elimina el registro
      if (
        this.paymodels[index].name === "" &&
        this.paymodels[index].paymodel_template.payout === ""
      ) {
        this.paymodels.splice(index, 1);
      }
    },

    addNewRecord() {
      const newItem = {
        name: "",
        paymodel_template: {
          payout: "",
        },
        editing: true, // nuevo registro en modo de edición
      };
      this.paymodels.unshift(newItem); // añade el nuevo registro al principio de la lista
      this.tableData.push(newItem);
    },

    filteredAgency(template) {
      return template.agency.name
        .toLowerCase()
        .includes(this.agencyFilter.toLowerCase());
    },

    filteredName(template) {
      return template.name
        .toLowerCase()
        .includes(this.nameFilter.toLowerCase());
    },

    filteredCountry(template) {
      return template.country.acronym
        .toLowerCase()
        .includes(this.countryFilter.toLowerCase());
    },

    filteredTag(template) {
      if (!this.tagFilter) {
        return true;
      }

      const lowerCaseTagFilter = this.tagFilter.toLowerCase();

      // Asumiendo que `tags` es un array de objetos con una propiedad `name`
      return template.tags.some((tag) => {
        if (typeof tag.name === "string") {
          return tag.name.toLowerCase().includes(lowerCaseTagFilter);
        }
        return false;
      });
    },

    async copyToClipboard(info) {
      this.info = info.creas[0];

        ApiService.get(
        "campaign/getFinalHtml/" + this.info.template_id + "/" + this.info.id + "/" + 'false'+ "/" + 'true'
      )
        .then((response) => {
          navigator.clipboard.writeText(response.data.htmlFinal);
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Copied!",
            timeout: 3000,
          });
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
            .flat()
            .forEach((error) => {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: "Cannot copy",
                timeout: 3000,
              });
            });
          }
        });
    },

    duplicate(item) {
      this.loading = true;
      ApiService.post("campaign/duplicateCampaign", {
        idTemplate: item.id,
      })
        .then((response) => {
          this.loading = false;
          this.$store.dispatch('getHTML')
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: item.name + " duplicated!",
            timeout: 3000,
          });
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    openCreaCode(info) {
      this.html_processed = "";
      this.info = info.creas[0].sendouts;
      this.data = info.creas[0];
      this.text_version = this.template_preview.text_version;
      this.subject = this.data.subject;
      this.pre_header = this.data.preheader;
      this.html_original = this.data.html_original;
      this.comment = this.template_preview.comment;

      ApiService.get(
        "campaign/getFinalHtml/" + this.data.template_id + "/" + this.data.id + "/" + 'false'+ "/" + 'false'
      )
        .then((response) => {
          this.html_processed = response.data.htmlFinal;
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });

      this.viewCode = true;
    },

    saveDesignerChanges() {
      ApiService.put("templates/" + this.templateID, {
        user_id_designer: this.$store.state.auth.user.id,
        html_processed: this.html_processed,
      })
        .then((response) => {
          this.viewCode = false
          this.$store.dispatch('getHTML');
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Html processed updated!",
            timeout: 3000,
          });
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    /////////////////////////////////////////////////////////////////////////////////////SHOW PAYMODELS

    showPays(item) {
      this.currencies = this.$store.state.currencies
      this.paymodels = item.paymodels;
      this.payout_paymodel = true;
    },

    /////////////////////////////////////////////////////////////////////////////////////SHOW HTML CODE

    openModalCode(item) {
      this.viewCode = true;
      this.html_processed = item.html_processed;
      this.text_version = item.text_version;
      this.comment = item.comment;
      this.template_name = item.name;
      this.info = [];

      ApiService.get("creas/list/" + item.id)
        .then((response) => {
          this.info = response.data.data;
          this.html_original = this.info[0].html_original;
          this.subject = this.info[0].subject;
          this.pre_header = this.info[0].preheader;
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    openModalDesigner(item) {
      this.viewCode = true;
      this.designerMode = true;
      this.html_processed = item.html_processed;
      this.text_version = item.text_version;
      this.comment = item.comment_refuse;
      this.template_name = item.name;
      this.info = [];
      this.templateID = item.id;
      ApiService.get("creas/list/" + item.id).then((response) => {
        this.info = response.data.data;
        this.template_name = this.info[0].template.name;
        this.html_original = this.info[0].html_original;
        this.subject = this.info[0].subject;
        this.pre_header = this.info[0].preheader;
      });

      ApiService.patch("campaign/putInProgressCampaign/" + item.id + '/' + this.$store.state.auth.user.id )
        .then((response) => {
          this.$store.dispatch('getHTML')
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    /////////////////////////////////////////////////////////////////////////////////////PREVIEWS

    originalPreview(info) {
      this.info = info.creas;
      this.html = this.info[0].html_original;
      const nuevaVentana = window.open();
      nuevaVentana.document.title = "ORIGINAL PREVIEW";
      nuevaVentana.document.write(
        '<div style="background-color: #F85182; color: white; padding: 5px 10px 5px 10px; margin-bottom: 10px"><h3 style="text-align: center;">ORIGINAL PREVIEW</h3></div>'
      );
      nuevaVentana.document.write(this.html);
    },

    processedPreview(info) {
      this.html = "";
      this.data = info.creas[0];
      ApiService.get(
        "campaign/getFinalHtml/" + this.data.template_id + "/" + this.data.id + "/" + 'false'+ "/" + 'false'
      )
        .then((response) => {
          this.html = response.data.htmlFinal;
          const nuevaVentana = window.open();
          nuevaVentana.document.title = "PROCESSED PREVIEW";
          nuevaVentana.document.write(
            '<div style="background-color: #F85182; color: white; padding: 5px 10px 5px 10px; margin-bottom: 10px"><h3 style="text-align: center;">PROCESSED PREVIEW</h3></div>'
          );
          nuevaVentana.document.write(this.html);
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    /////////////////////////////////////////////////////////////////////////////////BAT

    modalBat(info) {
      this.crea = info.creas[0];
      if(info.creas[0].sendouts.length > 0) {
        this.html = info.creas[0].sendouts[0].final_html;
      } else {
        ApiService.get("campaign/getFinalHtml/" + this.crea.template_id + "/" + this.crea.id + "/" + 'true'+ "/" + 'false')
        .then((response) => {
          this.html = response.data.htmlFinal;
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
      }
      this.sender = info.from_name
      this.dbCountry_id = info.country_id;
      this.dbId = info.id;
      this.text_version = this.template_preview.text_version;
      this.bat = true;
    },

    /////////////////////////////////////////////////////////////////////////////////LINKS

    showLinks(info) {
      this.nameCrea = info.name;
      this.variables = "";
      this.links = [];
      this.pixels = [];
      this.variables = info.creas[0].variables;
      this.links = JSON.parse(info.creas[0].links);
      this.pixels = JSON.parse(info.creas[0].pixels);

      this.modal_links = true;
    },

    showBlacklist(info) {
      this.blackList_info = info.blacklist;
      this.blacklistModal = true;
    },

    //////////////////////////////////////////////////////////////////////////////////////EDIT TEMPLATE

    editTemplate(item) {
      this.editInput = [];
      this.pays = [];
      this.tags = [];
      this.step = 0;
      this.dbSelected = 0;
      this.databases = [];

      ApiService.get("campaign/getCampaign/" + item.id)
        .then((response) => {
          this.editInput = response.data.template;
          ///ESTRUCTURA BÁSICA CAMPAIGNS
          (this.templateID = this.editInput.id),
            (this.template = {
              campaign_name: this.editInput.name,
              agency: this.editInput.agency_id,
              country: this.editInput.country_id,
              creator_id: this.editInput.user_id_creator,
              designer_id: null,
              geo_filter: this.editInput.geo_filter,
              text_version: this.editInput.text_version,
              comment: this.editInput.comment,
              credits: this.editInput.credits,
            }),
            this.editInput.tags.forEach((tag) => {
              this.tags.push(tag.id);
            });

          this.editInput.paymodels.forEach((pay) => {
            this.pays.push({
              payout: pay.paymodel_template.payout,
              idCurrency: pay.paymodel_template.currency_id,
              idPaymodel: pay.id,
            });
          });
          this.editInput.creas.forEach((element) => {
            this.databases.push(element.database);
            element.status = false;
            Vue.set(
              this.creas,
              element.database.id,
              Object.assign({}, element)
            );
          });
          /// FIN ESTRUCTURA BÁSICA CAMPAIGNS
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
      this.createModal = false;
      this.updateCampaign = true;
    },

    //////////////////////////////////////////////////////////////////////////////////////PROGRAMAR CAMPAÑA
    async scheduleCampaign(item) {
      this.editInput = [];
      this.databases = [];
      this.pays = [];
      this.creas = [];
      this.sendoutsLocal = []
      this.headersList = []
      this.footersList = []
      this.step = 3;

      await ApiService.get("campaign/getCampaign/" + item.id).then(
        (response) => {
          this.editInput = response.data.template;

          ///ESTRUCTURA BÁSICA CAMPAIGNS
          this.templateID = this.editInput.id,
          this.template = {
            name: this.editInput.name,
            agency: this.editInput.agency_id,
            country: this.editInput.country_id,
            creator_id: this.editInput.user_id_creator,
            designer_id: null,
            geo_filter: this.editInput.geo_filter,
            text_version: this.editInput.text_version,
            comment: this.editInput.comment,
            credits: this.editInput.credits,
          },
          this.tags = this.editInput.tags,
          this.pays = this.editInput.paymodels,
          this.editInput.creas.forEach((element) => {
            this.databases.push(element.database);
            Vue.set(
              this.creas,
              element.database.id,
              Object.assign({}, element)
            );
            if (element.status == null) {
              this.sendout.database_id = element.database_id;
              this.sendout.from_email = element.database.from_email;
              this.sendout.from_name = element.database.from_name;
              Vue.set(
                this.sendoutsLocal,
                element.database_id,
                Object.assign({}, this.sendout)
              );
              this.sendoutsLocal[element.database_id].crea_id = element.id;
            }

            this.creasFinal = this.creas.filter(function (el) {
              return el != null;
            });
          });

          this.dbSelected = this.editInput.creas[0].database_id;
          this.segment.database_id = this.dbSelected;

          this.creas.forEach((crea) => {
            Vue.set(this.creas, this.dbSelected, Object.assign({}, crea));
          });

          Vue.set(
            this.segments,
            this.dbSelected,
            Object.assign({}, { segment: [this.segment] })
          );
          this.segments[this.dbSelected].segment.forEach((seg) => {
            seg.database_id = this.dbSelected;
          });

          if (!this.segments.hasOwnProperty(this.dbSelected)) {
            const newSegment = {
              ...this.segment,
              database_id: this.dbSelected,
            };
            Vue.set(this.segments, this.dbSelected, { segment: [newSegment] });
          }

          ApiService.get("campaign/getListSegment/" + this.dbSelected)
            .then((response) => {
              this.isLoading = false;
              this.segmentsList = response.data.segments;
            })
            .catch((error) => {
              this.isLoading = false;
              if (typeof error.response.data === "object") {
                Object.values(error.response.data)
                  .flat()
                  .forEach((error) => {
                    this.$store.dispatch("setSnackbar", {
                      color: "tvf_secondary",
                      text: error.message,
                      timeout: 3000,
                    });
                  });
              }
            });

          ApiService.get("hf/listByDb/" + this.dbSelected)
            .then((response) => {
              let list = response.data.data;
              list.forEach((hf) => {
                if (hf.type === "header") {
                  this.headersList.push(hf);
                } else {
                  this.footersList.push(hf);
                }
              });
            })
            .catch((error) => {
              if (typeof error.response.data === "object") {
                Object.values(error.response.data)
                  .flat()
                  .forEach((error) => {
                    this.$store.dispatch("setSnackbar", {
                      color: "tvf_secondary",
                      text: error.message,
                      timeout: 3000,
                    });
                  });
              }
            });

          this.scheduleModal = true;
        }
      );
    },

    async scheduleCrea(item) {
      this.editInput = [];
      this.databases = [];
      this.pays = [];
      this.creas = [];
      this.sendoutsLocal = [];
      this.headersList = []
      this.footersList = []
      this.step = 3;

      await ApiService.get("campaign/getCampaignByCrea/" + item.creas[0].id).then(
        (response) => {
          this.editInput = response.data.template[0];

          ///ESTRUCTURA BÁSICA CAMPAIGNS
          (this.templateID = this.editInput.id),
            (this.template = {
              name: this.editInput.name,
              agency: this.editInput.agency_id,
              country: this.editInput.country_id,
              creator_id: this.editInput.user_id_creator,
                  designer_id: null,
              geo_filter: this.editInput.geo_filter,
              text_version: this.editInput.text_version,
              comment: this.editInput.comment,
              credits: this.editInput.credits,
            }),
            (this.tags = this.editInput.tags),
            (this.pays = this.editInput.paymodels),
            this.editInput.creas.forEach((element) => {
              this.databases.push(element.database);

                Vue.set(
                  this.creas,
                element.database.id,
                    Object.assign({}, element)
                  );
              if (element.status == null) {
                    this.sendout.database_id = element.database_id;
                this.sendout.from_email = element.database.from_email;
                this.sendout.from_name = element.database.from_name;
                  Vue.set(
                    this.sendoutsLocal,
                    element.database_id,
                    Object.assign({}, this.sendout)
                  );
                  this.sendoutsLocal[element.database_id].crea_id = element.id;
                }

          this.creasFinal = this.creas.filter(function (el) {
            return el != null;
          });
            });

          this.dbSelected = this.editInput.creas[0].database_id;
          this.segment.database_id = this.dbSelected;

          this.creas.forEach((crea) => {
            Vue.set(this.creas, this.dbSelected, Object.assign({}, crea));
          });

          Vue.set(
            this.segments,
            this.dbSelected,
            Object.assign({}, { segment: [this.segment] })
          );
          this.segments[this.dbSelected].segment.forEach((seg) => {
            seg.database_id = this.dbSelected;
          });

          if (!this.segments.hasOwnProperty(this.dbSelected)) {
            const newSegment = {
              ...this.segment,
              database_id: this.dbSelected,
            };
            Vue.set(this.segments, this.dbSelected, { segment: [newSegment] });
          }

          ApiService.get("campaign/getListSegment/" + this.dbSelected)
            .then((response) => {
              this.isLoading = false;
              this.segmentsList = response.data.segments;
            })
            .catch((error) => {
              this.isLoading = false;
              if (typeof error.response.data === "object") {
                Object.values(error.response.data)
                  .flat()
                  .forEach((error) => {
                    this.$store.dispatch("setSnackbar", {
                      color: "tvf_secondary",
                      text: error.message,
                      timeout: 3000,
                    });
                  });
              }
            });
          
          ApiService.get("hf/listByDb/" + this.dbSelected)
            .then((response) => {
              let list = response.data.data;
              list.forEach((hf) => {
                if (hf.type === "header") {
                  this.headersList.push(hf);
                } else {
                  this.footersList.push(hf);
                }
              });
            })
            .catch((error) => {
              if (typeof error.response.data === "object") {
                Object.values(error.response.data)
                  .flat()
                  .forEach((error) => {
                    this.$store.dispatch("setSnackbar", {
                      color: "tvf_secondary",
                      text: error.message,
                      timeout: 3000,
                    });
                  });
              }
            });

          this.scheduleModal = true;
        }
      );
    },

    //////////////////////////////////////////////////////////////////////////////////////REFUSE

    refuseCampaign(item) {
      this.id = item.id;
      this.refuseTemplate = true;
      this.refuse = true;
    },

    campaignDone(item) {
      this.id = item.id;
      ApiService.patch("campaign/putKitOkCampaign/" + this.id)
        .then((response) => {
          this.$store.dispatch('getHTML')
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    openConfirmation(item) {
      this.name = item.name;
      this.itemId = item.id;
      this.confirmation = true;
    },

    openCreaConfirmation(info) {
      this.itemId = info.creas[0].id;
      this.name = info.name;
      this.creaModal = true;
      this.confirmation = true;
    },

    //////////////////////////////////////////////////////////////////////////////////////ELIMINAR TEMPLATE
    deleteTemplate() {
      ApiService.delete("templates/" + this.itemId)
        .then((response) => {
          this.$store.dispatch('getHTML')
          this.confirmation = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Template '" + this.name + "' has been deleted",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    deleteCrea() {
      ApiService.delete("creas/" + this.itemId)
        .then((response) => {
          // Busca el índice del item en tu lista
          const index = this.tableData.findIndex(info => info.creas[0].id === this.itemId);

          // Si el item existe en la lista, elimínalo
          if (index !== -1) {
            this.tableData.splice(index, 1);
          }

          this.confirmation = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Domain has been deleted of this creativity",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    addItem(newItem) {
      this.tableData.push(newItem);
      this.tableData = [...this.tableData]
    },

    addNewDomain(item){
      this.item = item
      this.template = this.template_preview      
      this.domainModal = true
    },

    getPaymodels() {
      ApiService.get("paymodels/list")
        .then((response) => {
          this.loading = false;
          this.paymodelList = response.data.data;
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    loadDetails({ item }) {
      this.tableData = [];
      ApiService.get("templates/getDomainsHtmlList/" + item.id)
        .then((response) => {
          this.tableData = response.data.databases;
          this.template_preview = response.data.template;
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },

    updateExpandedTable() {
      this.$refs.myExpandedTable.updateExpandedTable();
    },
  },
};
</script>

<style scoped>
.col-sm-12,
.col-12,
.col-md-12 {
  padding: 0px 10px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-right: 8px !important;
}

.keyCode {
  width: 30%;
}

.list .mdi-chevron-down::before {
  content: "\F419" !important;
  color: #000;
}

.v-chip.v-size--small {
  border-radius: 5px;
  font-size: 12px;
  height: 24px;
}

#content {
  border-radius: 5px !important;
}

.theme--light.v-pagination .v-pagination__item {
  background: gainsboro !important;
}

.theme--light.v-pagination .v-pagination__item--active {
  color: #000000 !important;
}

.v-data-table {
  max-width: 100%;
}
</style>