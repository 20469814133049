<template>
  <div>
    <v-row justify="center">
      <v-dialog
        class="pa-10"
        :value="true"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-icon
              class="mt-2"
              @click="closeModal()"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-data-table
            class="px-10 pb-10"
            :headers="headers"
            :items="paymodels"
            hide-default-footer
          >
            <template v-slot:[`item.payout`]="{ item }">
              {{ item.payout | formatDecimal }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="!item.editing"
                small
                class="mr-2"
                @click="startEdit(item)"
              >mdi-pencil</v-icon>
              <v-icon
                v-if="item.editing"
                small
                class="ml-2"
                @click="saveEdit(item)"
              >mdi-content-save</v-icon>
              <v-icon
                small
                class="ml-2"
                @click="cancelEdit(item)"
                v-if="item.editing"
              >mdi-close</v-icon>
            </template>

            <template v-slot:[`item.pivot.lead`]="{ item }">
              <v-text-field
                v-if="item.editing"
                class="caption mt-5"
                v-model="item.pivot.lead"
                background-color="#F8F9FB"
                dense
                solo
                flat
              ></v-text-field>
              <span v-else>{{ item.pivot.lead }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import Vue from "vue";

export default {
  props: ["paymodels", "idSendout"],
  data() {
    return {
      lead: "",
      headers: [
        { text: "PAYOUT", value: "payout", width: "20%" },
        { text: "PAYMODEL", value: "paymodel.name", width: "25%" },
        { text: "CONVERSIONS", value: "pivot.lead" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  filters: {
    formatDecimal(value) {
      return (
        value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "€"
      );
    },
  },

  methods: {
    startEdit(item) {
      const index = this.paymodels.indexOf(item);
      Vue.set(this.paymodels[index], "editing", true);
    },

    cancelEdit(item) {
      const index = this.paymodels.indexOf(item);
      Vue.set(this.paymodels[index], "editing", false);
    },

    closeModal(){
      this.$emit('close');
      this.$emit('updateList');
    },

    async saveEdit(item) {
      const index = this.paymodels.indexOf(item);
      Vue.set(this.paymodels[index], "editing", false);

      await ApiService.post("sendouts/addLead", {
        idPaymodelTemplateSendout: item.pivot.id,
        idSendout: this.idSendout,
        lead: item.pivot.lead
      })
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            color: "success",
            text: "Leads saved",
            timeout: 3000,
          });
        })
        .catch((error) => {
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
    },
  },
};
</script>