<template>
  <div>
    <v-row>
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="50%"
      >
        <v-card>
            <v-card-text>
              <v-container>
                <v-col>
                  <v-row class="d-flex justify-center pt-12">
                    <span class="headline font-weight-bold">{{ sendout.template.name }}</span><br/>
                  </v-row>
                  <v-row class="d-flex justify-center pb-6">
                    <span class="subtitle-1">{{ sendout.database.name }}</span>
                  </v-row>
                  <v-row class="px-6 pb-6">
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <label class="subtitle-2 font-weight-bold">Throttle</label><br/>
                      <span class="caption">{{ sendout.throttle }} h</span>  
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <label class="subtitle-2 font-weight-bold">From name</label><br/>
                      <span class="caption">{{ sendout.crea.from_name }}</span> 
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <label class="subtitle-2 font-weight-bold">Reply name</label><br/>
                      <span class="caption">{{ sendout.crea.reply_name }}</span> 
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <label class="subtitle-2 font-weight-bold">Sender domain</label><br/>
                      <span class="caption">{{ sendout.sender_domain }}</span>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <label class="subtitle-2 font-weight-bold">From email</label><br/>
                      <span class="caption">{{ sendout.crea.from_email }}</span>                      
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <label class="subtitle-2 font-weight-bold">Reply email</label><br/>
                      <span class="caption">{{ sendout.crea.reply_email }}</span>                      
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <label class="subtitle-2 font-weight-bold">Subject</label><br/>
                      <span class="caption">{{ sendout.final_subject }}</span>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <label class="subtitle-2 font-weight-bold">Tags</label><br/>
                      <div v-for="tags in sendout.template.tags" :key="tags.id">
                        <span class="caption">{{ tags.name }}</span>
                      </div>                      
                    </v-col>                   
                    <v-col
                      cols="4"
                      sm="4"
                      md="4"
                    >
                      <label class="subtitle-2 font-weight-bold">Segments</label><br/>
                      <div v-for="segment, index in JSON.parse(sendout.segmentation)" :key="index">
                        <v-textarea
                          v-if="JSON.parse(sendout.segmentation).length > 4"
                          class="caption font-weight-regular"
                          :value="segment"
                          background-color="#F8F9FB"
                          readonly
                          dense
                          solo
                          flat
                          rows="3"
                        ></v-textarea>
                        <span v-else class="caption">{{ segment }}</span>
                      </div>
                    </v-col>
                    <v-col
                      class="mt-4"
                      cols="12"
                      sm="12"
                      md="12"
                    >                   
                      <label class="subtitle-2 font-weight-bold pb-4">Preview html</label>
                      <div class="html_code" v-html="sendout.final_html"></div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <label class="subtitle-2 font-weight-bold">Html Code</label>
                      <v-textarea
                        class="caption font-weight-regular"
                        v-model="sendout.final_html"
                        background-color="#F8F9FB"
                        readonly
                        dense
                        solo
                        flat
                        rows="5"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>

export default {
  props: ["sendout"],
  data() {
    return {

    };
  },

  methods: {
 
  },
  
};

</script>
