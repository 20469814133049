<template>
    <div>
      <v-row justify="center">
        <v-dialog
          :value="true"
          persistent
          @click:outside="$emit('close')"
          max-width="500px"
        >
          <v-card>
            <v-form 
              @submit.prevent="sendBat"
              v-model="isFormValid"
            >
              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row class="px-4 py-8">
                      <v-col
                        v-if="!validation"
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <label class="caption font-weight-bold">Sender/From name</label>
                        <v-text-field
                          class="caption"
                          v-model="sender"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-if="validation"
                      >
                        <label class="caption font-weight-bold">Language</label>
                        <v-select
                          class="caption"                                              
                          v-model="languageSelected"
                          :items="optionsLanguages"
                          item-value="iso"
                          item-text="name"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                          required
                        >
                          <template v-slot:item="{ item }">
                            <div class="d-flex align-items-center">
                              <img
                                :src="`/flags/${item.iso.toLowerCase()}.png`"
                                width="25"
                                class="lang"
                              />
                              <span class="caption">{{ item.name }}</span>
                            </div>
                          </template>
                        </v-select>
                      </v-col>       
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-if="validation"
                      >
                        <label class="caption font-weight-bold">Subject</label>
                        <v-text-field
                          class="caption"
                          v-model="validationSubject"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                        ></v-text-field>
                      </v-col>               
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-else
                      >
                        <label class="caption font-weight-bold">Subject</label>
                        <v-text-field
                          class="caption"
                          v-model="subject"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <label class="caption font-weight-bold">Email</label>
                        <v-text-field
                          class="caption"
                          v-model="email"
                          background-color="#F8F9FB"
                          placeholder="mail@example.es, mail2@example.es"
                          dense
                          solo
                          flat
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-if="!validation"
                      >
                        <label class="caption font-weight-bold">Seed List</label>
                        <v-select
                          class="caption"
                          v-model="idSeed"
                          :items="seeds"
                          item-value="id"
                          item-text="name"
                          :menu-props="{ maxHeight: '400' }"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        v-if="validation"
                      >
                        <label class="caption font-weight-bold">Volume</label>
                        <v-text-field
                          class="caption"
                          type="number"
                          v-model="volume"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <label class="caption font-weight-bold">Comment</label>
                        <v-textarea
                          class="caption font-weight-regular"
                          v-model="comment"
                          background-color="#F8F9FB"
                          dense
                          solo
                          flat
                          rows="3"
                        ></v-textarea>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-btn 
                          class="caption mt-3 white--text"
                          color="tvf_color" 
                          block
                          large 
                          :loading="loading"
                          :disabled="loading"
                          type="submit"
                        >
                        Send Bat
                        </v-btn> 
                      </v-col>                                          
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </template>
  
  <script>
  import ApiService from "@/services/api.service";
  
  export default {
    props: ['crea', 'validation', 'html', 'sendout', 'campaign', 'db', 'dbCountry', 'text_version', 'dbId', 'sender', 'campaignName', 'dbName'],
    data() {
      return {
        email: '',
        volume: '',
        emailArray: [],
        subject: '[BAT] ' + this.crea.subject,
        validationSubject: this.validation === true ? 'Validation BAT - ' + this.campaignName + ' - ' + this.dbName : '',
        comment: '',
        validated: false,
        errors: [],        
        isFormValid: false,
        loading: false,
        idSendouts: [],
        seeds: [],
        idSeed: '',
        send_date: '',
        amount_sent: '',
        database: '',
        paymodels: [],
        languageSelected: '',
        optionsLanguages: [],
        emailRules: [
          (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email Address'
        ],
      };
    },

    mounted() {
      this.getSeedList();
      this.getLanguages();
    },
  
    methods: { 

      getSeedList() {
        ApiService.get("seeds/list/" + this.dbCountry)
        .then((response) => {
          this.seeds = response.data.data;
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
      },

      getLanguages() {
        ApiService.get("languages/list")
        .then((response) => {
          this.optionsLanguages = response.data.data;
        })
        .catch((error) => {
          this.loading = false;
          if (typeof error.response.data === "object") {
            Object.values(error.response.data)
              .flat()
              .forEach((error) => {
                this.$store.dispatch("setSnackbar", {
                  color: "tvf_secondary",
                  text: error.message,
                  timeout: 3000,
                });
              });
          }
        });
      },

      /*
* This function is used to send a BAT to the server.
*/
      async sendBat() {     

        if(this.validation === true) {
          this.sendout.forEach(send => {
          if(send.database_id === this.db) {
            this.loading = true;            
            ApiService.post("campaign/sendValidationBat", {
              idSendout: send.id,            
              email: this.email, 
              sender: this.sender,
              subject: this.validationSubject,
              comment: this.comment,
              volume: this.volume,
              iso: this.languageSelected 
            })
            .then((response) => {
              this.loading = false;
              this.$emit('close')
              this.$store.dispatch('setSnackbar', {
                color: 'success',
                text: 'BAT has been sent',
                timeout: 3000
              })       
            })
            .catch((error) => {
              this.loading = false;
              this.handleError(error);
            });
          }   
        })
        } else {

          const emailArray = this.email.split(",");
          const trimmedEmailArray = emailArray.map(email => email.trim());

          this.emailArray = trimmedEmailArray;

          var emails_filtered = this.emailArray.filter(function (el) {
            return el != '';
          });

          this.loading = true;
          await ApiService.post("campaign/sendBat", {
              idDatabase: this.dbId,
              emails: emails_filtered,     
              idSeed: this.idSeed,  
              final_html: this.html,
              textPlain: this.text_version,
              sender: this.sender,
              subject: this.subject,
              comment: this.comment  
            })
          .then((response) => {
            this.loading = false;
            this.$emit('close')
            this.$store.dispatch('setSnackbar', {
              color: 'success',
              text: 'BAT has been sent',
              timeout: 3000
            })       
          })
          .catch((error) => {
            this.loading = false;
            this.handleError(error);
          }) 
              
        }
      },

      handleError(error) {
        if (typeof error.response.data === "object") {
          Object.values(error.response.data)
            .flat()
            .forEach((error) => {
              this.$store.dispatch("setSnackbar", {
                color: "tvf_secondary",
                text: error.message,
                timeout: 3000,
              });
            });
        }
      }
    },
  };
  </script>
  
  <style  scoped>
  .col-sm-4,
  .col-md-4,
  .col-sm-8,
  .col-md-8 {
    padding: 0px;
  }

  .col-sm-12, .col-12, .col-md-12 {
    padding: 0px 10px;
  }
  
  .v-label {
    font-size: 12px !important;
  }
  .spacing{
    width: 45%;
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  .lang {
  width: 1.5rem;
  height: 1.2rem;
  margin-right: 1rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3px;
  }
  
  </style>