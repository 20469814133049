<template>
  <div>
    <v-row justify="center">
      <v-dialog
          :value="true"
          :return-value.sync="time"
          @click:outside="$emit('close')"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="time"
              label="Picker in dialog"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="time"
            scrollable
            format="24hr"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="$emit('close')"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="tvf_color"
              @click="addCampaign"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-dialog>
        <new-event
          v-if="newCampaign"
          @close="newCampaign = false"
        />
    </v-row>
  </div>
</template>

<script>
import NewEvent from '../calendar/NewEvent.vue'

export default {
  components: {
    NewEvent
  }, 
  data() {
    return {
      time: null,
      menu: false,
      newCampaign: false,
      menu2: false,
      errors: [],
      agencies: [],
      databases: [],
    };
  },

 mounted() {
  },

  methods: {  
      addCampaign() {
          this.newCampaign = true
      }
  }
};
</script>

<style scoped>
.col-sm-4,
.col-md-4,
.col-12,
.col-sm-8,
.col-md-8 {
  padding: 0px;
}

.v-application div.v-picker__title, .v-application .accent{
    background-color: #F85182 !important;
}


</style>