<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="true"
        persistent
        @click:outside="$emit('close')"
        max-width="1300px"
      >
        <v-card>
          <v-simple-table class="pa-10">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    CAMPAIGN
                  </th>
                  <th class="text-center">
                    TIME
                  </th>
                  <th class="text-center">
                    DATABASE
                  </th>
                  <th class="text-center">
                    SEGMENTATION
                  </th>
                  <th class="text-center">
                    AMOUNT
                  </th>
                  <th class="text-center">
                    PAYOUT
                  </th>
                  <th class="text-center">
                    TAG(S)
                  </th>
                  <th class="text-center">
                    COMMENTS
                  </th>
                  <th class="text-center">
                    EDIT
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="py-5"
                  v-for="item in top_campaign"
                  :key="item.name"
                >
                  <td class="text-center top_campaign px-10 py-2">{{ item.name }}<br>{{ item.partner }}</td>
                  <td class="text-center">{{ item.time }}</td>
                  <td class="text-center">{{ item.database }}</td>
                  <td class="text-center">{{ item.segmentation }}</td>
                  <td class="text-center">{{ item.amount }}</td>
                  <td class="text-center">{{ item.payout }}</td>
                  <td class="text-center">{{ item.tags }}</td>
                  <td class="text-center">{{ item.comments }}</td>
                  <td class="text-center">
                    <v-btn icon>
                      <v-icon>mdi-circle-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="tvf_color"
              text
              @click="$emit('close')"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      top_campaign: [
        {
          name: "Allianz Auto",
          partner: "(Adkomo)",
          time: "8:00",
          database: "Conseils du Jour",
          segmentation: "segmentation",
          amount: "90K",
          payout: "0,40",
          tags: "Assurance",
          comments: "Any comment here with ECPM or something you want to write",
          edit: "edit",
        }
      ],
    };
  },
};
</script>

<style scoped>
div .v-data-table__wrapper {
  pointer-events: none;
}
</style>
